import { FormControlLabel, ThemeProvider } from "@mui/material";
import {Checkbox} from "@mui/material";
import Theme from "./Theme";
import React, { useEffect, useState } from "react";

const CheckboxFormInput = React.forwardRef(({value, name, label, register, isRequired, errors, onChange, setValue, isReadOnly}, ref) => {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(value);
        setValue && setValue(name, value);
    }, [value]);

    React.useImperativeHandle(ref, () => ({
        checked,
    }));

    const handleOnChange = e => {
        let v = e.target.checked;
        onChange && onChange(v);
        setChecked(v);
        setValue && setValue(name, v);
    };
    let _register = {};
    if (register) {
        _register = {...register(name, { 
                            isRequired, 
                            onChange: handleOnChange
                        })};
    }else {
        _register = {onChange: handleOnChange};
    }
    return (
        <ThemeProvider theme={Theme}>
            <FormControlLabel className="CheckboxFormInput"
                control={
                    <Checkbox disabled={isReadOnly? true:false} id={name || 'check_box_id'}
                        onClick={e => e.stopPropagation()}
                        checked={checked? true:false}
                        {..._register}
                        error={!!errors || ''}
                        helpertext={errors?.message} /> }
                label={label} />
        </ThemeProvider>
    )
});

export default CheckboxFormInput;