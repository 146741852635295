import { useHistory } from 'react-router-dom';
import './Rankings.scss';
import { getAgeGroupDisplay } from "../../components/LeagueInformationForm/AgeGroups";
import { getWeightClassDisplay } from "../../components/LeagueInformationForm/WeightClasses";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Points from './Points';
import FilterChips from "../../components/Filters/FilterChips";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import { ThemeProvider } from "@emotion/react";
import Theme from "../../components/FormInput/Theme";
import { RequestUtils } from "../../serverUtils/requests";
import { default as FullScreenIcon } from '@mui/icons-material/Fullscreen';
import Utils from "../../serverUtils/Utils";
import { Avatar, Link } from "@mui/material";
import DivisionFilter from "./DivisionFilter";
import UserModel from "../../serverUtils/models/UserModel";

const getDivisionDisplay = (division) => {
    if (!division || !division.id) {
        return "";
    }
    let name = [
        division.name,
        division.getAgeGroup && getAgeGroupDisplay(division?.getAgeGroup()),
        division.getWeightClass &&
        getWeightClassDisplay(division?.getWeightClass()),
        division.code,
    ].filter((d) => d);

    return name.join(" ");
};

export const getPlacements = async (tournament, division) => {
    let placements = await tournament.getPlacements(division)
    placements = placements.map(p => ({
        place: p.place, 
        getMembership: p.getMembership, 
        getRegistration: p.getRegistration, 
        getGym: p.getGym,
        division: p.division
    }))
        .sort((a, b) => Utils.sorter(a, b, 'place'));
    return placements;
}

const FILTER = [{ label: 'Team', value: 0 }, { label: 'Gym', value: 2 }, { label: 'Individual', value: 1 }]
export default function Rankings({ tournament }) {
    const history = useHistory();
    const [selectedDivisionId, setSelectedDivisionId] = useState(Object.keys(tournament.getPlacements())[0]);
    const [placements, setPlacements] = useState([]);
    const [filter, setFilter] = useState(FILTER[0]);
    const [divisionFilter, setDivisionFilter] = useState();
    const allDivisions = tournament.getAvailableDivisions();
    const fullscreenRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState();

    useEffect(() => {
        const urlParams = RequestUtils.getURLParameters();
        if (urlParams.filter) {
            let f = FILTER[parseInt(urlParams.filter)];
            setFilter(f);
        }
        if (urlParams.id){
            setDivisionFilter(setDivisionFilter(urlParams.id));
        }
    }, []);

    useEffect(() => {
        getPlacements(tournament, divisionFilter)
            .then(ps => {
                setPlacements(Utils.uniqArrayByKey(ps, 'place'));
            })
    }, [divisionFilter]);

    const selectedDivision = allDivisions.find(d => d.id === selectedDivisionId);
    const getPlacementPoint = (place) => {
        let gs = tournament.getGroupScores().find(g => {
            return !g.divisions || g.divisions.length === 0 || g.divisions.includes(selectedDivisionId);
        });
        if (gs) {
            const { placement_point } = gs;
            switch (place) {
                case 1:
                    return placement_point?.first || 0;
                case 2:
                    return placement_point?.second || 0;
                case 3:
                    return placement_point?.third || 0;
                case 4:
                    return placement_point?.fourth || 0;
            }
        }
        return 0;
    }

    const fullscreen = () => {
        if (fullscreenRef.current) {
            setIsFullscreen(true);
            Utils.toggleFullscreen(fullscreenRef.current, () => setIsFullscreen(false));
        }
    }
    return <div className={`Rankings`}>
        <ThemeProvider theme={Theme} >
            <FullScreenIcon className="FullScreenIcon" onClick={fullscreen}/>
            <FilterChips
                filters={FILTER}
                activeFilters={[filter]}
                onClick={(f) => {
                    if (f) {
                        RequestUtils.insertURLParam('filter', f.value, history);
                        if (f.value === 0) {
                            RequestUtils.insertURLParam('id', null, history);
                        }
                    } 
                    
                    setDivisionFilter(null);
                    setFilter(f);
                }}
            />
            <div className={isFullscreen? 'fullscreen':''}>
                {filter.value === 0 && 
                <div className="teams" ref={fullscreenRef} >
                    <Points tournament={tournament} point_type="teams"/>
                </div>}

                {filter.value === 2 && 
                <div className="teams" ref={fullscreenRef} >
                    <Points tournament={tournament} point_type="gyms"/>
                </div>}

                {filter.value === 1 && 
                <div className="individuals" >
                    <DivisionFilter tournament={tournament} 
                        onSelect={f => {
                            setSelectedDivisionId(f);
                            setDivisionFilter(f);
                        }} />

                    {selectedDivision && 
                        <div className={`table`} ref={fullscreenRef} >
                            <h3>{selectedDivision.name}</h3>
                            <div className={`divisionRankingTable`}>
                                <div className={`divisionRankingRowHeader`}>
                                    <span>Rank</span>
                                    <span>Name</span>
                                    <span>Team</span>
                                    <span className="points">Points</span>
                                </div>
                                {placements.map((place, i) => {
                                    return <div key={i} className={classNames(i === 0 ? `divisionRankingRowFirst` : '', `divisionRankingRow`)}>
                                        <span>{TournamentModel.renderPlacement(place.place)}</span>
                                        <div className="fieldFlow">
                                            <Link href={`/users/${place.getMembership().id}`}>
                                                <Avatar src={place.getMembership().getImage()}/>
                                            </Link>
                                            <span className="name">{UserModel.getMembershipName(place.getMembership())}</span>
                                        </div>
                                        <div className="fieldFlow">
                                            <Link href={`/teams/${place?.getMembership()?.getTeam()?.id}`}>
                                                <Avatar src={place?.getMembership()?.getTeam()?.getImage && place?.getMembership()?.getTeam()?.getImage()}/>
                                            </Link>
                                            <span className="name">{place.getMembership().getTeam()?.name || ''}</span>
                                        </div>
                                        <span className="points">{getPlacementPoint(place.place)}</span>
                                    </div>
                                })}
                        </div>

                    </div>}
                </div>}
                
            </div>
        </ThemeProvider>
    </div>
}


