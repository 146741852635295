import SelectFormInput from "../FormInput/SelectFormInput";
import TextFormInput from "../FormInput/TextFormInput";
import FormStyles from './../Form/Form.module.scss';
import { useEffect, useState } from "react";
import TextAreaFormInput from '../FormInput/TextAreaFormInput';
import { RemoveSubFormIcon } from './Form';
import "./PaymentInfos.scss";
export const PAYMENT = {
    paypal: {value: 'paypal', label: 'PayPal'},
    inperson: {value: 'inperson', label: 'Other'},
    square: { value: 'square', label: 'Square', isHidden: true },
    stripe: { value: 'stripe', label: 'Stripe', isHidden: true }
}

export default function PaymentInfos({ data: _data, register, refresh, setValue, onChange }) {
    const [data, setData] = useState();
    useEffect(() => {
        setData(_data);
    }, [_data]);
    const [update, setUpdate] = useState(false);
    const paymentTypeOptions = Object.values(PAYMENT).filter(p => !p.isHidden);

    const getAccountLabel = (payment_type) => {
        if (payment_type === PAYMENT.square.value) {
            return 'Application ID'
        }
        return `${PAYMENT[payment_type].label} Account`;
    }

    const getPrivateKeyLabel = (payment_type) => {
        if ([PAYMENT.stripe.value, PAYMENT.square.value].includes(payment_type)) {
            return 'Access Token';
        }
        if (payment_type===PAYMENT.paypal.value) {
            return 'Secret Key';
        }
        return 'Private Key';
    }

    const getPaymentType = (payment_type) => {
        if (!payment_type) {
            return PAYMENT.paypal.value;
        }
        return payment_type;
    }

    return <div className="PaymentInfos">
        {(data || []).map((pi, i) => {
            return <div key={i} className={FormStyles.fields} id={`payment_info_${i}`}>
                <div className="paymentInfoWrapper">
                    <div className="payment-name">
                        <TextFormInput name={`payment_info.${i}.label`}
                            isRequired
                            value={pi.label}
                            label="Name"
                            register={register} setValue={setValue}
                            />
                    </div>
                    <SelectFormInput
                        onChange={v => {
                            pi.payment_type = v;
                            setUpdate(!update);
                        }}
                        name={`payment_info.${i}.payment_type`} label="Payment Type"
                        setValue={setValue}
                        placeholder="Select payment type"
                        value={pi.payment_type}
                        options={paymentTypeOptions} />
                    {[PAYMENT.paypal.value, PAYMENT.square.value, PAYMENT.stripe.value].includes(getPaymentType(pi.payment_type))
                        && <TextFormInput
                            value={pi?.account}
                            name={`payment_info.${i}.account`}
                            label={getAccountLabel(getPaymentType(pi.payment_type))}
                            register={register} setValue={setValue}
                            isRequired />}
                    {[PAYMENT.paypal.value].includes(getPaymentType(pi.payment_type))
                        && <TextFormInput value={pi?.location_id}
                            name={`payment_info.${i}.location_id`}
                            label="Client ID"
                            register={register} setValue={setValue}
                            isRequired />}
                    {/* {[PAYMENT.paypal.value, PAYMENT.square.value, PAYMENT.stripe.value].includes(getPaymentType(pi.payment_type))
                        && <TextFormInput value={pi?.private_key}
                            name={`payment_info.${i}.private_key`}
                        label={getPrivateKeyLabel(getPaymentType(pi.payment_type))}
                            register={register}
                        />} */}
                    <TextAreaFormInput
                        label="Notes"
                        value={pi?.notes || ''}
                        name={`payment_info.${i}.notes`}
                        placeholder="add payment notes here...."
                        register={register} setValue={setValue}
                        />
                    <TextAreaFormInput
                        label="Cart Notes"
                        value={pi?.cart_notes || ''}
                        name={`payment_info.${i}.cart_notes`}
                        placeholder="add cart notes here..."
                        register={register} setValue={setValue}
                    />
                    <div className="remove-button-wrapper">
                        <RemoveSubFormIcon onClick={() => {
                            data.splice(i, 1);
                            refresh();
                            onChange && onChange();
                        }} label={`Payment option ${i + 1}`} />
                    </div>
                </div>
            </div>;
        })}
    </div>
        
}

export const GIHTransactionFee = ({ formData, register, onChange }) => {
    return [<div className={FormStyles.fieldDescription} key={0}>
        <h3>Transaction Fee</h3>
    </div>,
        <div className={FormStyles.fields} key={1}>
        <TextFormInput
            type="number"
            value={formData?.trans_fee}
            name="trans_fee"
            register={register}
            label="Transaction Fee" />
    </div>];
}