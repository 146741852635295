import { useState, useEffect } from "react";
import GymModel from "../../serverUtils/models/GymModel";
import LoadingList from "../../components/LoadingList/LoadingList";
import AddEntityMember from "../../components/Form/AddEntityMember";
import { IonIcon, IonList } from "@ionic/react";
import UserModel from "../../serverUtils/models/UserModel";
import {
    addCircle as addIcon,
} from "ionicons/icons";
import pageStyles from "./../Page.module.scss";
import { ENTITY } from "../../serverUtils/Models";
import UserChip from "../../components/UserChip/UserChip";
import MembershipInfo from "../../components/Form/MembershipInfo";
import { useStore } from "../../Store";
import "./GymMemberships.scss";
import { Checkbox } from "@mui/material";
import Utils from "../../serverUtils/Utils";
import { default as MergeToIcon } from "@mui/icons-material/CallMerge";
import AlertPane from "../../components/FormInput/AlertPane";
import { FilterInput } from "../../components/Form/Form";

export default function GymMemberships({gym}) {
    const session = useStore(state => state.session);
    const [newMember, setNewMember] = useState();
    const [isAdd, setIsAdd] = useState();
    const [memberships, setMemberships] = useState();
    const [filter, setFilter] = useState('');
    const [isEditable, setIsEditable] = useState(false);
    const [merge, setMerge] = useState([]);
    useEffect(() => {
        gym && GymModel.getMemberships({id:gym.id, search_string: filter})
            .then(memberships => {
                setMemberships(memberships);
            });
        setIsEditable(document.location.href.includes('/edit'));
    }, [gym, filter]);

    

    const MergeMemberhips = () => {
        const [message, setMessage] = useState();
        const [isReverse, setIsReverse] = useState(false);
        const mergeMemberships = async () => {
            if (merge.length > 1) {
                let response = await GymModel.mergeMemberships(merge[isReverse? 1:0].id, merge[isReverse? 0:1].id);
                if (response && !response.errors) {
                    merge.shift();
                    merge.shift();
                }else {
                    return setMessage('error: Failed to merge membership');
                }
                setMerge([...merge]);
                setTimeout(() => document.location.reload(), 2000);
                return setMessage('success: Successfully merged memberships');
            }
        }
        return <div className="MergeMemberhips">
                <div className="merge-info">
                    Merge <UserChip membership={merge[1]}/>[{`${merge[1].user_name}-${Utils.formatDate(merge[1].created_on)}`}] 
                        <MergeToIcon className={`merge-icon ${isReverse? 'reverse':''}`} onClick={() => setIsReverse(!isReverse)}/> 
                    <UserChip membership={merge[0]}/>[{`${merge[0].user_name}-${Utils.formatDate(merge[0].created_on)}`}]
                </div>
                <div className="buttons">
                    <button className="button icon" onClick={mergeMemberships}>Merge</button>
                    <button className="button icon" onClick={() => setMerge([])}>Clear</button>
                </div>
                <AlertPane message={message} setMessage={setMessage} timeout={5000}/>
            </div>;
    }

    let isAdmin = gym && UserModel.isAdmin(session, ENTITY.gym, gym.id);
    return !memberships? <LoadingList /> : (
        <div className="GymMemberships">
            <div className="filter">
                {isAdd && 
                    <AddEntityMember
                        entity={gym}
                        session={session} 
                        memberships={memberships}
                        newMember={newMember}
                        setNewMember={setNewMember}
                        setMemberships={setMemberships}
                        setIsAdd={setIsAdd}
                        model={GymModel}/>}
                {isAdmin && 
                    (!isAdd && <button className="button" onClick={() => setIsAdd(true)}>
                        <IonIcon icon={addIcon}/>Find a member to add
                    </button>)
                }
                <FilterInput entity="gym_memberships" label="Filter by name..." value={filter} onFilter={v => setFilter(v)} />
            </div>
            {session.is_super && merge.length > 1 && <MergeMemberhips />}
            <IonList className={pageStyles.marginBottom}>
                <div className="memberships">
                    {memberships
                        .map((m, i) => {
                            return <div className="membership" key={i}>
                                <div className="member">
                                    <UserChip membership={m}/>
                                    {isEditable && session.is_super && <div className="merge-option">
                                        <Checkbox style={{color: 'gray'}} checked={merge.filter(e => e.id === m.id).length>0}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    setMerge([...merge, m].sort((a, b) => Utils.sorterDate(a, b, 'created_on')));
                                                }else {
                                                    setMerge(merge.filter(e => e.id !== m.id));
                                                }
                                            }}/>Merge
                                    </div>}
                                </div>
                                {isAdmin && isEditable &&
                                    Utils.uniqueArrayByKeys(m.gyms.filter(g => g.id===gym.id), ['id', 'registered_date'])
                                        .map((g, j) => {
                                            return <MembershipInfo info={g} key={j}
                                                onUpdate={data => {
                                                    if (data) {
                                                        return GymModel.updateMembership({id: gym.id, member_id: m.id, data});
                                                    }else {
                                                        return GymModel.updateMembership({id: gym.id, member_id: m.id, data: {...g, delete: true}});
                                                    }
                                                }}
                                            />;
                                        }
                                    )}
                            </div> 
                    })}
                </div>
            </IonList>
        </div>
    )
}
