import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { Link, Paper } from "@mui/material";
import "./SupportPage.scss";
import { Document, Page } from "react-pdf";
import { useEffect, useState } from "react";

const HELPS = {
  league_layout: {
    title: "League Layout",
    description: "League competition can be form.",
    images: ["league_layout.jpg"],
  },
  create_a_tournament: {
    title: "Create a Tournament",
    images: ["Create_a_tournament.pdf"],
  },
  create_a_league: {
    title: "Create a League",
    images: ["Create_a_league.pdf"],
  },
  create_a_gym: {
    title: "Create a Gym",
    images: ["Create_a_gym.pdf"],
  },
  create_a_team: {
    title: "Create a Team",
    images: ["Create_a_team.pdf"],
  },
  setup_payment_gateway: {
    title: "Setup Payment Collection",
    images: ["setup_payment_gateway.pdf"],
  },
  gih_payout: {
    title: "Payout to GIH",
    images: ["gih_payout.pdf"],
  },
  pay_secret_api: {
    title: "How to get PayPal API key",
    images: ["pay_secret_api.pdf"],
  },
};

const SupportPage = () => {
  const [help, setHelp] = useState();

  const updateHelp = (h) => {
    if (help === h) {
      return setHelp(null);
    }
    setHelp(h);
  };

  const Helps = () => {
    const HelpLink = ({ h }) => (
      <Link
        onClick={() => updateHelp(h)}
        style={{ cursor: "pointer" }}
        color="inherit"
      >
        {HELPS[h].title}
      </Link>
    );
    if (help) {
      let info = HELPS[help];
      return (
        <li>
          <HelpLink h={help} />
          <div className="help">
            <div className="description">{info.description || ""}</div>
            {info.images.map((i) => {
              return (
                <div>
                  {i.endsWith(".pdf") ? (
                    <PDFReader filePath={`${document.location.origin}/help/${i}`} />
                  ) : (
                    <img src={`/help/${i}`} style={{ width: 600 }} />
                  )}
                </div>
              );
            })}
          </div>
        </li>
      );
    }
    return Object.keys(HELPS).map((h, i) => (
      <li key={i}>
        <HelpLink h={h} />
      </li>
    ));
  };

  return (
    <IonPage className="SupportPage">
      <IonHeader>
        <h1>GIH Support</h1>
      </IonHeader>
      <IonContent className="contact">
        <h5>Contact Us</h5>
        <p>
          <b>
            If you have questions related to the event that you are registering
            such as event refund, cancelation, etc... , please contact the event
            promoter. The promoter contact information should be on the home
            page of the event.
          </b>
        </p>
        <div>
          If you have issue regarding GIH membership such as site login,
          creating event, etc..., You can send us an email to{" "}
          <b>
            <Link href="mailto:grappling.in.house@gmail.com" color="inherit">
              grappling.in.house@gmail.com
            </Link>
          </b>{" "}
          with the following details:
          <div>
            <ul>
              <li>First and Last name</li>
              <li>Username on “Grappling In House” system (if relevant)</li>
              <li>Email address</li>
              <li>Title of Support issue</li>
              <li>Detail of Support issue</li>
            </ul>
          </div>
          <p>
            We will attempt to respond to your issue within 24 hrs.
            <br />
          </p>
          <p>
            Thank you, for using our site.
            <br />
            GIH Support Team.
          </p>
        </div>

        {/* <h5>Documentation</h5>
        <ul className="documentation">
          <Helps />
        </ul> */}
      </IonContent>
    </IonPage>
  );
};

export const PDFReader = ({ initPage = 1, filePath }) => {
  const [numPages, setNumPages] = useState();
  const [pdf, setPdf] = useState();
  window.pdfjsLib.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/legacy/build/pdf.worker.min.js`;
  useEffect(() => {
    setPdf(filePath);
  }, [filePath, initPage]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return !pdf ? (
    ""
  ) : (
    <div className="pdf">
      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array(numPages)
          .fill()
          .map((_, p) => (
            <Paper className="page" key={p}>
              <Page
                pageNumber={p + 1}
                id={`page_${p}`}
                scale={1.4}
                width={600}
                height={800}
              />
            </Paper>
          ))}
      </Document>
    </div>
  );
};

export default SupportPage;
