import "./TournamentPage.scss";
import TournamentPageStyles from './TournamentPage.module.scss';
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useStore } from "../../Store";
import Utils from "../../serverUtils/Utils";
import { RequestUtils } from "../../serverUtils/requests";
import { useHistory } from 'react-router-dom';
import { getDivisionTypeChips, getTournamentDivisionsByFilters } from "./DivisionRegistration";
import { BRACKET } from "../../components/Bracket/Bracket";
import { ThemeProvider } from "@emotion/react";
import Theme from "../../components/FormInput/Theme";
import { Chip } from "@mui/material";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import AlertPane from "../../components/FormInput/AlertPane";
import "./DivisionFilter.scss";

const DivisionFilter = forwardRef(({value: chip, tournament, onSelect, onChip, includeBracketTypeSize, isActiveTab, isEditMode, division: _division}, ref) => {
    const history = useHistory();
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [selectedChip, setSelectedChip] = useState(); //selected chip
    const [division, setDivision] = useState();
    const [refresh, setRefresh] = useState(false);
    const filterDivisions = useRef({});
    const urlParams = RequestUtils.getURLParameters();

    useImperativeHandle(ref, () => ({
        filters: filterDivisions.current, 
        setSelected: setSelectedChip, 
        initDivision, setDivision,
        division, 
        selected: selectedChip,
        setChipByDivision: div => {
            let chip = [...filterDivisions.current]
                .sort((a, b) => Utils.sort(a.divisions.length, b.divisions.length))
                .find(fs => fs.divisions.find(d => d.id === div));
            chip && setSelectedChip(chip.value);
        }
    }));
    const createFilters = () => {
        filterDivisions.current = getDivisionTypeChips(tournament);
        for (let fs of filterDivisions.current) {
            let divisions = getTournamentDivisionsByFilters(tournament, [fs], [], () => [], () => [], isEditMode);
            fs.divisions = divisions;
        }

        return Object.keys(filterDivisions.current).length > 0 && filterDivisions.current;
    }

    const initDivision = (d) => {
        let did = d && (d.pool || d.id);
        if (d && did !== division) {
            d.getTournament = () => tournament;
            setDivision(d.pool || d.id);
            isActiveTab && RequestUtils.insertURLParam('id', d.pool || d.id, history);
            onSelect(d.pool || d.id);
        }
    }

    useEffect(() => {
        onChip && onChip(selectedChip);
    }, [selectedChip]);

    useEffect(() => {
        // console.log('_division', _division)
        if (tournament.caches.bracketEntries === undefined) {
            tournament.getBracketEntries().then(() => {
                setRefresh(!refresh);
                onSelect && onSelect(_division && _division.id);
            });
        }
    }, [_division]);

    useEffect(() => {
        !tournament?.caches?.bracketEntries && 
            tournament.getBracketEntries(bes => setRefresh(!refresh));
    }, [tournament && tournament?.caches?.bracketEntries]);

    useEffect(() => {
        if (!tournament) {
            return;
        }
        
        let fs = createFilters();
        if (fs) {
            let f;
            if (chip) {
                f = fs.find(_f => _f.value === chip);
            }
            if (!f) {
                f = fs.find(_f => _f.value === urlParams.chip);
                if (!f) {
                    f = fs.find(filter => filter.divisions.length > 0);
                }
            }
            if (f) {
                selectedChip !== f.value && setSelectedChip(f.value);
                let div = f.divisions[0];
                if (urlParams.id){
                    let d = f.divisions.find(_d => [_d.id, _d.pool].includes(urlParams.id));
                    if (d) {
                        div = d;
                    }
                }
                initDivision(div);
            }
        }
    }, [tournament, isBracketEntriesUpdate, chip, ]); //urlParams.chip, urlParams.id

    const getBracketTypeSize = (b, isCountOnly) => {
        if (includeBracketTypeSize) {
            try{
                let d = tournament.getAvailableDivisions().find(_d => [b.id, b.pool].includes(_d.id));
                if (d) {
                    if (!d.getRegistrations) {
                        d.getRegistrations = () => tournament.getRegistrations().filter(r => [...r.pool.split('|'), r.division].includes(d.id));
                    }
                    let regs = d.getRegistrations().filter(r => r.status === 'A');
                    let length = regs.length;
                    if (d.pool === d.id) {
                        length = regs.filter(r => r.pool.split('|').includes(d.pool)).length;
                    }
                    if (isCountOnly) {
                        return length;
                    }
                    let hasThird = d.bracket_type===BRACKET.Single_Elimination && d.is_third? ' [has 3rd]':'';
                    let isModifyRR = d.is_modify_round_robin? ' modify RR':'';
                    return ` [${b.bracket_type.toUpperCase()}-${length}]${hasThird}${isModifyRR}`;
                }
            }catch(e) {
                console.log(e);
            }   
        }
        return '';
    }

    const getDivisionOptions = () => {
        if (!selectedChip) {
            return [];
        }
        let f = filterDivisions.current.find(f => f.value === selectedChip);
        let options = !tournament.caches.bracketEntries || !f? [] : 
            f.divisions//.filter(d => getBracketTypeSize(d, true) > 0)
                .map(d => {
                    let isLoaded = tournament.caches.bracketEntries;
                    let bracketCount = getBracketTypeSize(d, true)
                    if (isLoaded === undefined) {
                        isLoaded = '';
                    }else {
                        isLoaded = bracketCount === 0 || tournament.caches.bracketEntries.find(be => be.division === d.id)? '' : 'Not load';
                    }
                    return { 
                        value: d.pool || d.id, 
                        label: <div className="division-option">{d.pool || d.name}<b>{getBracketTypeSize(d)}</b>
                            <b className="not-load">{isLoaded}</b>
                        </div>
                    };
                });
        return options;
    }

    return <ThemeProvider theme={Theme}>
        {Object.keys(filterDivisions.current).length>0? 
            <div className={`DivisionFilter ${TournamentPageStyles.DivisionFilter}`}>
                <div className={`filter-chips ${TournamentPageStyles.DivisionFilterButtons}`}>
                    {filterDivisions.current.filter(f => f.divisions.length > 0).map((f, i) => {
                        return <Chip
                            key={i}
                            className="filter"
                            label={f.label}
                            variant={f.value === selectedChip ? 'filled' : 'outlined'}
                            color={f.value === selectedChip ? 'primary' : undefined}
                            onClick={() => {
                                setSelectedChip(f.value);
                                onChip && onChip(f.value);
                                let divs = f.divisions;
                                initDivision(divs.length>0? divs[0] : null);
                            }}
                        />
                    })}
                </div>
                <div className="SelectFormInput-wrapper">
                    {selectedChip && <SelectFormInput width={'98%'}
                        name="division_filter"
                        label="Division"
                        value={division || ''}
                        options={getDivisionOptions()}
                        onChange={value => {
                            isActiveTab && RequestUtils.insertURLParam('id', value, history);
                            onSelect(value);
                            setDivision(value);
                        }}
                    />}
                </div>
            </div> : <AlertPane message={`warning: There is no available division.  Please 
                check the registration status of the competitors tab.  Registration 
                status must be active.`} />}

    </ThemeProvider>
});


export default DivisionFilter;