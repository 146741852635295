import { DATA_HAS_CHANGED_MESSAGE, DATA_HAS_CHANGED_MESSAGE_WARNING, StatusUpdate, hasTableFormChange, reloadPage } from "../Form/Form";
import React, { useEffect, useRef, useState } from "react";
import "./../Form/Form.scss";
import {
  Input, TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { RequestLeague, RequestUtils } from "../../serverUtils/requests";
import TableFormInput, { IndexCell, ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/AlertPane";
import { SelectGender, SelectGrapplingType } from "./WeightClasses";
import { validateUniqueCode } from "./Divisions";
import Utils from "../../serverUtils/Utils";
import { AgeGroupSchema } from "../../serverUtils/Models";
export default function AgeGroups({ league }) {
  const [message, setMessage] = useState("");
  return (
    <div className={`AgeGroups sub_form`}>
      <AlertPane message={message} setMessage={setMessage} timeOut={2000}/>
      <AgeGroupTable league={league} setMessage={setMessage} />
    </div>
  );
}

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Age Group",
    align: "left",
    crc: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
    crc: true,
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    align: "left",
    crc: true,
  },
  {
    id: "age_min",
    disablePadding: false,
    label: "Age Minimum",
    align: "left",
    crc: true,
  },
  {
    id: "age_max",
    disablePadding: false,
    label: "Age Maximum",
    align: "left",
    crc: true,
  },
  {
    id: "grappling_types",
    disablePadding: false,
    label: "Grappling Types",
    crc: true,
  },
  {
    id: "genders",
    disablePadding: false,
    label: "Genders",
    crc: true,
  },
];

const alignCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const CRC_Fields = Utils.listObjectKeys(AgeGroupSchema().model);
const AgeGroupTable = ({ league, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);

  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const dataHasChangeRef = useRef();

  const checkCRC = () => {
    if (hasTableFormChange(league.getAgeGroups(), CRC_Fields)) {
      toolbarButtonsRef.current.setIndicator({save: 'yellow'});
      toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
      dataHasChangeRef.current.style.display = '';
    }else {
      toolbarButtonsRef.current.setIndicator({save: ''});
      toolbarButtonsRef1.current.setIndicator({save: ''});
      dataHasChangeRef.current.style.display = 'none';
    }
  }
  useEffect(() => {
    league.getAgeGroups && 
      league.getAgeGroups().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields)));
  }, []);
  const save = async () => {
    setMessage("");
    try{
      let selects = league.getAgeGroups().filter(ag => ag.crc !== Utils.getCRC(ag, CRC_Fields));
      if (
        selects.find(
          (s) => !s.name || !s.code || !s.status || !s.age_min || !s.age_max
        )
      ) {
        return setMessage(
          "error: Age group, code, age min, age max and status must be filled."
        );
      }
      for (let r of selects) {
        r.status = r.status || 'A';
        r.grappling_types = !r.grappling_types || r.grappling_types.length===0? ['gi']:r.grappling_types;
        r.genders = !r.genders || r.genders.length===0? ['M']:r.genders;
        let response;
        let isAdd = r.id.startsWith("-");
        if (!isAdd) {
          response = await RequestLeague.updateLeagueAgeGroupRequest(r);
        } else {
          r.league = league.id;
          response = await RequestLeague.addLeagueAgeGroupRequest(r);
        }
        let data = RequestUtils.getResponseData(response);
        if (data.error) {
          return setMessage("error: Updating age group " + r.name);
        } else if (isAdd) {
          r.id = data.id;
        }
        r.crc = Utils.getCRC(r, CRC_Fields);
      }
      tableRef.current.setSelected([]);
      setMessage("success: Successfully updated server.");
    }finally {
      checkCRC();
    }
  };

  const deleteSelected = async () => {
    setMessage("");
    try{
      let selects = league
        .getAgeGroups()
        .filter((r) => tableRef.current.selected.includes(r.id));
      if (selects.length === 0) {
        return setMessage("info: Nothing selected.");
      }
      for (let r of selects) {
        if (r.id.startsWith("-")) {
          continue;
        }
        let response = await RequestLeague.deleteLeagueAgeGroupRequest(r.id);
        let data = RequestUtils.getResponseData(response);
        if (data.error) {
          return setMessage("error: Deleting age group " + r.name);
        }
      }
      let ageGroups = league
        .getAgeGroups()
        .filter((r) => !tableRef.current.selected.includes(r.id));
      league.getAgeGroups = () => ageGroups;
      tableRef.current.setSelected([]);
      setMessage("success: Successfully deleted from server.");
    }finally {
      checkCRC();
    }
  };

  const doAdd = (row, index) => {
    try{
      let id = `-${new Date().getTime()}`;
      let ageGroups;
      if (row) {
        let copy = {...Utils.copy(row), id, name: `${row.name} copy`};
        ageGroups = [...league.getAgeGroups()];
        ageGroups.splice(index+1, 0, copy);
      }else {
        ageGroups = [
          ...league.getAgeGroups(),
          { id, name: "", league: league.id, status: 'A' },
        ];
      }
      league.getAgeGroups = () => ageGroups;
      reloadPage(setRefresh, () => tableRef.current.gotoLastPage());
    }finally {
      checkCRC();
    }
  };

  const toolbarButtons = ref => (
    <ToolbarButtons ref={ref} doAdd={doAdd} doSave={save} doDelete={deleteSelected} />
  );
  return <>
    <div className="data-has-changed" ref={dataHasChangeRef} style={{display: 'none'}}>
        <AlertPane message={DATA_HAS_CHANGED_MESSAGE_WARNING} isFloat/>
    </div>
    <TableFormInput name="AgeGroups"
      toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
      toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
      isEditable
      ref={tableRef}
      headCells={headCells}
      data={(league.getAgeGroups && league.getAgeGroups()) || []}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const checkboxRef = row.checkboxRef = React.createRef();
        return (
          <TableRow
            className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
            hover
            onClick={e => handleClick(e, row.id, checkboxRef)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox" className="index">
              <IndexCell ref={checkboxRef} isItemSelected={isItemSelected} labelId={labelId} index={index + 1} onClone={() => doAdd(row, index)}/> 
            </TableCell>
            <TableCell>
              <Tooltip title={row.name}>
                <Input defaultValue={row.name} 
                  onChange={e => {
                    row.name = e.target.value;
                    checkCRC();
                  }}
                  onClick={e => e.stopPropagation()}/>
              </Tooltip>
              
            </TableCell>
            <TableCell>
              <StatusUpdate row={row} 
                list={league.getAgeGroups()} 
                tableRef={tableRef} 
                setRefresh={() => setRefresh(!refresh)}
                onChange={checkCRC}/>
            </TableCell>
            <TableCell>
              <CodeInput row={row} list={league.getAgeGroups()} checkCRC={checkCRC} setRefresh={() => setRefresh(!refresh)}/>
            </TableCell>
            <TableCell>
              <Input onClick={e => e.stopPropagation()}
                onChange={e => {
                  row.age_min = e.target.value;
                  checkCRC();
                }}
                type="number"
                defaultValue={row.age_min}
              />
            </TableCell>
            <TableCell>
              <Input onClick={e => e.stopPropagation()}
                onChange={e => {
                  row.age_max = e.target.value;
                  checkCRC();
                }}
                type="number"
                defaultValue={row.age_max}
              />
            </TableCell>
            <TableCell>
              <SelectGrapplingType row={row} 
                setRefresh={() => {
                  setRefresh(!refresh);
                  checkCRC();
                }}/>
            </TableCell>
            <TableCell>
              <SelectGender row={row} 
                setRefresh={() => {
                  setRefresh(!refresh);
                  checkCRC();
                }}
              />
            </TableCell>
          </TableRow>
        );
      }}
    />
  </>;
};

export const getAgeGroupDisplay = (ag) => {
  if (!ag) {
    return "";
  }
  return `[${ag.age_min || 0}-${ag.age_max || 0} yrs]`;
};

export const CodeInput = ({row, list, checkCRC, setRefresh}) => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    if (validateUniqueCode(list.map(r => r.code)).includes(row.code)) {
      setMessage('duplicated');
    }
  }, [row]);

  return <div className="CodeInput">
    <Tooltip title={row.code}>
      <Input defaultValue={row.code} className="capitalize"
        onChange={e => {
          row.code = e.target.value.toUpperCase();
          if (validateUniqueCode(list.map(r => r.code)).includes(row.code)) {
            setMessage('duplicated');
          }else {
            setMessage('');
          }
          checkCRC();
        }}
        onBlur={e => setRefresh && setRefresh()}
        onClick={e => e.stopPropagation()}/>
    </Tooltip>
    <span className="warning">{message}</span>
  </div>;
}
