import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { calculateTotalEntriesByRound, calculateTotalRounds, createInitialRound, createRoundRobinRounds, BYE, WINS_BY, WINS_BY_OPTIONS, calculateTotalNumberOfCompetitors, TBD } from "./bracketUtils";
import BracketStyles from "./Bracket.module.scss";
import CompetitorStyles from "./Competitor.module.scss";
import "./BracketWrapper.scss";
import SelectFormInput from "../FormInput/SelectFormInput";
import { RequestUtils } from "../../serverUtils/requests";
import classNames from "classnames";
import FlagIcon from "../FlagIcon";
import TournamentModel, { Bracket2Utils } from "../../serverUtils/models/TournamentModel";
import { BRACKET, BRACKET_TYPES, STATUS, STATUS_LABEL } from "./Bracket";
import Utils from "../../serverUtils/Utils";
import AlertPane from "../FormInput/AlertPane";
import { useHistory, useLocation } from 'react-router-dom';
import FilterChips from "../Filters/FilterChips";
import { default as ZoomInIcon } from "@mui/icons-material/ZoomIn";
import { default as ZoomOutIcon } from "@mui/icons-material/ZoomOut";
import { default as ExportPDFIcon } from "@mui/icons-material/PictureAsPdfRounded";
import { default as AutofillAllIcon} from "@mui/icons-material/FlashAutoOutlined";
import { default as SaveAllIcon } from "@mui/icons-material/SaveOutlined";
import { default as EditIcon } from "@mui/icons-material/Edit";
import { default as CheckIcon } from "@mui/icons-material/CheckCircleOutline";
import { default as ExportMailMergeIcon } from "@mui/icons-material/Microsoft";
import { Checkbox, FormControlLabel, Link, Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Theme from "../FormInput/Theme";
import UserChip from "../UserChip/UserChip";
import { useStore } from "../../Store";
import UserModel from "../../serverUtils/models/UserModel";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { BracketSchema, ENTITY } from "../../serverUtils/Models";
import Busy from "../Busy";
import { getDivisionTypeChips } from "../../pages/TournamentPage/DivisionRegistration";
import { openPDF } from "../../pages/MessagesPage/MessagesPage";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { default as CollapseIcon } from '@mui/icons-material/ArrowRight';
import { default as ExpandIcon } from '@mui/icons-material/ArrowDropDown';
import { GIHBanner } from "../../App";
import Logo from "../../icons/GIH-full-logo-medal-black.svg";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import { DATA_HAS_CHANGED_MESSAGE_WARNING, divScroller } from "../Form/Form";
import DivisionFilter from "../../pages/TournamentPage/DivisionFilter";
import { getRegistrationGym } from "../TournamentForm/Registrations";
import { Badge } from "@mui/material";
import { doSaveTournamentDivisions } from "../TournamentForm/TournamentDivisions";
import { doSavePoolings } from "../TournamentForm/Poolings";
import { getPlacement } from "../../pages/TournamentPage/TournamentPage";
import ScoreCardIcon from '../../icons/scoreboard.svg';
import ScoreCard from "./ScoreCard";

const urlParams = RequestUtils.getURLParameters();
const isBracketsTab = () => {
    return RequestUtils.getURLParameters().tab === 'brackets';
}

export const doSaveBracketWrapper = async (tournament) => {
    let bracketEntries = tournament.caches.bracketEntries;
    let hasError = {};
    if (!bracketEntries) {
        return hasError;
    }
    let result = await doSaveTournamentDivisions(tournament);
    if (!result) {
        return {error: 'Error updating tournament divisions'};
    }
    result = doSavePoolings(tournament);
    if (result.error) {
        return {error: 'Error updating tournament poolings'};
    }

    for (let d of tournament.getAvailableDivisions()) {
        let currentCRC = Utils.getCRC({bracketEntries: bracketEntries.filter(be => be.division === d.id)}, CRC_Bracket_Fields);
        if (currentCRC !== tournament.bracket_entries_crc[d.id]) {
            let entries = bracketEntries.filter(e => e.division === d.id);
            let response = await TournamentModel.updateBracketEntries(entries, tournament.id, d.id, d.bracket_type);
            if (!response || response.error) {
                return response;
            }
            tournament.bracket_entries_crc[d.id] = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields);
        }
    }
    tournament.getBracketEntries();
    return hasError;
}
export const CRC_Division_Fields = ["id", "bracket_type", "is_bracket_by_seed", "is_block", "max_reg", "is_third", "is_modify_round_robin", "divsions"];
const BracketWrapper = React.forwardRef(({ tournament, tabsRef={}, isEditMode, tabObjRefs={} }, ref) => {
    const history = useHistory();
    const session = useStore(state => state.session);
    const setGlobalDialog = useStore(state => state.setGlobalDialog);
    const [isEditable, setIsEditable] = useState(false);
    const [isEditingBracket, setIsEditingBraket] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isExportingAll, setExportingAll] = useState();
    const [divisionFilter, setDivisionFilter] = useState();
    const [chip, setChip] = useState();
    const [currentDivision, setCurrentDivision] = useState(); 
    const [isAutofilling, setIsAutofilling] = useState(false);
    const [isSavingAll, setIsSavingAll] = useState(false);
    const [exportAllMessage, setExportAllMessage] = useState();
    const divisionFilterRef = useRef();
    const bracketRef = useRef();
    const divRef = useRef();
    const dataHasChangeRef = useRef();
    const mailMergeExportAllRef = useRef({});
    const exportedDivisionsRef = useRef([]);
    const isExportMailMergeRef = useRef(false)

    useEffect(() => {
        if (chip && isExportingAll) {
            console.log('chip: ', chip.label);
            const {divisions} = isExportingAll;
            if (divisions.length > 0){
                initDivision(divisions[0].id);
            }else {
                bracketExportComplete();
            }
        }
    }, [chip]);
    const initDivision = (curDiv = urlParams.id) => {
        if (curDiv) {
            curDiv = tournament.getAvailableDivisions().find(d => [d.id, d.pool].includes(curDiv));
            if (curDiv) {
                curDiv.getTournament = curDiv.getTournament || (() => tournament);
                curDiv.crc = Utils.getCRC(curDiv, CRC_Division_Fields);
                setCurrentDivision(curDiv);
                divisionFilterRef.current && divisionFilterRef.current.setDivision(curDiv.id);
            }
        } 
        return curDiv;
    }

    let clientRec = divRef.current?.getBoundingClientRect && divRef.current.getBoundingClientRect();
    useEffect(() => {
        checkCRC();
    }, [clientRec && clientRec.height]);
    
    useEffect(() => {
        if (tournament) {
            let df = getDivisionTypeChips(tournament);
            setDivisionFilter(df);
            initDivision();
            if (!tournament.bracket_entries_crc) {
                tournament.bracket_entries_crc = {};
            }
        }
    }, [tournament]);

    useEffect(() => {
        session && setIsEditable(UserModel.isAdmin(session, ENTITY.tournament, tournament.id));
    }, [session]);

    useEffect(() => {
        setChipDivision();
    }, [RequestUtils.getURLParameters().tab]);

    useEffect(() => {
        if (currentDivision && !currentDivision.crc) {
            currentDivision.crc = Utils.getCRC(currentDivision, CRC_Division_Fields);
        }
        if (isExportingAll && currentDivision){
            bracketRef.current.doExport(isExportMailMergeRef.current);
        }
    }, [currentDivision]);

    const setChipDivision = () => {
        if (RequestUtils.getURLParameters().tab === 'brackets') {
            try{
                let {id, chip} = RequestUtils.getURLParameters();
                if (!chip && !id) {
                    return;
                }

                let df = divisionFilter;
                if (!df) {
                    df = getDivisionTypeChips(tournament);
                    setDivisionFilter(df);
                }
                if (!chip && id) {
                    divisionFilterRef.current && divisionFilterRef.current.setChipByDivision(id);
                }else if (chip) {
                    divisionFilterRef.current && divisionFilterRef.current.setSelected(chip);
                }
                
                if (id) {
                    initDivision(id);
                }else {
                    setCurrentDivision(null);
                }
            }finally{
                checkCRC();
            }
        }
    }

    const init = () => {
        // console.log('BracketWrapper');
        tournament.bracket_entries_crc = {};
        tournament.getBracketEntries().then(bracketEntries => {
            let serialDataMap = {};
            tournament.getAvailableDivisions().forEach(d => {
                let dentries =  bracketEntries.filter(be => be.division === d.id);
                let serialData = [];
                tournament.bracket_entries_crc[d.id] = Utils.getCRC({bracketEntries: dentries}, CRC_Bracket_Fields, null, serialData);
                serialDataMap[tournament.bracket_entries_crc[d.id]] = serialData.pop();
            });
            console.log('tournament.bracket_entries_crc: ',  tournament.bracket_entries_crc, serialDataMap);
        });
        tournament.divisions_crc = !tournament.divisions_crc && Utils.getCRC({divsions: tournament.divisions}, CRC_Division_Fields);
        tournament.poolings_crc = !tournament.poolings_crc && Utils.getCRC({divsions: tournament.poolings}, CRC_Division_Fields);
    }
    useEffect(init, []);

    useImperativeHandle(ref, () => ({
        forceUpdate: () => setRefresh(!refresh),
        checkCRC,
        doSave: () => doSaveBracketWrapper(tournament),
    }));

    const checkCRC = (isCheckAll=true) => {
        if (!isEditMode) {
            return;
        }
        if (tournament.bracket_entries_crc === undefined || 
            !currentDivision || !currentDivision.crc ||
            tournament.bracket_entries_crc[currentDivision.id] === undefined) {
            return;
        }
        let r;
        try {
            let curDivCrc = Utils.getCRC(currentDivision, CRC_Division_Fields);
            if (curDivCrc !== currentDivision.crc) {
                r = true;
            }else {
                let entries = tournament.caches.bracketEntries.filter(be => be && be.division === currentDivision.id);
                let currentCRC = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields, tournament.bracket_entries_crc[currentDivision.id]);
                r = currentCRC !== tournament.bracket_entries_crc[currentDivision.id];
            }
            if (isCheckAll) {
                let bracketEntries = tournament.caches.bracketEntries;
                r = bracketEntries && tournament.getAvailableDivisions().filter(d => d.crc).find(d => {
                    let entries = bracketEntries.filter(be => be && be.division === d.id);
                    let currentCRC = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields);
                    let _r = currentCRC !== tournament.bracket_entries_crc[d.id];
                    _r && console.log('CRC: ', d.id, currentCRC, tournament.bracket_entries_crc[d.id]);
                    return _r;
                });
            }
            return r;
        }finally {
            dataHasChangeRef.current.style.display = r? '':'none';
        }
    }

    const ExportAll = () => {
        const [refreshExportAll, setRefreshExportAll] = useState(false);
        const Orientation = () => {
            const handleClick = (e, v) => {
                e.stopPropagation();
                GROUP_OF = v;
                setRefresh(!refresh);
            }
            return <div className="Orientation">
                <FormControlLabel control={<Radio checked={GROUP_OF===8} onClick={e => handleClick(e, 8)}/>} label="Landscape" />
                <FormControlLabel control={<Radio checked={GROUP_OF===16} onClick={e => handleClick(e, 16)}/>} label="Portrait" />
            </div>
        }

        const startExport = (groups) => {
            mailMergeExportAllRef.current = [];
            let group = groups.pop();
            let divisions = group.divisions.filter(d => d.getRegistrations().length > 0);
            setExportingAll({groups, divisions});
            divisionFilterRef.current.setSelected(group.value);
        }

        const exportAll = e => {
            e.stopPropagation();
            exportedDivisionsRef.current = [];
            const {filters} = divisionFilterRef.current;
            let allFilters = filters.filter(f => f.divisions.length > 0);
            divisionFilterRef.current.setSelected(null);
            setCurrentDivision(null);
            setTimeout(() => {
                startExport(allFilters);
            }, 500);
        }

        const exportGroup = e => {
            e.stopPropagation();
            const {filters, selected} = divisionFilterRef.current;
            let filter = filters.find(f => f.value === selected);
            let regs = Utils.sumArray(filter.divisions.map(d => d.getRegistrations().length));
            if (filter.divisions.length === 0 || regs === 0) {
                return;
            }
            divisionFilterRef.current.setSelected(null);
            setCurrentDivision(null);
            setTimeout(() => {
                startExport([filter]);
            }, 500);
        }

        const autoFillAll = e => {
            e.stopPropagation();
            setIsAutofilling(true);
            setTimeout(() => {
                try{
                    let entries = [];
                    tournament.getAvailableDivisions().forEach(d => {
                        let es = d.bracket_type === BRACKET.Round_Robin ?
                            createRoundRobinRounds(tournament, d) : createInitialRound(tournament, d);
                        entries = [...entries, ...es];
                    });
                    tournament.caches.bracketEntries = entries.map(e => {
                        if (!e.getDivision) {
                            let found = tournament.poolings.find(p => p.pool === e.division);
                            if (found) {
                                found.name = found.pool;
                                found.id = found.pool;
                                e.getDivision = () => found;
                            }
                        }
                        return e;
                    });
                    setCurrentDivision({...currentDivision});
                }finally{
                    setIsAutofilling(false);
                }
            }, 500);
        }

        const saveAll = e => {
            e.stopPropagation();
            setExportAllMessage('');
            setIsSavingAll(true);
            setTimeout(() => {
                doSaveBracketWrapper(tournament)
                    .then(r => {
                        setExportAllMessage(r.error? 'error: Error saving all brackets':'success: Successfully save all brackets');
                        setIsSavingAll(false);
                        checkCRC();
                    })
            }, 500);
        }

        const ExportAllButton = ({title, onClick}) => {
            return <button className="button export" onClick={onClick}>
                    <ExportMailMergeIcon style={{display: isExportMailMergeRef.current? '':'none'}}/>
                    <ExportPDFIcon style={{display: isExportMailMergeRef.current? 'none':''}}/>
                        Export {title}
                </button>
        }
        return !isEditingBracket && <div className="ExportAll">
            {exportAllMessage && <AlertPane message={exportAllMessage} timeOut={3000} setMessage={setExportAllMessage}/>}
            <div className="buttons">
                {isEditMode && <button className="button export" onClick={saveAll}><SaveAllIcon /> Save All {isSavingAll && <IonSpinner name="circles" className="spinner"/>}</button>}
                {isEditMode && <button className="button export" onClick={autoFillAll}><AutofillAllIcon /> Autofill All {isAutofilling && <IonSpinner name="circles" className="spinner"/>}</button>}
                <ExportAllButton title="All" onClick={exportAll} />
                <ExportAllButton title="Group" onClick={exportGroup} />
                {/* <Orientation /> */}
                <CheckboxFormInput id="mail_merge_check"
                    value={isExportMailMergeRef.current} 
                    onChange={v => {
                        isExportMailMergeRef.current = v;
                        setRefreshExportAll(!refreshExportAll);
                    }} 
                    label={'Mail Merge'}/> 
            </div>
        </div>
    }

    const bracketRenderComplete = () => {
        if (isExportingAll){
            console.log('exporting bracketRenderComplete: ', currentDivision.name);
            // bracketRef.current.doExport(isExportMailMerge);
        }
    }

    const bracketExportComplete = () => {
        const done =() => {
            setExportingAll(null);
            if (mailMergeExportAllRef.current && Object.keys(mailMergeExportAllRef.current).length > 0){
                let keys =  Object.keys(mailMergeExportAllRef.current);
                for (let k of keys) {
                    let data = mailMergeExportAllRef.current[k];
                    let fileName = `${tournament.name}.${k}.txt`;
                    Utils.saveTextToFile(data.join('\n'), fileName);
                }
            }
            setExportAllMessage('success: Export has completed');
        }

        if (isExportingAll) {
            const {initDivision} = divisionFilterRef.current;
            let {groups, divisions} = isExportingAll;
            const nextGroup = () => {
                let g = groups.pop(); 
                divisionFilterRef.current.setSelected(g.value);
                let exportedIds = exportedDivisionsRef.current.map(d => [d.id, d.pool]).flat(Infinity);
                let divisions = g.divisions.filter(d => !exportedIds.includes(d.id))
                    .filter(d => d.getRegistrations().length > 0);
                setExportingAll({
                    groups: [...groups], 
                    divisions, 
                });
            }
            if (divisions.length > 0) {
                let d = divisions.shift();
                exportedDivisionsRef.current.push(d);
                setExportingAll({...isExportingAll});
            }
            if (divisions.length === 0 && groups.length > 0){
                nextGroup();
            }else if (divisions.length > 0){
                initDivision(divisions[0]);
            }else {
                done();
            }   
        }
    }

    return <div className="BracketWrapper" ref={divRef}>
        <div className="data-has-changed" ref={dataHasChangeRef} style={{display: 'none'}}>
            <AlertPane message={DATA_HAS_CHANGED_MESSAGE_WARNING} isFloat/>
        </div>
        <ExportAll />
        <DivisionFilter ref={divisionFilterRef} isActiveTab={tabsRef.current && tabsRef.current.getActiveTabUrl() === 'brackets'}
            isEditMode={true}
            value={chip}
            division={currentDivision}
            tournament={tournament} 
            includeBracketTypeSize={true}
            onChip={c => {
                if (c) {
                    setChip(divisionFilterRef.current.filters.find(f => f.value === c));
                }
                isBracketsTab() && RequestUtils.insertURLParam('chip', c||'', history);
            }}
            onSelect={f => {
                if (f) {
                    let div = tournament.getAvailableDivisions().find(d => [d.pool, d.id].includes(f));
                    setCurrentDivision(div? div:null);
                    // div && Utils.cookie('BracketWrapper', JSON.stringify({chip, id: div.id}));
                }
            }} />

        {!currentDivision? <AlertPane message={`warning: No registration found for this division.`}/> :
            <Bracket ref={bracketRef} tabObjRefs={tabObjRefs}
                checkCRC={checkCRC}
                tournament={tournament}
                currentDivision={currentDivision}
                isEditable={isEditMode && isEditable}
                setIsEditingBraket={(isEditing) => {
                    setIsEditingBraket(isEditing);
                }}
                tabsRef={tabsRef} 
                renderComplete={bracketRenderComplete}
                exportComplete={bracketExportComplete}
                isExportingAll={isExportingAll}
                mailMergeExportAllRef={mailMergeExportAllRef}
                setGlobalDialog={setGlobalDialog}
                group={chip}
                setChipDivision={setChipDivision}
            />}
    </div>
});

const getActiveRegistrations = (tournament, divId) => tournament.getRegistrations().filter(r => [r.division, ...(r.pool||'').split('|')].includes(divId));
export const CRC_Bracket_Fields = ["bracketEntries", ...Utils.listObjectKeys(BracketSchema().model)];
const Bracket = forwardRef(({
    group, setGlobalDialog, 
    mailMergeExportAllRef, isExportingAll, 
    tournament, currentDivision, isEditable, 
    tabsRef, renderComplete, exportComplete, 
    setIsEditingBraket, tabObjRefs, checkCRC,
    setChipDivision
}, ref) => {
    const history = useHistory();
    const localServer = useStore(state => state.local_server);
    const setLocalTournament = useStore(state => state.setLocalTournament);
    const setIsBracketEntriesUpdate = useStore(state => state.setIsBracketEntriesUpdate);
    const [reload, setReload] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [serverMessage, setServerMessage] = useState('');
    const [bracketEntries, setBracketEntries] = useState([]);
    const [hoveredCompetitor, setHoveredCompetitor] = useState(null);
    const [zoom, setZoom] = useState(1.0);
    const [isExporting, setIsExporting] = useState(false);
    const [isThird, setIsThird] = useState();
    const [isModifyRoundRobin, setModifyRoundRobin] = useState();
    const [registrations, setRegistrations] = useState([]);
    const [isExportRRSummary, setIsExportRRSummary] = useState(false);
    const [isBusy, setBusy] = useState(false);

    const bracketTypeRef = useRef();
    const exportButtonRef = useRef();
    const exportMailMergeButtonRef = useRef();
    const bracketDivWrapperRef = useRef();
    const doExportCalback = useRef();

    useImperativeHandle(ref, () => ({
        doExport: (isMailMerge, cb) => {
            doExportCalback.current = cb;
            if (isMailMerge) {
                exportMailMergeButtonRef.current && exportMailMergeButtonRef.current.click();
            }else {
                exportButtonRef.current && exportButtonRef.current.click();
            }
        },
        doSave: division => saveBracketEntries(division, true),
    }));

    // useEffect(() => {
    //     tournament.getBracketEntries()
    //         .then(entries => {
    //             entries = entries.filter(be => {
    //                 let r = be.getRegistration();
    //                 return !r || r.status === STATUS.Active;
    //             });
    //             setBracketEntries(entries);
    //         });
    // }, []);

    useEffect(() => {
        let cid;
        if (tournament && currentDivision) {
            currentDivision.getTournament = () => tournament;
            setIsThird(currentDivision.is_third);
            setModifyRoundRobin(currentDivision.is_modify_round_robin);
            tournament.getBracketEntries().then(bes => {
                const validateBracketSize = () => {
                    let divRegSize = currentDivision.getRegistrations().filter(r => r.status === STATUS.Active);
                    let bracketSize = Utils.uniqArray(bes.filter(be => be.division === currentDivision.id).map(be => be.membership)).filter(d => ![BYE, TBD].includes(d));
                    if (bracketSize.length > 0 &&  divRegSize.length !== bracketSize.length) {
                        bes = bes.filter(be => be.division !== currentDivision.id);
                        setGlobalDialog( 
                            <div className="bracket-size-has-changed">
                                <span><b>{currentDivision.name}</b> bracket size has change.  This bracket will be reset.</span>
                                <div className="buttons">
                                    <button className="button" onClick={e => {
                                        e.stopPropagation();
                                        setGlobalDialog(null);
                                    }}>Close</button>
                                </div>
                            </div>);
                    }
                    tournament.caches.bracketEntries = bes;
                    checkCRC();
                    return bes;
                }
                setBracketEntries(validateBracketSize());
                renderComplete && renderComplete();
            });
            let registrations = currentDivision.getRegistrations(true);
            if (!registrations || registrations.length===0) {
                registrations = getActiveRegistrations(tournament, currentDivision.id);
            }
            setRegistrations(registrations.filter(r => r.status===STATUS.Active));
            cid = currentDivision.id;
        }
        if (isEditable) {
            let url = document.location.pathname + document.location.search;
            return () => {
                if (!cid || RequestUtils.getURLParameters().id === cid || isExporting) {
                    return;
                }
                let cDiv = tournament.getAvailableDivisions().find(d => d.id === cid);
                tournament.getBracketEntries().then(bes => {
                    let bEntries = bes.filter(be => be.division === cDiv.id);
                    let ccrc = Utils.getCRC(cDiv, CRC_Division_Fields, cDiv.crc);
                    let notSaveDiv = cDiv.crc && cDiv.crc !== ccrc;
                    ccrc = Utils.getCRC({bracketEntries: bEntries}, CRC_Bracket_Fields, tournament.bracket_entries_crc[cDiv.id]);
                    let notSaveBEs = tournament.bracket_entries_crc[cDiv.id] && tournament.bracket_entries_crc[cDiv.id] !== ccrc;
                    setGlobalDialog(notSaveBEs || notSaveDiv? 
                        <div className="data-not-save">
                            <span><b>{currentDivision.name}</b> data was not saved!!!</span>
                            <div className="buttons">
                                <button className="button" onClick={e => {
                                    e.stopPropagation();
                                    setGlobalDialog(null);
                                }}>Continue without save</button>
                                <button className="button" onClick={async e => {
                                    e.stopPropagation();
                                    saveBracketEntries(currentDivision);
                                    setGlobalDialog(null);
                                    checkCRC();
                                }}>Save and continue</button>
                                <button className="button" onClick={async e => {
                                    e.stopPropagation();
                                    history.push(url);
                                    setChipDivision();
                                }}>Go back to Division</button>
                            </div>
                        </div> : null);
                });
                
            }
        }
    }, [currentDivision]);


    const saveBracketEntries = async (division=currentDivision, skipTabRefCheck) => {
        setServerMessage('');
        setIsDisabled(true);
        if (tournament.divisions_crc !== Utils.getCRC({divsions: tournament.divisions}, CRC_Division_Fields)) {
            let result = await doSaveTournamentDivisions(tournament);
            if (!result) {
                return setServerMessage('error: Error updating tournament divisions');
            }
            tournament.divisions_crc = Utils.getCRC({divsions: tournament.divisions}, CRC_Division_Fields);
        }
        if (tournament.poolings_crc !== Utils.getCRC({divsions: tournament.poolings}, CRC_Division_Fields)) {
            let result = await doSavePoolings(tournament);
            if (!result) {
                return setServerMessage('error: Error updating tournament poolings');
            }
            tournament.poolings_crc = Utils.getCRC({divsions: tournament.poolings}, CRC_Division_Fields);
        }
        !skipTabRefCheck && await tabObjRefs.current.save('bracketsRef');
        let entries = await tournament.getBracketEntries();
        entries = entries.filter(e => e.division === division.id);
        let response = await TournamentModel.updateBracketEntries(entries, tournament.id, division.id, division.bracket_type);
        if (!response || response.error) {
            setIsDisabled(false);
            return setServerMessage('error: Error saving bracket');
        }
        let updateCRC = Utils.getCRC({bracketEntries: entries}, CRC_Bracket_Fields);
        tournament.bracket_entries_crc[division.id] = updateCRC;
        tournament.caches.bracketEntries = null;
        currentDivision.crc = Utils.getCRC(currentDivision, CRC_Division_Fields);
        setIsDisabled(false);
        setServerMessage('success: Successfully update bracket');
        tournament.getBracketEntries();
        checkCRC();
    }

    const setThirdPlace = (entries) => {
        let semiRound = calculateTotalRounds(registrations.length) - 2;
        let semiLosers = entries.filter(e => e.round === semiRound && !e.result);
        if (currentDivision.bracket_type === BRACKET.Round_Robin || 
            currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_3RD ||
            registrations.length <= 2) { 
            semiLosers.forEach(e => e.place = null);
            return;
        }
        semiLosers.length <= 2 && semiLosers.forEach(e => e.place = 3);
        if ([BRACKET.Double_Elimination_Loser_2ND, BRACKET.Double_Elimination_Loser_2ND].includes(currentDivision.bracket_type)) {
            let totalConsoleRounds = calculateTotalRounds(currentDivision.getRegistrations().length);
            let fourthPlaceRound = totalConsoleRounds - 2;
            let consoleFourthEntries = entries.filter(e => e.round === fourthPlaceRound && e.isLoser && !e.result);
            if (consoleFourthEntries.length === 1) {
                consoleFourthEntries[0].place = 4;
            }
        }else if (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_1ST) {
            entries.filter(e => e.place === 3).forEach(e => e.place = null);
            let totalConsoleRounds = calculateTotalRounds(currentDivision.getRegistrations().length);
            const setThird = (rentries) => {
                rentries.forEach(e => e.place = 3);
            }
            let mainDrawFinals = entries.filter(e => e.round===totalConsoleRounds-1 && !e.isLoser && !e.isFinal_1st_double).filter(e => !e.result); 
            let consoleDrawFinals = entries.filter(e => e.round===totalConsoleRounds && e.isLoser && !e.isFinal_1st_double).filter(e => !e.result); 
            setThird(mainDrawFinals);
            setThird(consoleDrawFinals); 
        }
    }

    const updateTournamentBracketEntries = (entries) => {
        setThirdPlace(entries);
        let bes = bracketEntries.filter(be => be.division !== currentDivision.id);
        bes.push(...entries);
        setBracketEntries([...bes]);
        tournament.caches.bracketEntries = bes;
        setIsBracketEntriesUpdate();
        checkCRC();
    }

    const renderBracket = (bracketType) => {
        if (reload) {
            return '';
        }
        if (registrations.length === 0){
            return <AlertPane message={'warning: No registration found for this bracket'} />;
        }
        switch (bracketType) {
            case BRACKET.Single_Elimination:
                return <BracketSingleElimination tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    registrations={registrations}
                    isThird={isThird}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    checkCRC={() => setTimeout(checkCRC, 2000)}
                    tournament={tournament}
                />;
            case BRACKET.Round_Robin:
                return <BracketRoundRobin tabsRef={tabsRef} ref={bracketTypeRef}
                    isExportRRSummary={isExportRRSummary}
                    tournament={tournament}
                    zoom={zoom}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    checkCRC={() => setTimeout(checkCRC, 2000)}
                    isExporting={isExporting}
                    isBusy={isBusy}
                />;
            case BRACKET.Double_Elimination_Loser_3RD:
            case BRACKET.Double_Elimination_Loser_2ND:
            case BRACKET.Double_Elimination_Loser_1ST:
                return <BracketDoubleElimination tabsRef={tabsRef} ref={bracketTypeRef}
                    zoom={zoom}
                    tournament={tournament}
                    registrations={registrations}
                    currentDivision={currentDivision}
                    bracketEntries={bracketEntries.filter(e => e.division === currentDivision.id)}
                    isEditing={isEditing}
                    isDisabled={isDisabled}
                    hoveredCompetitor={hoveredCompetitor}
                    setHoveredCompetitor={setHoveredCompetitor}
                    setBracketEntries={(entries) => {
                        updateTournamentBracketEntries(entries);
                    }}
                    checkCRC={() => setTimeout(checkCRC, 2000)}
                />;
            default:
                return <div>Not implemented</div>
        }
    }

    const clearBracket = (e) => {
        e && e.stopPropagation();
        let entries = tournament.caches.bracketEntries.filter(e => ![currentDivision.id, currentDivision.pool].includes(e.division));
        setBracketEntries(entries);
        tournament.caches.bracketEntries = entries;
        // doReload();
        localServer && setLocalTournament({...tournament});
        checkCRC();
    }

    const updateZoom = (isIn) => {
        const inc = 0.25;
        if (isIn) {
            let z = zoom + inc;
            z <= 1.0 && setZoom(z);
        } else {
            let z = zoom - inc;
            z >= 0.25 && setZoom(z);
        }
    }

    const NonPlacements = ({ }) => {
        if (!currentDivision) {
            return '';
        }
        let bracketEntryIds = [...new Set(bracketEntries.map(e => e.division === currentDivision.id && e.membership))];
        let nonPlaces = registrations.filter(r => !bracketEntryIds.includes(r.membership));
        return nonPlaces.length > 0 && <div className={classNames("NonPlacements", BracketStyles.Draw)}>
            <h6>
                <Badge badgeContent={nonPlaces.length} color="primary">
                    <span>Non Placements</span>
                </Badge>
            </h6>
            {nonPlaces.map((n, i) => {
                return <UserChip key={i} membership={n.getMembership()} 
                    nameClass={`registrant ${n.status!==STATUS.Active? 'block':''}`}
                    title={`Current status: ${STATUS_LABEL(n.status)}`}
                    onClick={() => gotoCompetitor(n, tabsRef, history, tournament)}/>
            })}
        </div>;
    }

    const handleAdvanceByes = (e) => {
        e.stopPropagation();
        let entries = bracketEntries.filter(e => e.division === currentDivision.id && e.round === 0 && !e.isLoser && !e.isThird);
        let byes = entries
            .map(e => e.membership === BYE ? e.index : -1)
            .filter(index => index !== -1);

        if (byes.length === 0) {
            return;
        }
        byes = byes.map(index => {
            if (index%2 === 0) {
                index++;
            }else {
                index--;
            }
            let be = entries.find(e => e.index === index);
            let position = {
                round: be.round + 1,
                index: Math.floor(index/2),
            }
            let nextRoundEntry = {...be, ...position};
            be.result = WINS_BY.BYE.value;
            return nextRoundEntry;
        });
        
        entries.push(...byes);
        let bes = [...tournament.caches.bracketEntries.filter(be => be.division !== currentDivision.id), ...entries];
        setBracketEntries(bes);
        tournament.caches.bracketEntries = bes;
        setIsBracketEntriesUpdate();
        localServer && setLocalTournament({...tournament});
        checkCRC();
    }

    const handleAutofill = (e) => {
        setBusy(true);
        e && e.stopPropagation();
        setTimeout(() => {
            clearBracket();
            console.log('handleAutofill:', currentDivision.bracket_type);
            let entries = currentDivision.bracket_type === BRACKET.Round_Robin ?
                createRoundRobinRounds(tournament, currentDivision) : createInitialRound(tournament, currentDivision);
            tournament.caches.bracketEntries.push(
                ...entries.map(e => {
                    if (!e.getDivision) {
                        let found = tournament.poolings.find(p => p.pool === e.division);
                        if (found) {
                            found.name = found.pool;
                            found.id = found.pool;
                            e.getDivision = () => found;
                        }
                    }
                    return e;
                })
            );
            setBracketEntries(entries);
            setIsBracketEntriesUpdate();
            localServer && setLocalTournament({...tournament});
            checkCRC();
            setBusy(false);
        }, 1000);
    }

    const exportMailMerge = e => {
        const getSizeType = (isSize) => {
            let size = currentDivision.bracket_type===Bracket.RoundRobin? currentDivision.getRegistrations().length :
                calculateTotalEntriesByRound(0, currentDivision.getRegistrations().length, currentDivision.bracket_type);
            if (currentDivision.bracket_type !== BRACKET.Round_Robin) {
                if (size <= 2){
                    size = 2;
                }else if (2 < size && size <= 4){
                    size = 4;
                }else if (4 < size && size <= 8){
                    size = 8;
                }else if (8 < size && size <= 16) {
                    size = 16;
                }else if (16 < size && size <= 32) {
                    size = 32;
                }else if (32 < size && size <= 64) {
                    size = 64;
                }
            }
            if (isSize) {
                return size;
            }
            return `${currentDivision.bracket_type}-${size}`;
        }

        try{
            let nodeIds = createBracketNodeIds(getSizeType(true), currentDivision.bracket_type, isThird);

            let headers = ['tour_name', 'div_name'];
            let entries = [tournament.name, currentDivision.name];
            let bentries = bracketEntries.filter(e => e.division === currentDivision.id);
            
            const getName = be => {
                if (!be || !be.getMembership || !be.getMembership()) {
                    return '';
                }
                return UserModel.getMembershipName(be.getMembership());
            }

            if (currentDivision.bracket_type === BRACKET.Round_Robin) {
                let beByRounds = Utils.groupBy(bentries, ['round']);
                Object.keys(beByRounds).forEach(r => {
                    let matchData = beByRounds[r]
                    for (let i=0; i<matchData.length; i+=2) {
                        let be1 = matchData[i];
                        let be2 = matchData[i+1];
                        let isConsolidation = Math.floor(i/2);
                        let isRR = true;
                        be1.nodeId = createBracketNodeId({...be1, index: 0, isConsolidation, isRR});
                        be2.nodeId = createBracketNodeId({...be1, index: 1, isConsolidation, isRR});
                        let winner = (be1.result&&be1) || (be2.result&&be2);
                        if (winner) {
                            winner = {...winner, nodeId: createBracketNodeId({...winner, index: 2, isConsolidation, isRR})};
                            bentries.push(winner);
                        }
                    }
                });
            }else {
                bentries.forEach(be => be.nodeId = createBracketNodeId({...be, isConsolidation: be.isLoser}));
            }
        
            nodeIds.forEach(nid => {
                let be = bentries.find(be => {
                    return be.nodeId === nid;
                });
                headers.push(`${nid}_N`);
                headers.push(`${nid}_G`);
                entries.push(getName(be));
                entries.push(getGym(be, true));
            });

            let placeds = [];
            let totalPlaces = currentDivision.bracket_type === BRACKET.Round_Robin? currentDivision.getRegistrations().length : 4;
            for (let p=1; p<=totalPlaces; p++){
                headers.push(`${p}_place_N`);
                headers.push(`${p}_place_G`);
                headers.push(`${p}_place_Points`);
                headers.push(`${p}_place_Wins`);
                headers.push(`${p}_place_Losses`);
                let place;
                if (currentDivision.bracket_type === BRACKET.Round_Robin){
                    place = bentries.find(be => be.membership === bracketTypeRef.current.summary[p-1].membership);
                }else {
                    place = bentries.find(be => be.place===p);
                }
                if (!place || placeds.includes(place.membership)) {
                    continue;
                }
                placeds.push(place.membership);
                entries.push(getName(place));
                entries.push(getGym(place, true));
                if (currentDivision.bracket_type === BRACKET.Round_Robin) {
                    const {summary} = bracketTypeRef.current;
                    let reg = summary.find(s => s.membership === place.membership);
                    entries.push(reg.points);
                    entries.push(reg.wins);
                    entries.push(reg.losses);
                }
            }
            
            let text = `${headers.join('\t')}\n${entries.join('\t')}`;
            let sizeType = getSizeType();
            let fileName = `${currentDivision.name}.${sizeType}.mailmerge.txt`;
            if (isExportingAll) {
                let data = mailMergeExportAllRef.current[sizeType];
                if (!data) {
                    mailMergeExportAllRef.current[sizeType] = data = [];
                }else {
                    text = `${entries.join('\t')}`;
                }
                data.push(text);
            }else {
                Utils.saveTextToFile(text, fileName);
            }
        }finally {
            exportComplete && exportComplete();
        }
    }

    const ExportSummary = () => {
        return currentDivision.bracket_type===BRACKET.Round_Robin?
            <CheckboxFormInput value={isExportRRSummary} onChange={setIsExportRRSummary} label={'Summary'}/> 
            : '';
    }
    let hasBracket = currentDivision && currentDivision.getRegistrations().length > 0;
    return <ThemeProvider theme={Theme}>
        <div className={`Bracket ${isExporting? 'export':''} ${currentDivision.bracket_type===BRACKET.Round_Robin? 'portrait':'landscape'}`}>
            <div className={`header-wrapper`}>
                <NonPlacements />
                <div className="header">
                    <h2 className={`${BracketStyles.divisionTitle} bracket-title`}>
                        <Link className="Link"
                            onClick={e => {
                                e.stopPropagation();
                                let isPool = currentDivision.id===currentDivision.pool;
                                history.push(`/tournaments/${tournament.id}${isEditable? '/edit':''}?tab=${isPool? 'poolings':'divisions'}&id=${currentDivision.id}`);
                                tabsRef.current.setActiveTabByName(isPool? 'poolings':'divisions');
                            }}
                        >
                            {currentDivision && (currentDivision.pool  || currentDivision.name || currentDivision.id)}
                            <Badge badgeContent={registrations.length} color="primary">
                                <span>- {BRACKET.getBracketName(currentDivision.getBracketType())}</span>
                            </Badge>
                        </Link>
                    </h2>
                    {serverMessage && <AlertPane message={serverMessage} timeOut={5000} setMessage={setServerMessage}/>}
                    <div className={`buttons controls ${BracketStyles.buttons}`} style={Utils.isMobile() ? { flexWrap: 'wrap' } : {}} >
                        {hasBracket && <button onClick={() => updateZoom(true)} className={classNames("button", "icon_button", BracketStyles.button)}>
                            <ZoomInIcon />
                        </button>}
                        {hasBracket && <button onClick={() => updateZoom()} className={classNames("button", "icon_button", BracketStyles.button)}>
                            <ZoomOutIcon />
                        </button>}
                        {!isEditing ? isEditable && hasBracket && 
                            <button className={classNames("button", "small_button", BracketStyles.button)} 
                                onClick={() => {
                                    setIsEditing(true);
                                    setIsDisabled(false);
                                    setIsEditingBraket(true);
                                }}><EditIcon /> Edit</button> :
                            <>
                                <button className="button alt_button" onClick={() => {
                                    setIsEditing(false);
                                    setIsDisabled(false);
                                    setIsEditingBraket(false);
                                }} disabled={isDisabled}>Done
                                </button>
                                {!localServer && 
                                    <button className="button" onClick={() => saveBracketEntries()} disabled={isDisabled}>
                                        Save {isDisabled && <IonSpinner name="circles" className="spinner"/>}
                                    </button>}
                            </>}
                        {!isEditing && hasBracket && 
                            <div className="export">
                                <div className="pdf">
                                    <button ref={exportButtonRef}
                                        className={classNames("ExportButton", "button", "small_button", BracketStyles.button)}
                                        onClick={e => {
                                            e.stopPropagation();
                                            let pMsg = `Exporting ${group.label}-${currentDivision.name || currentDivision.id} to PDF...`;
                                            setIsExporting(pMsg);
                                            setTimeout(() => {
                                                bracketTypeRef.current.setIsPrinting(true);
                                                bracketDivWrapperRef.current.style.pointerEvents = 'none'; 
                                                generatePDF(bracketTypeRef, currentDivision, 
                                                    () => {
                                                        setIsExporting(false);
                                                        bracketTypeRef.current.setIsPrinting(false);
                                                        exportComplete && exportComplete();
                                                        bracketDivWrapperRef.current.style.pointerEvents = 'auto'; 
                                                        doExportCalback.current && doExportCalback.current();
                                                    },
                                                    (page=0, totalPage=1, printTitleRef) => {
                                                        let temp = printTitleRef;
                                                        try{
                                                            printTitleRef.current.setPage(`${page+1} of ${totalPage}`);
                                                            setIsExporting(`${pMsg} [ page ${page+1} ]`);
                                                        }catch(e) {
                                                            console.log(e, temp);
                                                        }
                                                        // console.log(temp);
                                                    },
                                                    isExportingAll
                                                );
                                            }, 100);
                                            
                                        }}>
                                        <ExportPDFIcon />Export
                                    </button>
                                    <ExportSummary />
                                </div>
                                <button ref={exportMailMergeButtonRef} 
                                    className={classNames('mail-merge', "button small_button", BracketStyles.button)}
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (bracketTypeRef.current) {
                                            bracketTypeRef.current.setIsPrinting(true);
                                            setTimeout(() => {
                                                exportMailMerge(e);
                                                bracketTypeRef.current.setIsPrinting(false);
                                            }, 500);
                                        }else {
                                            exportComplete && exportComplete();
                                        }
                                    }}>
                                    <ExportMailMergeIcon />Export for Mail Merge
                                </button>
                            </div>}
                    </div>
                </div>
            </div>
            {isEditing && 
                <div className={`buttons edit ${BracketStyles.buttons}`}>
                    <button onClick={handleAutofill} className="button" disabled={isDisabled}>Autofill {isBusy && <IonSpinner name="circles" className="spinner"/>}</button>
                    {currentDivision.bracket_type!==BRACKET.Round_Robin && <button onClick={handleAdvanceByes} className="button" disabled={isDisabled}>Advance Byes</button>}
                    <button className="button alt_button" onClick={clearBracket} disabled={isDisabled}>Clear
                    </button>
                    <SelectFormInput width="100%"
                        name="bracket_type"
                        label="Change Bracket Type"
                        value={currentDivision && currentDivision.bracket_type}
                        options={BRACKET_TYPES}
                        onChange={v => {
                            let d = tournament.getAvailableDivisions().find(d => d.id === currentDivision.id);
                            d.bracket_type = currentDivision.bracket_type = v;
                            delete d.is_third;
                            delete d.is_modify_round_robin;
                            currentDivision.getBracketType = () => v;
                            currentDivision.updated = true;
                            clearBracket();
                            tabObjRefs.current.checkCRC('bracketsRef');
                            localServer && setLocalTournament({...tournament});
                        }}
                    />

                    {currentDivision.bracket_type === BRACKET.Single_Elimination && currentDivision.getRegistrations(true).filter(r => r.status === STATUS.Active).length > 3 &&
                        <FormControlLabel label="Has Third Place"
                            control={<Checkbox checked={isThird? true:false}
                                onChange={e => {
                                    let d = tournament.getAvailableDivisions().find(d => d.id === currentDivision.id);
                                    let is_third = e.target.checked;
                                    d.is_third = currentDivision.is_third = is_third;
                                    currentDivision.updated = true;
                                    localServer && setLocalTournament({...tournament});
                                    let bes = [
                                        ...bracketEntries.filter(be => be.division !== currentDivision.id),
                                        ...bracketEntries.filter(be => be.division === currentDivision.id && !be.isThird)
                                    ];
                                    setBracketEntries(bes);
                                    setIsThird(is_third);
                                    checkCRC();
                                }}
                            />}
                        />}

                    {currentDivision.bracket_type === BRACKET.Single_Elimination && currentDivision.getRegistrations(true).filter(r => r.status === STATUS.Active).length === 3 &&
                        <FormControlLabel label="Modify Round Robin"
                            control={<Checkbox checked={isModifyRoundRobin? true:false}
                                onChange={e => {
                                    let d = tournament.getAvailableDivisions().find(d => d.id === currentDivision.id);
                                    let is_modify_round_robin = e.target.checked;
                                    d.is_modify_round_robin = currentDivision.is_modify_round_robin = is_modify_round_robin;
                                    currentDivision.updated = true;
                                    localServer && setLocalTournament({...tournament});
                                    setModifyRoundRobin(currentDivision.getRegistrations(true).filter(r => r.status === STATUS.Active).length === 3 && is_modify_round_robin);
                                    checkCRC();
                                }}
                            />}
                        />}
                </div>}
            {!isEditing && currentDivision.is_third ? 
                <span className="has-third"><CheckIcon /> Has Third Place</span>:''}  
            <div className="Bracket-div-wrapper" ref={bracketDivWrapperRef}>
                {currentDivision && renderBracket(currentDivision.bracket_type)}
            </div>
            <Busy message={isExporting}/>
        </div>
    </ThemeProvider>;
        
});

function getRoundName({ r, totalRounds, bracketType, isConsolidation, isThird }) {
    let isRR = bracketType === BRACKET.Round_Robin;
    if (isRR) {
        return;
    }
    if (!isConsolidation && bracketType !== BRACKET.Double_Elimination_Loser_1ST) {
        if (r === totalRounds - 2) {
            return ROUND_NAME.Final;
        }
        if (r === totalRounds - 3) {
            return ROUND_NAME.Semi;
        }
        if (r === totalRounds - 3) {
            return ROUND_NAME.Quarter;
        }
    }
    if (r === totalRounds - 1) {
        if (isConsolidation) {
            if (bracketType === BRACKET.Double_Elimination_Loser_3RD) {
                return ROUND_NAME.ThirdPlace;
            } else if (bracketType === BRACKET.Double_Elimination_Loser_2ND) {
                return ROUND_NAME.SecondPlace;
            } else if (bracketType === BRACKET.Double_Elimination_Loser_1ST) {
                return ROUND_NAME.DrawWinner;
            }
        }
        if (bracketType === BRACKET.Double_Elimination_Loser_1ST) {
            return ROUND_NAME.DrawWinner;
        }
        if (isThird) {
            return ROUND_NAME.ThirdPlace;
        }
        return ROUND_NAME.Winner;
    }
    if (isConsolidation && r === totalRounds - 2) {
        return `Round ${r + 1}`;
    }
}

const selectNodeId = (thisEl) => {
    let params = RequestUtils.getURLParameters();
    if (params.node) {
        let node = thisEl.querySelector(`.${params.node}`);
        if (node) {
            node.style.border = '2px solid blue';
            node.scrollIntoView({ behavior: 'auto', block: 'center' });
        }
    }
}

const gotoCompetitor = (r, tabsRef, history, tournament) => {
    history.push(`/tournaments/${tournament.id}/edit?tab=competitors&id=${r.id}`);
    tabsRef.current.setActiveTabByName('competitors');
}

const BracketDoubleElimination = React.forwardRef(({
    zoom,
    registrations=[],
    currentDivision,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    checkCRC,
    tabsRef,
}, ref) => {
    const divConsolidationRef = useRef();
    const [filter, setFilter] = useState(0);
    const [drawFilter, setDrawFilter] = useState({ value: 0 });
    const bracketDoubleElimationDivRef = useRef();
    const consolidationRef = useRef();
    const mainBracketRef = useRef();
    const finalDoubleEliminationRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        bracketZoom(divConsolidationRef, zoom);
    }, [zoom, drawFilter.value]);

    useLayoutEffect(() => {
        let thisEl = divConsolidationRef.current;
        if (thisEl) {
            let columns = thisEl.children;
            const isWinnerRoundOfLoser3rd = (i) => currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD && i === columns.length - 1;
            let bottoms = [0];
            for (let c = 1; c < columns.length; c++) {
                let isOddColumn = c % 2 > 0;
                let column = columns[c];
                let prevColumn = columns[c - 1];
                if (prevColumn.classList.contains('blank')) {
                    bottoms.push(parseFloat(column.style.bottom || 0));
                    continue;
                }
                let prevChildLastNodes = prevColumn.lastChild.children;
                let nextPrevColumnLastNode = prevChildLastNodes[prevChildLastNodes.length - 2];
                let lastPrevColumnNode = prevChildLastNodes[prevChildLastNodes.length - 1];
                if (!lastPrevColumnNode || !nextPrevColumnLastNode) {
                    continue;
                }
                let bottom = bottoms[c - 1];
                if (isOddColumn && !isWinnerRoundOfLoser3rd(c)) {
                    let mid = (lastPrevColumnNode.getBoundingClientRect().top - nextPrevColumnLastNode.getBoundingClientRect().bottom) / 2;
                    bottom += mid + lastPrevColumnNode.getBoundingClientRect().height / 2;
                }
                column.style.bottom = `${bottom}px`;
                bottoms.push(bottom);
            }
            let offset = bottoms.pop()
            thisEl.style.paddingTop = `${offset}px`;
            let highestTop;
            for (let c = 0; c < columns.length; c++) {
                let column = columns[c];
                if (column.classList.contains('blank')) {
                    continue;
                }
                let firstNode = column.firstChild.firstChild;
                if (!firstNode) {
                    continue;
                }
                let b = firstNode.querySelector('b');
                if (!b) {
                    continue;
                }
                let top = firstNode.getBoundingClientRect().top - b.getBoundingClientRect().height;
                if (!highestTop || top < highestTop) {
                    highestTop = top;
                }
            }
            offset = offset - (highestTop - thisEl.getBoundingClientRect().top); 
            thisEl.style.paddingTop = `${offset + 40}px`;
            renderJoins(thisEl.children, thisEl.classList.contains('bracket_consolidation') && 1, currentDivision);
            selectNodeId(thisEl);
        }
    }, [divConsolidationRef.current, currentDivision.id, currentDivision.bracket_type, filter, drawFilter.value, zoom]);

    useImperativeHandle(ref, () => ({
        divRef: bracketDoubleElimationDivRef,
        setFilter,
        setDrawFilter,
        mainBracketRef, consolidationRef, finalDoubleEliminationRef,
        setIsPrinting: isPrinting => {
            mainBracketRef.current.setIsPrinting(isPrinting);
            setIsPrinting(isPrinting);
        }
    }));

    let totalRounds = calculateTotalRounds(registrations.length, BRACKET.Double_Elimination_Loser_3RD);
    let isLoserThird = currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD;
    if (isLoserThird) {
        totalRounds -= 2;
    } else {
        totalRounds -= 1;
    }
    const handleDrawFilterClick = fs => {
        fs = drawFilter.value != fs.value ? fs : {};
        setDrawFilter(fs);
    }

    const ConsoleDraw = () => {
        return <ConsolidationBracket ref={consolidationRef}
            filter={filter}
            setFilter={setFilter} 
            isPrinting={isPrinting}
            setIsPrinting={setIsPrinting}
            totalRounds={totalRounds}
            currentDivision={currentDivision}
            getPrintTitle={getPrintTitle}
            divConsolidationRef={divConsolidationRef}
            registrations={registrations}
            isLoserThird={isLoserThird}
            bracketEntries={bracketEntries}
            isEditing={isEditing}
            isDisabled={isDisabled}
            hoveredCompetitor={hoveredCompetitor}
            setHoveredCompetitor={setHoveredCompetitor}
            setBracketEntries={entries => {
                setBracketEntries(entries.filter(e => !e.isFinal_1st_double));
            }}
            checkCRC={checkCRC}
        />
    }

    const MainDraw = () => {
        return <div className="Main Draw">
            <BracketSingleElimination ref={mainBracketRef}
                isDoubleElimination
                zoom={zoom}
                registrations={registrations}
                currentDivision={currentDivision}
                bracketEntries={bracketEntries.filter(e => !e.isLoser && !e.isFinal_1st_double)}
                isEditing={isEditing}
                isDisabled={isDisabled}
                hoveredCompetitor={hoveredCompetitor}
                setHoveredCompetitor={setHoveredCompetitor}
                setBracketEntries={(entries) => {
                    setBracketEntries([
                        ...entries,
                        ...bracketEntries.filter(e => e.isLoser),
                    ]);
                }}
                checkCRC={checkCRC}
            />
        </div>;
    }

    const FinalDoubleElimination =  React.forwardRef(({}, ref) => {
        const [pair, setPair] = useState([]);
        const [pair1, setPair1] = useState();
        const divRef = useRef();
        const pairsRef = useRef();
        const printTitleRef = useRef();

        useImperativeHandle(ref, () => ({
            divRef, printTitleRef, setIsPrinting,
        }));

        useEffect(() => {
            if (isPrinting && isPrinting.cb) {
                isPrinting();
            }
        }, [isPrinting]);

        useEffect(() => {
            let bracketSize = calculateTotalNumberOfCompetitors(registrations.filter(r => r.status === STATUS.Active).length);
            let totalRound = calculateTotalRounds(bracketSize);
            let mainEntries = bracketEntries.filter(be => !be.isLoser && be.round === totalRound);
            let consoleTotalRound = totalRound>2? totalRound+1 : totalRound;
            let consolidateEntries = bracketEntries.filter(be => be.isLoser && be.round === consoleTotalRound);
            let finalBes = bracketEntries.filter(be => be.isFinal_1st_double);
            const setMatch0 = () => {
                let round = 0;
                let pr = finalBes.filter(be => be.round === round);
                let p0 = pr.find(be => be.index===0);
                let p1 = pr.find(be => be.index===1);
                if (!p0 && mainEntries.length > 0) {
                    p0 = {...mainEntries.find(be => be.round === totalRound && be.index === 0), 
                            isFinal_1st_double: true, 
                            round, index: 0,
                            isLoser: false,
                        };
                    bracketEntries.push(p0);
                }
                if (!p1 && consolidateEntries.length > 0) {
                    p1 = {...consolidateEntries.find(be => be.round === consoleTotalRound && be.index === 0),
                            isFinal_1st_double: true, 
                            round, index: 1,
                            isLoser: false,
                        };
                    bracketEntries.push(p1);
                } 
                pr = [p0, p1];
                setPair(pr);
                return pr;
            }
            let pr = setMatch0(0, setPair);
            let pr1 = finalBes.filter(be => be.round===1);
            if (pr1.length===0 && pr.find(be => be && be.result && be.index === 1)) {
                pr1 = [
                    {...pr[1], round: 1, result: '', index: 0}, 
                    {...pr[0], round: 1, result: '', index: 1}
                ];
                bracketEntries.push(...pr1);
            }

            pr1.filter(be => be).length===2 && setPair1(pr1);

            let finalEntries = bracketEntries.filter(be => be.isFinal_1st_double);
            finalEntries.forEach(be => be.place = '');
            if (finalEntries.find(be => be.result)) {
                const setPlacements = (fes) => {
                    let first = fes.find(be => be.result);
                    if (first) {
                        first.place = 1;
                    }
                    let second = fes.find(be => !be.result);
                    if (second) {
                        second.place = 2;
                    }
                }
                if (finalEntries.find(be => be.round === 1)) {
                    setPlacements(finalEntries.filter(be => be.round === 1));
                }else {
                    setPlacements(finalEntries.filter(be => be.round === 0));
                }
            }
        }, [bracketEntries]);

        const Pair = ({_pair=[], isBracketWinner, round}) => {
            const [refresh, setRefresh] = useState(false);
            return <div className="Pair">
                {[0, 1, 2].map(index => {
                    let entry = index<2 && _pair[index];
                    let winner = _pair.find(p => p && p.result) || {};
                    let node_id = createBracketNodeId({ round: entry? entry.round:round, index: index, isFinal_1st_double: true});
                    return <div className="entry" key={`competitor${index}_${isEditing}`} id={`competitor${index}_${isEditing}`}>
                        {index===2 && index === 2 && isBracketWinner && <b className="winner-label">Bracket Winner</b>}
                        {isEditing && index === 2? 
                            <EditingCompetitor
                                tabsRef={tabsRef}
                                node_id={node_id}
                                checkCRC={checkCRC}
                                currentDivision={currentDivision}
                                round={round}
                                roundName={''}
                                mainBracketEntries={[]}
                                bracketEntries={bracketEntries}
                                index={index}
                                registrations={registrations.filter(r => [_pair[0] && _pair[0].membership, _pair[1] && _pair[1].membership].includes(r.membership))}
                                defaultValue={winner.membership}
                                defaultResult={winner.result}
                                isFinal_1st_double
                                onChange={v => {
                                    if (!v) {
                                        _pair[2] = {};
                                    }
                                    _pair.forEach((be, i) => {
                                        if (i < 2) {
                                            be.result = be.membership === v? WINS_BY.WIN.value : '';
                                        }
                                    });
                                    let bes = bracketEntries.filter(be => !be.isFinal_1st_double);
                                    if (round === 1) {
                                        bes = bracketEntries.filter(be => !(be.isFinal_1st_double && be.round === 1));
                                    }else {
                                        setPair1(null);
                                    }

                                    let entries = [...bes, _pair[0], _pair[1]];
                                    setBracketEntries(entries);
                                    checkCRC();
                                    // setTimeout(() => {
                                    //     bracketDoubleElimationDivRef.current.getElementsByClassName('FinalDoubleElimination')[0].scrollIntoView({ behavior: 'auto', block: 'center' });
                                    // }, 1000);
                                }}
                                onWinBy={v => {
                                    winner.result = v;
                                    setRefresh(!refresh);
                                    checkCRC();
                                }}
                            />
                        :
                            <Competitor 
                                isFinal_1st_double
                                roundName={`Round ${round + 1}`}
                                index={index}
                                node_id={node_id}
                                entry={index<2? entry:winner}
                                hoveredCompetitor={hoveredCompetitor}
                                setHoveredCompetitor={setHoveredCompetitor}
                                isPrinting={isPrinting}
                                bracketEntries={bracketEntries}
                                round={round}
                            />}
                    </div>
                })}
            </div>
        }

        return <div className={`FinalDoubleElimination ${isPrinting? 'export':''}`} ref={divRef}>
            <PrintTitle getPrintTitle={() => 'Final Draw'} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef}/>
            <h5>Final Double Elimination</h5>
            <div className="Pairs" ref={pairsRef} onMouseMove={mouseEvent => divScroller({divRef: pairsRef, mouseEvent})}>
               <Pair _pair={pair} isBracketWinner={!pair1} round={0}/>
                {pair1 && <Pair _pair={pair1} isBracketWinner round={1}/>} 
            </div>
        </div>;
    });

    const getPrintTitle = (isConsol) => {
        let ddDraw = ` - ${isConsol? 'Main':'Consolidation'} Draw`;
        let g = filter.toString().startsWith('group_');
        if (g) {
            let gr = filter.toString().split('group_')[1].split('_');
            let r = parseInt(gr[0]);
            g = parseInt(gr[1]);
            g = ` - Round${r+1} Group${g+1}`;
        } else {
            g = '';
        }
        return `${currentDivision.name || currentDivision.id}${ddDraw}${g}`;
    }

    return totalRounds <= 0? '' : 
        <div className="BracketDoubleElimation" ref={bracketDoubleElimationDivRef}>
            <div className={BracketStyles.DrawFilter}>
                <FilterChips 
                    filters={[{ value: 1, label: 'Main Draw' }, { value: 2, label: 'Consolation Draw' }]}
                    activeFilters={[drawFilter]}
                    onClick={handleDrawFilterClick}
                />
            </div>
            {(!drawFilter.value || drawFilter.value === 1) && <MainDraw />}
            {(!drawFilter.value || drawFilter.value === 2) && <ConsoleDraw />}
            {currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_1ST && <FinalDoubleElimination ref={finalDoubleEliminationRef}/>}
        </div>;
});


const ConsolidationBracket = forwardRef(({
    filter, 
    setFilter, 
    isPrinting, 
    setIsPrinting, 
    totalRounds, 
    currentDivision, 
    getPrintTitle, 
    divConsolidationRef,
    registrations,
    isLoserThird,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    checkCRC
}, ref) => {

    const divRef = useRef();
    const printTitleRef = useRef();
    const roundFilterRef = useRef();
    useEffect(() => {
        // console.log('BracketDoubleElimination: ConsolidationBracket ', filter, isPrinting);
        if (isPrinting && isPrinting.cb) {
            isPrinting();
        }
    }, [isPrinting]);
    useEffect(() => {
        // console.log('ConsolidationBracket: ', bracketEntries);
    
        const getLosersByRound = (round) => {
            const entries = bracketEntries.filter(be => 
                !be.isLoser && !be.isFinal_1st_double && be.round === round
            );
            const winners = entries.filter(be => be.result);
    
            return winners.map(w => {
                const loserIndex = (w.index % 2 === 0) ? w.index + 1 : w.index - 1;
                return entries.find(be => be.index === loserIndex && be.getMembership());
            }).filter(Boolean);
        };
    
        const findConsoleIndex = (cround, index) => {
            const isEven = index % 2 === 0;
            const first = isEven ? index : index - 1;
            const second = first + 1;
    
            if (cround === 0) {
                if (first % 2 !== 0 || second !== first + 1) {
                    return null; 
                }
                return first / 2; // Index is half of the first element
            }
            return second === first + 1 ? first : null;
        };
    
        let conEntries = bracketEntries.filter(be => be.isLoser);

        let hasChange = false;
        const totalRounds = calculateTotalRounds(currentDivision.getRegistrations().length) - 
                            (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_3RD ? 1 : 0);
    
        for (let round = 0; round < totalRounds; round++) {
            const losers = getLosersByRound(round);
    
            losers.forEach(l => {
                let cround = l.round >= 2 ? round + 1 : round;
                let cindex = findConsoleIndex(cround, l.index);
                let cfound = conEntries.find(be => be.round === cround && be.index === cindex);
    
                if (cfound && cfound.membership !== l.membership) {
                    conEntries.forEach(be => {
                        if (be.membership === cfound.membership) {
                            be.membership = l.membership;
                            be.getMembership = l.getMembership;
                        }
                    });
                    hasChange = true;
                } else if (!cfound) {
                    bracketEntries.push({
                        ...l, round: cround, index: cindex, isLoser: true,
                    });
                    hasChange = true;
                }
            });
    
            // if (losers.length === 0) {
            //     const isDropDownNode = be => {
            //         return be.round === 0 || (be.round > 0 && be.index%2===0);
            //     }
            //     bracketEntries = bracketEntries.filter(be => {
            //         return !be.isLoser || !isDropDownNode(be) || (be.isLoser && be.round !== round);
            //     });
            // }
        }
    
        if (hasChange || conEntries.length !== bracketEntries.filter(be => be.isLoser).length) {
            setBracketEntries([...bracketEntries]); // Avoid mutating state directly
        }
    }, [bracketEntries]);
    

    useImperativeHandle(ref, () => ({
        divRef, printTitleRef, roundFilterRef, divConsolidationRef,
        setIsPrinting: isP => {
            setIsPrinting(isP);
        },
    }));

    return <div className={`ConsolidationBracket ${BracketStyles.Draw} ${totalRounds>4? 'large-round':''}`}>
        <h5>Consolation Draw</h5>
        <RoundFilter ref={roundFilterRef}
            initFilter={filter}
            totalRounds={totalRounds}
            isConsolidation={true}
            currentDivision={currentDivision}
            onFilter={(f, isPrint) => {
                setFilter(f);
                setIsPrinting(isPrint);
            }} />
        <div className={`bracket_main_wrapper`} ref={divRef}
            onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
            <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef} bracketTypeName="ConsolidationBracket"/>
            <div ref={divConsolidationRef} className={classNames('bracket_consolidation', BracketStyles.bracket_consolidation)}>
                {Array(totalRounds).fill(0).map((_, r) => {
                    let entriesCount = calculateTotalEntriesByRound(Math.floor(r / 2), registrations.length, BRACKET.Double_Elimination_Loser_3RD);
                    if (isLoserThird && r === totalRounds - 1) {
                        entriesCount = 1;
                    }
                    return isFilter(filter, r) ? 
                        <Round key={r}
                            round={r}
                            mainBracketEntries={bracketEntries.filter(e => !e.isLoser)}
                            bracketEntries={bracketEntries.filter(e => e.isLoser)}
                            isEditing={isEditing}
                            isDisabled={isDisabled}
                            registrations={registrations}
                            hoveredCompetitor={hoveredCompetitor}
                            setHoveredCompetitor={setHoveredCompetitor}
                            setBracketEntries={(entries) => {
                                entries.forEach(be => be.place = '');
                                if (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_2ND) {
                                    let second = entries.find(be => be.round===totalRounds-1);
                                    if (second) {
                                        second.place = 2;
                                        entries.filter(be => be.round===totalRounds-2).forEach(be => {
                                            if (be.membership !== second.membership) {
                                                be.place = 3;
                                            }
                                        });
                                    }
                                }else if (currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_1ST) {
                                    let winner = entries.find(be => be.round===totalRounds-1);
                                    if (winner) {
                                        entries.filter(be => be.round===totalRounds-2).forEach(be => {
                                            if (be.membership !== winner.membership) {
                                                be.place = 3;
                                            }
                                        });
                                    }
                                }
                                setBracketEntries([
                                    ...bracketEntries.filter(e => !e.isLoser),
                                    ...entries
                                ]);
                            }}
                            currentDivision={currentDivision}
                            entriesCount={entriesCount}
                            roundName={getRoundName({ r, totalRounds, isConsolidation: true, bracketType: currentDivision.getBracketType() })}
                            isConsolidation={true}
                            filter={getEliminationGroupFilter({ filter, isConsolidation: true })}
                            isPrinting={isPrinting}
                            checkCRC={checkCRC}
                        /> : <div className="blank column" key={r}/>;
                })}
            </div>
        </div>
    </div>;
});

const BracketRoundRobin = React.forwardRef(({
    zoom,
    registrations,
    currentDivision,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    checkCRC,
    tabsRef,
    tournament,
    isExportRRSummary,
    isExporting,
}, ref) => {
    const history = useHistory();
    const [filter, setFilter] = useState({ round: 'summary' });
    const [summary, setSummary] = useState([]);
    const [isExpandSummary, setIsExpandSummary] = useState(true);
    const [isExpandAll, setIsExpandAll] = useState(false);
    const [displayRounds, setDisplayRounds] = useState([0]);

    const bracketPoolRef = useRef();
    const roundFilterRef = useRef();
    const summaryTableRef = useRef();
    const summaryRef = useRef();
    const divRef = useRef();
    const printTitleRef = useRef();
    const expandAllBusyRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    const printPageSize = 12;

    useEffect(() => {
        expandAllBusyRef.current.style.display = 'none';
    }, [isExpandAll]);

    // useEffect(() => console.log('displayRounds', [displayRounds]));

    useEffect(() => {
        summaryTableRef.current.style.display = isExpandSummary? '':'none';
    }, [isExpandSummary]);

    useEffect(() => {
        console.log('BracketRoundRobin: ', filter);
        if (isPrinting && typeof isPrinting === 'function') {
            isPrinting();
        }else {
            bracketPoolRef.current.style.display = '';
            summaryRef.current.style.display = '';
        }
        isPrinting && expandAll();
    }, [isPrinting]);

    useEffect(() => {
        bracketZoom(bracketPoolRef, zoom);
    }, [zoom]);
    const totalRounds = calculateTotalRounds(registrations.length, BRACKET.Round_Robin);
    
    const getRRPoints = () => {
        if (!currentDivision.getTournament) {
            return;
        }
        let rrPoints;
        let tournament = currentDivision.getTournament();
        if (tournament.use_round_robin_points) {
            rrPoints = {};
            tournament.getLeague().round_robin_points.forEach(rp => {
                rrPoints[rp.win_by] = rp.points;
            });
        }
        return rrPoints;
    }

    const getSummary = () => {
        let rrPoints = getRRPoints();
        let winners = [];
        let losers = [];
        bracketEntries.forEach(be => be.points = 0);
        Array(totalRounds).fill(0).map((_, r) => {
            let entries = bracketEntries.filter(e => e.round === r).sort((a, b) => Utils.sorter(a, b, 'index'));
            for (let i = 0; i < entries.length; i+=2) {
                let pair = [entries[i], entries[i+1]];
                let hasBye = pair.find(be => be.membership === BYE);
                if (!hasBye) {
                    let winner = pair.find(be => be.result);
                    if (winner) {
                        if (rrPoints){
                            let points = rrPoints[winner.result];
                            winner.points = points || 1;
                        }
                        winners.push(winner.membership);
                        let loser = pair.find(be => !be.result);
                        if (loser) {
                            losers.push(loser.membership);
                        }
                    }
                }
            }
        });
        let order = registrations.map(r => {
            r.wins = winners.filter(w => w === r.membership).length;
            r.losses = losers.filter(w => w === r.membership).length;
            r.points = rrPoints? Utils.sumArray(bracketEntries.filter(be => be.membership === r.membership).map(be => be.points)) 
                : r.wins - r.losses;
            return r;
        }).sort((a, b) => Utils.sorter(b, a, 'points'));
        setSummary(order);
    };

    useEffect(() => {
        getSummary();
        roundFilterRef.current = {
            toogleCSS: (isShow) => {
                let competitorWrapperEls = divRef.current.getElementsByClassName('competitor-name');
                for (let cEl of competitorWrapperEls) {
                    cEl.style.fontFamily = isShow? '':'';
                }
            },
            toggleDisplay: isShow => {
                roundFilterRef.current.toogleCSS();
                let matchEls = divRef.current.getElementsByClassName(`match`);
                for (let mEl of matchEls) {
                    mEl.style.display = isShow? '':'none';
                }
                let roundTitleEls = divRef.current.getElementsByClassName(`round-title`);
                for (let rEl of roundTitleEls) {
                    rEl.style.display = isShow? '':'none';
                }
                let winnerHeaderEls = divRef.current.getElementsByClassName(`winner-header`);
                for (let rEl of winnerHeaderEls) {
                    rEl.style.display = isShow? '':'none';
                }
            },
            getRoundFilters: () => {
                roundFilterRef.current.toogleCSS();
                let matches = Math.ceil(bracketEntries.length / 2);
                if (matches <= printPageSize) {
                    return isExportRRSummary? [{value: 'summary'}, {value: 0} ]:[];
                }
                let pages = Math.ceil(matches / printPageSize);
                let fts = new Array(pages).fill(0).map((_, index) => index).map(f => ({value: f})).reverse();
                if (isExportRRSummary){
                    fts.push({value: 'summary'});
                }
                return fts;
            },
            handleClick: (f, cb) => {
                let page = f.value;
                if (page === 'summary'){
                    summaryRef.current.style.display = '';
                    summaryTableRef.current.style.display = '';
                    bracketPoolRef.current.style.display = 'none';
                }else {
                    summaryRef.current.style.display = 'none';
                    summaryTableRef.current.style.display = 'none';
                    bracketPoolRef.current.style.display = '';
                    let rounds = Utils.groupBy(bracketEntries, ['round']);
                    let start = page * printPageSize;
                    let end = start + printPageSize;
                    let matchIndex = 0;
                    roundFilterRef.current.toggleDisplay();
                    Object.keys(rounds).forEach(r => {
                        let matches = toPairs(rounds[r]);
                        let roundTitleEl = divRef.current.getElementsByClassName(`t${r}`);
                        matches.forEach(m => {
                            if (start <= matchIndex && matchIndex < end) {
                                let matchCompEl = divRef.current.getElementsByClassName(`m${matchIndex}`);
                                matchCompEl.length>0 && (matchCompEl[0].style.display = '');
                                if (roundTitleEl.length>0) {
                                    roundTitleEl[0].style.display = '';
                                }
                            }
                            matchIndex++;
                        });
                    });
                }
                cb();
            },
        }
        
    }, [bracketEntries]);

    useImperativeHandle(ref, () => ({
        divRef,
        setFilter, filter,
        roundFilterRef,
        setIsPrinting,
        printTitleRef,
        summary,
    }));

    function toPairs(arr) {
        let result = [];
        for (let i = 0; i < arr.length; i += 2) {
          result.push([arr[i], arr[i + 1], arr[i].result? arr[i] : (arr[i+1].result? arr[i+1] : {})]);
        }
        return result;
    }

    const RoundRobin = () => {
        const divRef = useRef();
        let rounds = Utils.groupBy(bracketEntries, ['round']);
        let matchIndex = 0;
        let ts = new Date().getTime();
        let matchCount = Math.ceil(currentDivision.getRegistrations().length / 2);
        try{
            return <div className="RoundRobin" ref={divRef} onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
                {Object.keys(rounds).map((r, i) => {
                    let pairs = toPairs(rounds[r]);
                    let isDisplay = displayRounds.includes(parseInt(r));
                    return <div id={`r${r}`} className={`round r${r}`} key={i}>
                        <div className="round-header">
                            <div className="title">
                                <h4 className={`round-title t${r}`}>Round {i+1}</h4>
                                <CollapseIcon style={{display: !isDisplay&&!isExporting? '':'none'}} 
                                    onClick={e => {
                                        e.stopPropagation();
                                        setDisplayRounds([...displayRounds, parseInt(r)]);
                                    }}/>
                                <ExpandIcon style={{display: isDisplay&&!isExporting? '':'none'}}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setDisplayRounds(displayRounds.filter(d => d !== parseInt(r)));
                                    }}/>
                            </div>
                            {isDisplay && <b className="winner-header">Round Winner</b>}
                        </div>
                        {isDisplay && 
                            <div className="competitors">
                                {pairs.map((pair, index) => {
                                    let isBye = pair.find(p => p.membership === BYE);
                                    if (isBye) {
                                        isBye.getMembership = () => null;
                                    }
                                    let winner = isBye || pair.find(p => p.result) || {};
                                    return <div className={`match m${matchIndex++}`} key={index}>
                                        <span>{matchIndex}</span>
                                        {pair.map((entry, k) => {
                                            let node_id = createBracketNodeId({ round: r, index: k, isConsolidation: (matchIndex-1)%matchCount, isRR: true});
                                            return <div className="entry" key={k}>
                                                {!isBye && isEditing && k===2?
                                                    <EditingCompetitor
                                                        tabsRef={tabsRef}
                                                        rrPoints={getRRPoints()}
                                                        isRR rrIndex={0}
                                                        node_id={node_id}
                                                        checkCRC={checkCRC}
                                                        currentDivision={currentDivision}
                                                        round={r}
                                                        roundName={''}
                                                        mainBracketEntries={[]}
                                                        bracketEntries={bracketEntries}
                                                        index={index}
                                                        registrations={registrations.filter(r => [pair[0].membership, pair[1].membership].includes(r.membership))}
                                                        defaultValue={winner.membership}
                                                        defaultResult={winner.result}
                                                        isDisabled={isDisabled}
                                                        onChange={v => {
                                                            if (!v) {
                                                                pair[3] = {};
                                                            }
                                                            pair.forEach((be, i) => {
                                                                if (i < 2) {
                                                                    be.result = be.membership === v? WINS_BY.WIN.value : '';
                                                                    if (be.result) {
                                                                        pair[2] = be;
                                                                    }
                                                                }
                                                            });
                                                            setBracketEntries(bracketEntries);
                                                            checkCRC();
                                                        }}
                                                        onWinBy={v => {
                                                            winner.result = v;
                                                            getSummary();
                                                            checkCRC();
                                                        }}
                                                    />
                                                :
                                                    <Competitor 
                                                        isRR
                                                        roundName={''}
                                                        index={k}
                                                        node_id={node_id}
                                                        entry={k==2? winner : entry}
                                                        hoveredCompetitor={hoveredCompetitor}
                                                        setHoveredCompetitor={setHoveredCompetitor}
                                                        isPrinting={isPrinting}
                                                        bracketEntries={bracketEntries}
                                                        round={r}
                                                    />}
                                            </div>}
                                        )}
                                    </div>;
                                })}
                            </div>}
                    </div>;
                })}
            </div>;
        }finally {
            // console.log('RoundRobin: ', new Date().getTime() - ts);
        }
    }

    const getPrintTitle = () => {
        let g = filter.group;
        if (g) {
            g = ` - Group ${g+1}`;
        } else {
            g = '';
        }
        let r = filter.round;
        if (!isNaN(r)) {
            r = ` - Round ${r+1}`;
        } else {
            r = '';
        }
        return `${currentDivision.name || currentDivision.id}${r} ${g}`;
    }

    const Summary = () => {
        return <div className="Summary" style={{display: isPrinting? 'none':''}} ref={summaryRef}>
            <h4>{!isExpandSummary? 
                <CollapseIcon onClick={e => {
                    e.stopPropagation();
                    setIsExpandSummary(true)
                }}/>
                :
                <ExpandIcon onClick={e => {
                    e.stopPropagation();
                    setIsExpandSummary(false);
                }}/>} Robin Summary</h4>
            <TableContainer component={Paper} className="TableContainer" ref={summaryTableRef} style={{display: isExpandSummary? '':'none'}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Competitor</TableCell>
                            <TableCell align="right">Placement</TableCell>
                            <TableCell align="right">Points</TableCell>
                            <TableCell align="right">Wins</TableCell>
                            <TableCell align="right">Losses</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {summary.map((reg, i) => (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {isPrinting? UserModel.getMembershipName(reg.getMembership())
                                        : <UserChip membership={reg.getMembership()} onClick={() => gotoCompetitor(reg, tabsRef, history, tournament)}/>}
                                </TableCell>
                                <TableCell align="right">{i + 1}</TableCell>
                                <TableCell align="right">{reg.points}</TableCell>
                                <TableCell align="right">{reg.wins}</TableCell>
                                <TableCell align="right">{reg.losses}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    }

    const expandAll = () => {
        let roundList = Utils.groupBy(bracketEntries, ['round']);
        let rounds = [...Array(Object.keys(roundList).length).keys()];
        setDisplayRounds(rounds);
    }
    return <ThemeProvider theme={Theme}>
        <div ref={divRef} className={`BracketRoundRobin ${isExporting? 'export':''}`}>
            <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef}/>
            <Summary />
            {!isPrinting && 
                <button className="button Expand-All" 
                    onClick={e => {
                        expandAllBusyRef.current.style.display = '';
                        setTimeout(() => {
                            setIsExpandAll(!isExpandAll);
                            if (displayRounds.length > 1){
                                setDisplayRounds([0]);
                            }else {
                                expandAll();
                            }
                        }, 500);
                    }}>{displayRounds.length>1? 'Collapse':'Expand'} All <IonSpinner ref={expandAllBusyRef} name="circles" className="spinner" style={{display: 'none'}} />
                </button>}
            <div ref={bracketPoolRef} className={BracketStyles.BracketRoundRobin}>
                <RoundRobin />
            </div>
        </div>
    </ThemeProvider>
});

let GROUP_OF = 8;
const RoundFilter = React.forwardRef(({ initFilter, totalRounds, onFilter, isConsolidation, currentDivision }, ref) => {
    const isRR = currentDivision.getBracketType() === BRACKET.Round_Robin;
    const minRounds = 1;
    const [filter, setFilter] = useState({value: 0});
    useEffect(() => {
        // currentDivision && setFilter(initFilter);
    }, [currentDivision]);
    
    useEffect(() => {
        setFilter(!initFilter? {value: 0}:{value:initFilter});
    }, [initFilter]);

    React.useImperativeHandle(ref, () => ({
        getRoundFilters: () => {
            let roundFilters = getRoundFilters()
            return [...roundFilters, ...getRRGroupFilter(roundFilters.length)];
        },
        handleClick,
    }));

    const createGroups = (groups, r=0) => {
        return Array(groups).fill(0)
            .map((_, f) => {
                return { label: `Round ${r+1} Group ${f + 1}`, value: `group_${r}_${f}` };
            });
    }
    const getRoundFilters = () => {
        let roundFilters = Array(totalRounds).fill(0)
            .map((_, f) => {
                let roundName = getRoundName({ r: f, totalRounds, bracketType: currentDivision.getBracketType(), isConsolidation });
                return { label: roundName ? roundName : `Round ${f + 1}`, value: f };
            });
        if (!isRR && currentDivision.getRegistrations().length > GROUP_OF) {
            const skip = 3
            for (let r=0; r<totalRounds-skip; r+=skip) {
                let baseEntriesCount = calculateTotalEntriesByRound(r, currentDivision.getRegistrations(true).length, BRACKET.Single_Elimination);
                if (isConsolidation) {
                    baseEntriesCount /= 2;
                }
                if (baseEntriesCount > 0) {
                    let groupCount = Math.ceil(baseEntriesCount / GROUP_OF);
                    let groups = createGroups(groupCount, r);
                    roundFilters = [...roundFilters, ...groups];
                }
            }
        }
        return roundFilters;
    }

    const getRRGroupFilter = (rounds) => {
        if (isRR) {
            let rRrounds = [];
            let groups = Math.ceil(currentDivision.getRegistrations(true).length / GROUP_OF);
            for (let r=0; r<rounds; r ++) {
                if (groups > 1) {
                    rRrounds.push(createGroups(groups, r));
                }
            }
            return rRrounds.flat();
        }
        return [];
    }
    let rounds = getRoundFilters();
    let groupRRs = getRRGroupFilter(rounds.length);
    const handleClick = (f, isPrint) => {
        if (!f || Object.keys(f).length===0) {
            return;
        }
        try{
            if (isRR) {
                let value = f.value;
                if (f.group) {
                    f.round = f.round || filter.value || 0;
                } else if (f.value === filter.value) {
                    f.round = 'summary';
                    value = f.value = -1;
                } else if (isNaN(f.value)) {
                    let rg = f.value.split('_');
                    f = { round: parseInt(rg[1]), group: parseInt(rg[2])};
                } else {
                    f = {round: value};
                }
                setFilter({value});
                return onFilter(f, isPrint);
            }

            f = filter.value != f.value ? f : {};
            onFilter(f.value || -1, isPrint);
            setFilter(f);
            return true;
        }catch(e){
            console.log('handleClick', e);
        }
    }

    return totalRounds > minRounds && filter &&
        <div className={BracketStyles.RoundFilter}>
            <FilterChips
                filters={[...rounds, ...groupRRs]}
                activeFilters={[filter]}
                onClick={handleClick}
            />
        </div>;
});

function bracketZoom(divRef, zoom) {
    if (divRef && divRef.current) {
        divRef.current.style.zoom = zoom;
    }
}

function isFilter(filter, round) {
    if (!isNaN(filter)) {
        return filter <= round;
    }
    return true;
}

const PrintTitle = forwardRef(({getPrintTitle, currentDivision, isPrinting, bracketTypeName}, ref) => {
    const [page, setPage] = useState('1');
    const divRef = useRef();
    const setStyleDisplay = isShow => {
        divRef.current.style.display = isShow? '':'none';
    }
    useImperativeHandle(ref, () => ({
        setPage,
        setStyleDisplay,
        divRef,
    }));
    useEffect(() => {
        // setStyleDisplay(isPrinting);
    }, [isPrinting]);

    return currentDivision.getTournament? 
        <div className={`PrintTitle ${isPrinting? 'export':''}`} ref={divRef}>
            <div className="division">
                <h1>{getPrintTitle()}</h1>
                <div className="tournament-name">{currentDivision.getTournament().name} - {Utils.formatDate(currentDivision.getTournament().dates.start_date)}</div>
            </div>
            <div className="banner">
                <GIHBanner icon={Logo} />
                <div className="page">page {page}</div>
            </div>
        </div> : '';
});

const BracketSingleElimination = React.forwardRef(({
    isDoubleElimination,
    zoom,
    registrations,
    currentDivision, 
    isThird,
    bracketEntries,
    isEditing,
    isDisabled,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    tabsRef,
    checkCRC,
}, ref) => {
    const [filter, setFilter] = useState(0);
    const totalRounds = calculateTotalRounds(registrations.length) + 1;
    const divRef = useRef();
    const bracketThirdRef = useRef();
    const roundFilterRef = useRef();
    const printTitleRef = useRef();
    const [isPrinting, setIsPrinting] = useState();
    useEffect(() => {
        // console.log('BracketSingleElimination: ', filter);
        if (isPrinting && ['function', 'object'].includes(typeof isPrinting)) {
            try{
                isPrinting();
            }catch(e) {
                console.log('Error executing isPrinting: ', e);
            }
        }
    }, [isPrinting]);

    useEffect(() => {
        bracketZoom(divRef, zoom);
        bracketThirdRef && bracketThirdRef.current && bracketZoom(bracketThirdRef.current.thisRef, zoom);
    }, [zoom]);
    useEffect(() => {
        isThird && bracketZoom(bracketThirdRef.current.thisRef, zoom);
    }, [isThird]);

    useImperativeHandle(ref, () => ({
        divRef,
        setFilter,
        roundFilterRef,
        bracketThirdRef,
        setIsPrinting: v => {
            setIsPrinting(v);
        },
        printTitleRef,
    }));

    const getPrintTitle = () => {
        let ddDraw = isDoubleElimination ? ' - Main Draw' : '';
        let g = filter.toString().startsWith('group_');
        if (g) {
            let gr = filter.toString().split('group_')[1].split('_');
            let r = parseInt(gr[0]);
            g = parseInt(gr[1]);
            g = ` - Round${r+1} Group${g+1}`;
        } 
        return `${currentDivision.name || currentDivision.id}${ddDraw}${g? g : ''}`;
    }
    return <div className={classNames("BracketSingleElimination")}>
        <div className={ BracketStyles.Draw}>
            {isDoubleElimination && <h5>Main Draw</h5>}
            <RoundFilter ref={roundFilterRef}
                totalRounds={totalRounds}
                currentDivision={currentDivision}
                onFilter={(f, isPrint) => {
                    setFilter(f);
                    setIsPrinting(isPrint);
                }}
            />
            <div ref={divRef} className={`bracket_main_wrapper`} 
                onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
                <PrintTitle getPrintTitle={getPrintTitle} isPrinting={isPrinting} currentDivision={currentDivision} ref={printTitleRef}/>
                <div className={classNames('bracket_main', BracketStyles.bracket)} >
                    {Array(totalRounds).fill(0).map((_, r) => {
                        return isFilter(filter, r) ?
                            <Round key={r}
                                tabsRef={tabsRef}
                                filter={getEliminationGroupFilter({ filter })}
                                round={r}
                                bracketEntries={bracketEntries.filter(b => !b.isThird)}
                                isEditing={isEditing}
                                isDisabled={isDisabled}
                                registrations={registrations}
                                hoveredCompetitor={hoveredCompetitor}
                                setHoveredCompetitor={setHoveredCompetitor}
                                setBracketEntries={(entries) => {
                                    if (currentDivision.is_third){
                                        entries = [...entries, ...bracketEntries.filter(be => be.isThird)];
                                    }
                                    setBracketEntries(entries);
                                    // bracketThirdRef && bracketThirdRef.current && bracketThirdRef.current.refresh();
                                }}
                                currentDivision={currentDivision}
                                roundName={getRoundName({ r, totalRounds, bracketType: currentDivision.getBracketType() })}
                                isPrinting={isPrinting}
                                checkCRC={checkCRC}
                            /> : ''
                    }
                    )}
                </div>
            </div>
        </div>
        {isThird && 
            <ThirdPlace 
                semiRound={totalRounds-3}
                isPrinting={isPrinting}
                getPrintTitle={getPrintTitle}
                tabsRef={tabsRef}
                isEditing={isEditing}
                isDisabled={isDisabled}
                registrations={registrations}
                hoveredCompetitor={hoveredCompetitor}
                setHoveredCompetitor={setHoveredCompetitor}
                currentDivision={currentDivision}
                filter={filter}
                ref={bracketThirdRef}
                bracketEntries={bracketEntries}
                setBracketEntries={entries => {
                    setBracketEntries(entries);
                    bracketThirdRef && bracketThirdRef.current && bracketThirdRef.current.refresh();
                }} 
                checkCRC={checkCRC}
                />}
                
    </div>;
});

const ThirdPlace = React.forwardRef(({
    semiRound,
    isPrinting, 
    getPrintTitle, 
    tabsRef, 
    isEditing, 
    isDisabled, 
    registrations, 
    hoveredCompetitor, 
    setHoveredCompetitor,
    currentDivision,
    filter,
    bracketEntries,
    setBracketEntries,
    checkCRC,
    }, ref) => {
    
    const [refresh, setRefresh] = useState(false);
    const [entries, setEntries] = useState([]);
    const divRef = useRef();
    const thirdDivRef = useRef();
    const printTitleRef = useRef();

    useImperativeHandle(ref, () => ({
        refresh: () => setRefresh(!refresh),
        divRef,
        printTitleRef,
    }));

    useEffect(() => {
        let thirdEntries = bracketEntries.filter(b => b.isThird);
        if (!isEditing) {
            return setEntries(thirdEntries);
        }
        let semiLoserEntries = getSemiLoserEntries();
        let loserMemberIds = semiLoserEntries.map(be => be.membership);  
        thirdEntries = thirdEntries.filter(be => loserMemberIds.includes(be.membership)); //remove that is not in current semi losers
        let hasChange;
        let newIdIndex = bracketEntries.length;
        semiLoserEntries.forEach(s => {
            let found = thirdEntries.find(be => be.round===0 && be.membership === s.membership);
            if (!found) {
                hasChange = true;
                let newEntry = {...s, index: s.index<2? 0:1, isThird: true, round: 0, place: null, id: `-${newIdIndex++}`}
                thirdEntries.push(newEntry);
            }
        });
        let bes = bracketEntries.filter(be => !be.isThird); 
        if (hasChange) {
            thirdEntries = thirdEntries.filter(be => be.round !== 1); //reset 3rd winner  
            setBracketEntries([...bes, ...thirdEntries]);
        }else {
            setEntries(thirdEntries);
        }
    }, [bracketEntries]);

    const getSemiLoserEntries = () => {
        const semiEntries = bracketEntries.filter(be => 
            !be.isThird && be.round === semiRound
        );
    
        const losers = [0, 1].map(index => {
            const entries = semiEntries.filter(be => Math.floor(be.index / 2) === index);
            return entries.length === 2 && entries.filter(be => !be.result).length === 1 
                ? entries.filter(be => !be.result)[0] 
                : null;
        });
    
        return losers.filter(loser => loser);
    };

    const getRegistrations = () => {
        let membershipIds = getSemiLoserEntries().map(e => e.getMembership && e.getMembership() && e.getMembership().id).filter(e => e);
        return registrations.filter(r => {
            let rdivs = [r.division, ...(r.pool||'').split('|')];
            return rdivs.includes(currentDivision.id) && membershipIds.includes(r.membership);
        });
    }

    const totalRounds = 2;
    return <div ref={divRef} className={`ThirdPlace ${BracketStyles.Draw} ${isPrinting? 'export':''}`} >
        <PrintTitle getPrintTitle={getPrintTitle} currentDivision={currentDivision} isPrinting={isPrinting} ref={printTitleRef}/>
        <h4 className="title">Third Place</h4>
        <div className={classNames('third_bracket_main', BracketStyles.bracket)} ref={thirdDivRef}
            onMouseMove={mouseEvent => divScroller({divRef: thirdDivRef, mouseEvent})}>
            {Array(totalRounds).fill(0).map((_, r) => {
                return <Round key={r}
                        tabsRef={tabsRef}
                        filter={getEliminationGroupFilter({ filter })}
                        round={r}
                        bracketEntries={entries}
                        isEditing={isEditing}
                        isDisabled={isDisabled}
                        registrations={getRegistrations()}
                        hoveredCompetitor={hoveredCompetitor}
                        setHoveredCompetitor={setHoveredCompetitor}
                        setBracketEntries={entries => {
                            setBracketEntries([...bracketEntries.filter(b => !b.isThird), ...entries]);
                        }}
                        currentDivision={currentDivision}
                        roundName={getRoundName({ r, totalRounds, bracketType: BRACKET.Single_Elimination, isThird: true })}
                        isPrinting={isPrinting}
                        isThird={true}
                        checkCRC={checkCRC}
                    /> 
            })}
        </div>
    </div>
});

function RoundHeader({ round, name, index, isConsolidation, isThird, isFinal_1st_double }) {
    const isSkip = () => {
        if (isFinal_1st_double) {
            return index!==0;
        }
        let r = round || 0;
        if (isConsolidation) {
            if (r % 2 !== 0) {
                r--;
            }
        }
        return index * Math.pow(2, r) % GROUP_OF !== 0;
    }
    if (isSkip()) {
        return '';
    }

    name = name!==undefined ? name : (isNaN(round) ? (isThird? ROUND_NAME.ThirdPlace:ROUND_NAME.Winner) : `Round ${round + 1}`);
    return <div className={classNames('round_header', CompetitorStyles.round_header)}>
        <b>{name===ROUND_NAME.Final && isThird? '':name}</b>
    </div>;
}

const ROUND_NAME = {
    Winner: 'Winner',
    Final: 'Final',
    Semi: 'Semi',
    Quarter: 'Quarter',
    ThirdPlace: '3rd Place',
    SecondPlace: '2nd Place',
    DrawWinner: 'Draw Winner',
    BracketWinner: 'Bracket Winner',
}

const findBracketEntry = (entries, {round, index, isThird, isLoser, isFinal_1st_double}) => {
    return entries.find(e => e?.index === index && 
        e.round === round && 
        Utils.isEqual(e.isLoser, isLoser) && 
        Utils.isEqual(e.isThird, isThird) &&
        Utils.isEqual(e.isFinal_1st_double, isFinal_1st_double)
    );
}
function RenderNode({
    round,
    rrIndex,
    allEntries,
    index,
    registrations,
    isDisabled,
    currentDivision,
    setBracketEntries,
    hoveredCompetitor,
    isEditing,
    bracketEntries, mainBracketEntries,
    setHoveredCompetitor,
    roundName,
    isConsolidation,
    filter,
    isPrinting,
    isThird,
    checkCRC,
    isRR,
    tabsRef,
}) {
    useEffect(() => {
        // isConsolidation && console.log('bracketEntries:', isConsolidation, bracketEntries);
    }, [bracketEntries]);

    const entry = findBracketEntry(allEntries, {index, round, isLoser: isConsolidation, isThird}) || {};
    const getMembership = (v) => {
        let found = registrations.find(r => r.membership === v);
        return found && found.getMembership();
    }
    const renderRoundNumber = () => {
        if (currentDivision.bracket_type === BRACKET.Round_Robin) {
            return index === 0 && rrIndex === 0 ? round : undefined;
        }
        return round
    }
    const getOpponentEntry = (membership) => {
        if (round === 0) {
            return;
        }
        let prevRound = round - 1;
        let prevIndex = Math.floor(index / 2);
        return bracketEntries.find(e => {
            return e.round === prevRound && [prevIndex, prevIndex + 1].includes(e.index) && e.membership !== membership;
        });
    }

    const render = () => {
        const getPrevFromBracketEntries = membership => {
            let prevs = bracketEntries
                .filter(r => {
                    const getIndex = () => {
                        if (isConsolidation && round%2 !== 0 && !Object.values(ROUND_NAME).includes(roundName)) {
                            return index - 1;
                        }
                        return index;
                    }
                    return r.round === round - 1 && Math.floor(r.index / 2) === getIndex();
                });
            if (membership){
                return prevs.find(be => be.membership === membership);
            }
            return prevs;
        }

        const setPlacement = (entry, oppEntry) => {
            if (roundName === ROUND_NAME.Winner) {
                bracketEntries.forEach(be => be.place = null);
                entry.place = 1;
                let skipExtendPlacements = !isConsolidation && currentDivision.bracket_type === BRACKET.Double_Elimination_Loser_2ND;
                if (oppEntry) {
                    if (skipExtendPlacements) {
                        oppEntry.place = null;
                    }else {
                        oppEntry.place = 2;
                    }
                }
                if (!skipExtendPlacements && !currentDivision.is_third) {
                    let semiLoserEntries = bracketEntries.filter(be => be.round === round-2 && !be.result);
                    semiLoserEntries.forEach(be => {
                        if (!be.result && ![entry.membership, oppEntry && oppEntry.membership].filter(e => e).includes(be.membership)) {
                            be.place = 3;
                        }else {
                            be.place = '';
                        }
                    });
                }
            } else if (roundName === ROUND_NAME.ThirdPlace) {
                bracketEntries.forEach(be => be.place = null);
                entry.place = 3;
                oppEntry && (oppEntry.place = 4);
            } 
        }

        let node_id = createBracketNodeId({ round: rrIndex || round, index, isConsolidation, isThird});
        if (isEditing) {
            return <EditingCompetitor key={index}
                tabsRef={tabsRef}
                isRR={isRR} rrIndex={rrIndex}
                node_id={node_id}
                checkCRC={checkCRC}
                currentDivision={currentDivision}
                isThird={isThird}
                isConsolidation={isConsolidation}
                round={renderRoundNumber()}
                roundName={roundName}
                mainBracketEntries={mainBracketEntries}
                bracketEntries={bracketEntries}
                index={index}
                registrations={registrations}
                entryId={entry?.id}
                defaultValue={entry.membership}
                defaultResult={entry.result}
                isDisabled={isDisabled}
                getOpponentEntry={getOpponentEntry}
                onChange={v => {
                    try{
                        if (Object.keys(entry).length === 0) {
                            bracketEntries.push(entry); //add new entry
                        }
                        let prevs = getPrevFromBracketEntries();
                        prevs.forEach(be => {
                            be.result = '';
                            delete be.score;
                            delete be.score2;
                            delete be.place;
                        });
                        if (!v && entry) {
                            let bes = bracketEntries.filter(be => be !== entry);
                            return setBracketEntries(bes);
                        }
                        delete entry.place;
                        let oppEntry = getOpponentEntry(v);
                        if (oppEntry) {
                            let prevBracketEntry = getPrevFromBracketEntries(v);
                            if (prevBracketEntry) {
                                prevBracketEntry.result = WINS_BY.WIN.value;
                            }
                            setPlacement(entry, oppEntry);
                        }
                        entry.tournament = currentDivision.tournament;
                        entry.division = currentDivision.id;
                        entry.index = index;
                        entry.round = round;
                        entry.isThird = isThird;
                        entry.isLoser = isConsolidation;
                        entry.membership = v;
                        entry.getMembership = () => getMembership(v);
                        
                        if (currentDivision.is_modify_round_robin && round === 1 && index === 0) {
                            let thirdLoser = bracketEntries.find(be => be.round===0 && be.index===3);
                            thirdLoser.membership = oppEntry.membership;
                            thirdLoser.getMembership = oppEntry.getMembership;
                        }
                        
                        setBracketEntries([...bracketEntries]);
                    }finally{
                        checkCRC();
                    }
                }}
                onWinBy={v => {
                    let prevBracketEntry = getPrevFromBracketEntries(entry.membership);
                    if (prevBracketEntry) {
                        prevBracketEntry.result = v;
                        let bOpp = prevBracketEntry.getOpponent && prevBracketEntry.getOpponent();
                        if (bOpp){
                            bOpp.opp.result = '';
                        }
                        // setBracketEntries(bracketEntries);
                    }
                }}
            />;
        }
        return <Competitor key={index}
            isRR={isRR}
            isThird={isThird}
            index={index}
            round={renderRoundNumber()}
            roundName={roundName}
            bracketEntries={bracketEntries}
            node_id={node_id}
            entry={entry}
            hoveredCompetitor={hoveredCompetitor}
            setHoveredCompetitor={setHoveredCompetitor}
            isConsolidation={isConsolidation}
            isPrinting={isPrinting}
        />
    }

    const isScheduled = () => {
        if (isEditing || round === 0 || rrIndex === 0 || (isConsolidation && round % 2 !== 0 && index % 2 === 0)) {
            return;
        }
        if (rrIndex === 1) {
            return true;
        }
        let isOddRound = round % 2 !== 0;
        let prevIndex = index * 2;
        if (isConsolidation && isOddRound) {
            prevIndex = (index - 1) * 2;
        }
        let prevEntries = bracketEntries.filter(b => b.round === round - 1 && [prevIndex, prevIndex + 1].includes(b.index));
        let prevLength = prevEntries.length;
        if ([prevLength>0 && prevEntries[0].membership, prevLength>1 && prevEntries[1].membership].includes(BYE)) {
            return;
        }
        let bracketId1 = prevLength > 0 && TournamentModel.getBracketId(prevEntries[0]);
        let bracketId2 = prevLength > 1 && TournamentModel.getBracketId(prevEntries[1]);
        return bracketId1 || bracketId2;
    }
    let scheduled = isScheduled();
    let cName = classNames(
        `competitor_wrapper ${isPrinting? 'export':''} group_of_${GROUP_OF}`,
        BracketStyles.competitor_wrapper, 
        createBracketNodeId(round, index, isConsolidation, isThird));
    let isFilter = () => {
        const thirdPlaceFilter = ['node_R0_I0_C0_T1', 'node_R0_I1_C0_T1', 'node_R1_I0_C0_T1'];
        if (!isNaN(filter)) {
            return false;
        }
        let nodeId = createBracketNodeId({round, index, isConsolidation, isThird});
        if (thirdPlaceFilter.includes(nodeId)){
            return false;
        }
        return !filter.includes(nodeId);
    }
    const isJoinVisible = () => {
        if (isConsolidation && round%2!==0 && index%2===0 && !Object.values(ROUND_NAME).includes(roundName)) {
            return 'none';
        }
        return undefined;
    }
    const getJoinerId = () => [isConsolidation, round, index].join('_')
    return !isFilter() && 
        <div className={`${cName}`}>
            <div className={classNames('border_center', BracketStyles.border_center)} style={{border: isJoinVisible()}} id={getJoinerId()}>
                <div className={classNames('border_center_top', BracketStyles.border_center_top)} style={{border: isJoinVisible()}} id={getJoinerId()}/>
                <div className="border_center_bottom" style={{ position: 'relative' }}>
                    {/* {tabsRef && scheduled && !isPrinting &&
                        <Link>
                            <ScheduleIcon style={{ position: 'absolute', zIndex: 10, left: -16, cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(`/tournaments/${currentDivision.tournament}/edit?tab=schedules&id=${scheduled}`);
                                    tabsRef.current.setActiveTabByName('schedules');
                                }}
                            />
                        </Link>} */}
                </div>
            </div>
            <div className={classNames('border_join', BracketStyles.border_join)} style={{border: isJoinVisible()}} id={getJoinerId()}/>
            {render()}
        </div>;
}
export const createBracketNodeId = ({ round, index, isConsolidation, isThird=false, isRR=false, isFinal_1st_double=false }) => {
    if (isRR) {
        return `node_R${round}_I${index}_C${isConsolidation}_T0`;
    }
    
    return `node_R${round}_I${index}_C${isConsolidation ? 1:0}_T${isThird? 1:0}${isFinal_1st_double? '_F1':''}`;
}
const renderJoins = (columns, isConsolidation, currentDivision) => {
    if (!columns) {
        return;
    }
    const hideJoiner = (node, unHide) => {
        let join = node.querySelector('.border_join');
        let center = node.querySelector('.border_center .border_center_top');
        if (!join) {
            return;
        }
        if (!unHide) {
            join.style.visibility = 'hidden';
            center.style.visibility = 'hidden';
        } else {
            join.style.visibility = 'visible';
            center.style.visibility = 'visible';
        }
    }
    for (let ci = 1; ci < columns.length; ci++) {
        let column = columns[ci];
        let prevColumn = columns[ci - 1];
        if (prevColumn.classList.contains('blank')) {
            if (column.firstChild) {
                for (let n = 0; n < column.firstChild.children.length; n++) {
                    hideJoiner(column.firstChild.children[n]);
                }
            }
            continue;
        }
        try {
            let nodes = prevColumn.firstChild.children;
            if (nodes.length === 0) {
                continue;
            }
            let first = nodes[0];
            let second = nodes[1];
            let nodeHeight = first.getBoundingClientRect().height / 2;
            let firstMid = first.getBoundingClientRect().top + nodeHeight;
            let secondMid = second.getBoundingClientRect().top + nodeHeight;
            let height = secondMid - firstMid;
            const getCalculateOffsetNode = () => {
                let ciChildren = column.firstChild.children;
                for (let ci = 0; ci < ciChildren.length; ci++) {
                    if (ciChildren[ci].getBoundingClientRect().top > first.getBoundingClientRect().top) {
                        return ciChildren[ci];
                    }
                }
                return column.firstChild.firstChild;
            }
            let joinOffset = getCalculateOffsetNode();
            if (!joinOffset) {
                continue;
            }
            joinOffset = joinOffset.getBoundingClientRect().top - firstMid;
            for (let si = 0; si < column.children.length; si++) {
                let splitColumn = column.children[si];
                for (let ni = 0; ni < splitColumn.children.length; ni++) {
                    let node = splitColumn.children[ni];
                    let join = node.querySelector('.border_join');
                    if (join) {
                        let isLoser3rdFinalRound = currentDivision && currentDivision.getBracketType() === BRACKET.Double_Elimination_Loser_3RD && ci === columns.length - 1;
                        if (isConsolidation === 1 && ci % 2 > 0 && ni % 2 === 0 && !isLoser3rdFinalRound) {
                            // hideJoiner(node);
                        } else {
                            hideJoiner(node, true);
                            join.style.height = `${height}px`;
                            join.style.marginTop = `-${joinOffset}px`;
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}

const getRoundRobinGroupFilter = (filter, regCount) => {
    if (isNaN(filter.group)) {
        return [];
    }
    let groupFilters = [];
    let group = filter.group;
    for (let round = 0; round <= 1; round++) {
        let groupOf = Math.floor(GROUP_OF / Math.pow(2, round))
        let startIndex = group * groupOf;
        let endIndex = startIndex + groupOf;
        for (let index = startIndex; index < endIndex && index < regCount; index++) {
            groupFilters.push(createBracketNodeId({ round, index }));
        }
    }
    return groupFilters;
}

const getEliminationGroupFilter = ({ filter, isConsolidation }) => {
    if (!filter || !filter.toString().startsWith('group_')) {
        return filter;
    }

    let group = filter.split('_');
    if (group.length !== 3) {
        return [];
    }
    let round = parseInt(group[1]);
    group = parseInt(group[2]);
    if (isNaN(group)) {
        return [];
    }
    let startIndex = group * GROUP_OF;
    let endIndex = startIndex + GROUP_OF;
    let groupFilter = [];
    const createFilter = () => {
        let rounds = round + calculateTotalRounds(GROUP_OF);
        for (let r = round; r < rounds; r++) {
            for (let index = startIndex; index < endIndex; index++) {
                groupFilter.push(createBracketNodeId({ round: r, index, isConsolidation }));
            }
            startIndex = Math.floor(startIndex / 2);
            endIndex = Math.floor(endIndex / 2);
        }
        return groupFilter;
    }

    const createConslidationFilter = () => {
        for (let round = 0; round <= 4; round++) {
            for (let index = startIndex; index < endIndex; index++) {
                groupFilter.push(createBracketNodeId({ round, index, isConsolidation }));
            }
            if (round % 2 !== 0) {
                startIndex /= 2;
                endIndex /= 2;
            }
        }
        return groupFilter;
    }

    if (!isConsolidation) {
        return createFilter();
    }
    return createConslidationFilter();
}

function RenderRound({
    rrIndex,
    round,
    allEntries,
    registrations,
    isDisabled,
    currentDivision,
    setBracketEntries,
    hoveredCompetitor,
    isEditing,
    bracketEntries, mainBracketEntries,
    setHoveredCompetitor,
    roundName,
    thisRef,
    isConsolidation,
    tabsRef,
    filter,
    isPrinting,
    isThird,
    checkCRC,
    isRR,
}) {
    const renderCompetitor = (i) => {
        return <RenderNode key={i}
            tabsRef={tabsRef}
            rrIndex={rrIndex}
            round={round}
            index={i}
            registrations={registrations}
            isDisabled={isDisabled}
            currentDivision={currentDivision}
            setBracketEntries={setBracketEntries}
            hoveredCompetitor={hoveredCompetitor}
            isEditing={isEditing}
            allEntries={allEntries}
            mainBracketEntries={mainBracketEntries}
            bracketEntries={bracketEntries}
            setHoveredCompetitor={setHoveredCompetitor}
            roundName={roundName}
            isConsolidation={isConsolidation}
            tabsRef={tabsRef} 
            filter={filter}
            isPrinting={isPrinting}
            isThird={isThird}
            checkCRC={checkCRC}
            isRR={isRR}
        />
    }

    return <div key={`round_${round}`} className={classNames('column', BracketStyles.column)} ref={thisRef}>
        {allEntries.length > 1 ?
            <div className={BracketStyles.topHalf}>
                {allEntries.map((_, i) => {
                    return renderCompetitor(i)
                })}
            </div>
            : <div className={BracketStyles.winner}>
                {renderCompetitor(0)}
            </div>}
    </div>
}

const createBracketNodeIds = (size, bracket_type, isThird) => {
    let entries = [];
    let rounds = calculateTotalRounds(size, bracket_type) + (bracket_type!==BRACKET.Round_Robin? 1:0);
    for (let round=0; round<rounds; round++) {
        if (bracket_type === BRACKET.Round_Robin) {
            let totalMatchses = Math.ceil(size/2);
            for (let match=0; match < totalMatchses; match++) {
                for (let index=0; index<=2; index++){
                    entries.push(createBracketNodeId({round, isConsolidation: match, index, isRR: true}));
                }
            }
        }else {
            let nodes = calculateTotalEntriesByRound(round, size, bracket_type);
            for (let index=0; index<nodes; index++) {
                entries.push(createBracketNodeId({round, index}));
            }
        }
    }

    if (bracket_type === BRACKET.Single_Elimination) { //third place for SE
        entries.push(...['node_R0_I0_C0_T1','node_R0_I1_C0_T1','node_R1_I0_C0_T1']);
    }

    if (![BRACKET.Single_Elimination, BRACKET.Round_Robin].includes(bracket_type)) { //DE Consolidation
        rounds = rounds + (bracket_type !== BRACKET.Double_Elimination_Loser_3RD ? 1 : 0);
        for (let round=0; round<rounds; round++) {
            let nodes = calculateTotalEntriesByRound(Math.floor(round / 2), size, bracket_type);
            for (let index=0; index<nodes; index++) {
                entries.push(createBracketNodeId({
                    round, index, isConsolidation: true, isThird
                }));
            }
        }
    }

    if (bracket_type === BRACKET.Double_Elimination_Loser_1ST) {
        entries.push(...[
            'node_R0_I0_C0_T0_F1', 'node_R0_I1_C0_T0_F1', 'node_R0_I1_C0_T0_F1',
            'node_R1_I0_C0_T0_F1', 'node_R1_I1_C0_T0_F1', 'node_R1_I1_C0_T0_F1',
        ]);
    }
    
    return entries;
}

function Round({
    round,
    rrIndex,
    mainBracketEntries,
    bracketEntries,
    isEditing,
    isDisabled,
    registrations,
    hoveredCompetitor,
    setHoveredCompetitor,
    setBracketEntries,
    currentDivision,
    entriesCount,
    roundName,
    isConsolidation,
    filter,
    tabsRef,
    isPrinting,
    isThird,
    checkCRC,
    isRR,
}) {
    const thisRef = useRef(null);
    useEffect(() => {
        // renderJoins(thisRef.current.parentElement.children);
    }, [isPrinting]); 
    
    useLayoutEffect(() => {
        thisRef.current && renderJoins(thisRef.current.parentElement.children);
        thisRef.current && selectNodeId(thisRef.current);
    }, [currentDivision, thisRef.current && thisRef.current.getClientRects()]);

    let numberOfEntries = entriesCount || calculateTotalEntriesByRound(round, registrations.length);
    let allEntries = bracketEntries.filter(entry => entry.round === round);
    for (let i = allEntries.length; i < numberOfEntries; i++) {
        allEntries.push(null);
    }
    return <RenderRound
        tabsRef={tabsRef} 
        rrIndex={rrIndex}
        round={round}
        allEntries={allEntries}
        mainBracketEntries={mainBracketEntries}
        registrations={registrations}
        isDisabled={isDisabled}
        currentDivision={currentDivision}
        setBracketEntries={entries => {
            entries = entries.map(e => {
                e.tournament = currentDivision.getTournament().id;
                return e;
            })
            setBracketEntries(entries);
        }}
        hoveredCompetitor={hoveredCompetitor}
        isEditing={isEditing}
        bracketEntries={bracketEntries}
        setHoveredCompetitor={setHoveredCompetitor}
        roundName={roundName}
        thisRef={thisRef}
        isConsolidation={isConsolidation}
        filter={filter}
        isPrinting={isPrinting}
        isThird={isThird}
        checkCRC={checkCRC}
        isRR={isRR}
    />
}

const getWinBy = ({bracketEntries, round, index, membershiId, isConsolidation, roundName, isRR, isFinal_1st_double}) => {
    let preEntries = isRR || isFinal_1st_double? bracketEntries.filter(be => be.round === parseInt(round)) :
        getPrevBracketEntries(bracketEntries, round, isConsolidation&&round%2!==0 && !Object.values(ROUND_NAME).includes(roundName)? index-1:index);
    let found = isFinal_1st_double? preEntries.find(be => be.isFinal_1st_double && be.result) : preEntries.find(e => e.membership===membershiId);
    if (found && found.result) {
        let r = WINS_BY[found.result];
        if (r) {
            return r.label;
        }
    }
    
    return '';
}

const getGym = (entry, isName) => {
    if (!entry) {
        return '';
    }
    let tour = entry.getTournament && entry.getTournament();
    if (!tour) {
        tour = entry.getDivision && entry.getDivision().getTournament && entry.getDivision().getTournament();
    }
    let found = tour && tour.getRegistrations().find(r => r.membership===entry.membership && [r.id, ...(r.pool||'').split('|')].includes(entry.division));
    if (found) {
        return found && getRegistrationGym(found, isName);
    }
    try{
        return entry.getMembership && 
            entry.getMembership() &&
            entry.getMembership().getGyms && 
            entry.getMembership().getGyms().length > 0 && 
            entry.getMembership().getGyms()[0].name;
    }catch(e){
        console.log(e);
    }
}

function Competitor({
    isFinal_1st_double,
    isRR,
    entry,
    hoveredCompetitor,
    setHoveredCompetitor,
    round,
    roundName,
    node_id,
    index,
    isConsolidation, isThird,
    isPrinting,
    bracketEntries
}) {
    const session = useStore(state => state.session);
    const [isHighLite, setIsHighLite] = useState(false);
    const divRef = useRef();
    useEffect(() => {
        let params = RequestUtils.getURLParameters();
        const doScrollToView = () => {
            if (params.highlite.split('|')[0] === entry.membership) { 
                    divRef.current.scrollIntoView({
                        behavior: 'smooth', 
                        block: 'center',     
                    });
                }
        }
        if (entry && (params.highlite||'').split('|').includes(entry.membership)){
            if (!params.round){
                doScrollToView();
                setIsHighLite('highlite');
            }else if (parseInt(params.round) === round) {
                doScrollToView();
                setIsHighLite('highlite');
            }else {
                setIsHighLite('');
            }
        }
    }, [entry]);

    const getName = () => {
        if (!entry || !entry.getMembership || !entry.membership || entry.membership === TBD) {
            return <span className="empty">TBD</span>;
        }
        if (entry.membership === BYE) {
            return <span className="empty">Bye</span>;
        }
        const membership = entry.getMembership();
        return `${membership?.first_name} ${membership?.last_name}`;
    }
    const RenderFlag = () => {
        if (!isPrinting && entry.getMembership && entry.getMembership()?.country) {
            return <FlagIcon className={CompetitorStyles.flagIcon} country={entry?.getMembership()?.country.toLowerCase()} />
        }
        return '';
    }

    // if ([ROUND_NAME.Winner, ROUND_NAME.ThirdPlace].includes(roundName) && entry && ![TBD, BYE].includes(entry.membership)) {
    //     let isWinner = ROUND_NAME.Winner === roundName;
    //     entry.place = isWinner? 1:3;
    //     let second = bracketEntries.find(b => {
    //         return b.round === entry.round - 1 && b.membership !== entry.membership;
    //     });
    //     if (second) {
    //         second.place = isWinner? 2:4;
    //     }
    // }
    
    const displayWinBy = () => {
        if (isRR || isFinal_1st_double) {
            return index===2;
        }
        return round>0;
    }

    const renderPlacement = () => {
        if (entry.place && entry.place <= 4) {
            return !isRR && entry.place? TournamentModel.renderPlacement(entry.place) : '';
        }
        return '';
    }
        

    return <div node_id={node_id} ref={divRef}
        index={entry?.index}
        key={entry?.id || 0}
        entry-id={entry?.id}
        className={classNames(
            `Competitor competitor ${isHighLite}`,
            CompetitorStyles.competitor,
            { [CompetitorStyles.hovered]: hoveredCompetitor === entry?.membership })}
        onMouseOver={() => {
            if (entry?.membership) {
                setHoveredCompetitor(entry?.membership);
            }
        }}
        onMouseOut={() => {
            setHoveredCompetitor(null);
        }}
    >
        <RoundHeader round={round} name={roundName} index={index} isConsolidation={isConsolidation} isThird={isThird} isFinal_1st_double={isFinal_1st_double}/>
        {entry ? 
            <div className={`competitor-info`}>
                <div className={CompetitorStyles.competitorName}>
                    <div className="competitor-name">
                        <Tooltip title={`${getName()} ${session && session.is_super? node_id:''}`}>
                            <Link color="inherit" href={`/users/${entry.membership}`}>
                                {getName()}
                            </Link>
                        </Tooltip>
                        <div className="gym-name">{getGym(entry, true)}</div>
                    </div>
                    <RenderFlag />
                    <div className={CompetitorStyles.placement}>{renderPlacement()}</div>
                </div>
                {displayWinBy() && <b className="win-by">{getWinBy({bracketEntries, round, index, membershiId: entry.membership, isConsolidation, roundName, isRR, isFinal_1st_double})}</b>}
            </div> : 
            <div className={CompetitorStyles.competitorName}>TBD</div>}
    </div>
}

export const getPrevBracketEntries = (bracketEntries, round, index, isFinal_1st_double) => {
    if (isFinal_1st_double) {
        return bracketEntries.filter(be => be.isFinal_1st_double && be.round === round);
    }
    return bracketEntries.filter(r => r.round === round - 1 && Math.floor(r.index / 2) === index);
}

const hasByeInPrevRound = (bracketEntries, round, index) => {
    if (round > 0) {
        return;
    }
    let hasBye = getPrevBracketEntries(bracketEntries, round, index);
    if (hasBye.length!== 2 || hasBye.find(be => [TBD, BYE, '', undefined, null].includes(be.membership))) {
        return true;
    }
}
const getCompetitorOptions = ({
    rrIndex, 
    isRR, 
    isThird, 
    registrations, 
    round, 
    index, 
    bracketEntries, 
    isConsolidation, 
    mainBracketEntries, 
    roundName, 
    isFinal_1st_double 
}) => {
    let lastRoundComps = [];
    let isRoundOdd = round % 2 !== 0;
    let isIndexOdd = index % 2 !== 0;

    const getPrevFromMain = () => {
        const getMainIndex = (index) => {
            if (round > 0){
                return Math.floor(index/2);
            }
            return index;
        };

        const getRound = () => {
            let finalRound = calculateTotalRounds(registrations.length) - 1;
            if (round > finalRound){
                return finalRound;
            }
            return round;
        }
        let founds = mainBracketEntries.filter(r => {
            return r.round === getRound() 
                && Math.floor(r.index / 2) === (isConsolidation? getMainIndex(index):index);
        }).map(r => r.membership);
        let winner = mainBracketEntries.find(r => r.round === getRound() + 1 && founds.includes(r.membership));
        return winner ? founds.filter(f => f !== winner.membership) : founds;
    }
    const getPrevFromBracketEntries = () => getPrevBracketEntries(bracketEntries, round, index, isFinal_1st_double).map(r => r.membership);

    if (round === undefined) {
        lastRoundComps = mainBracketEntries.filter(r => Math.floor(r.index / 2) === index).map(r => r.membership);
    }else if (round > 0) {
        if (!Object.values(ROUND_NAME).includes(roundName) && isConsolidation) {
            if (isRoundOdd && !isIndexOdd) {
                lastRoundComps = getPrevFromMain();
            } else if (isConsolidation && isRoundOdd) {
                lastRoundComps = bracketEntries.filter(r => r.round === round - 1 && Math.floor(r.index / 2) === Math.floor(index / 2))
                                    .map(r => r.membership);
            } else {
                lastRoundComps = getPrevFromBracketEntries();
            }
        } else {
            lastRoundComps = getPrevFromBracketEntries();
        }
    } else if (isConsolidation && round === 0) {
        lastRoundComps = getPrevFromMain();
    }

    const createOption = (r, isSuggest, isReg) => {
        if (r && r.id && r.membership) {
            const getGymName = () => {
                return r.getGym && r.getGym() && r.getGym().name;
            }
            let membership = r.getMembership();
            return {
                value: r.membership,
                label: <span className={isSuggest? CompetitorStyles.competitorSelectable: ''}>
                    {membership.first_name} {membership.last_name} [<b>{getGymName()}</b>]
                </span>,
                isReg,
                isSuggest
            };
        }
    }
    let suggests = registrations.filter(r => r && lastRoundComps.includes(r.membership)).map(r => {
        return createOption(r, true, true);
    }).filter(o => o);
    let nonSuggests = registrations.filter(r => r && !lastRoundComps.includes(r.membership)).map(r => {
        return createOption(r, null, true);
    }).filter(o => o);
    
    if ((isThird && round === 0) || isRR || isFinal_1st_double) {
        suggests = registrations.map(r => createOption(r, true, true));
        nonSuggests = [];
    }

    let byeOptions = [{ value: TBD, label: 'TBD' }, isRR? null : { value: BYE, label: 'Bye' }];
    let options = [...suggests, ...nonSuggests];
    if ((isRR && rrIndex > 0) || (!isRR && round > 0) || isFinal_1st_double){
        options = suggests;
        if (suggests.length > 1){
            byeOptions = [];
        }
    }
    return [(isFinal_1st_double || (!isRR && round>0)) && {value: '', label: <span>No Selection</span>}, ...byeOptions, ...options].filter(o => o);
}
function EditingCompetitor({
    currentDivision,
    bracketEntries, mainBracketEntries,
    index, rrIndex,
    registrations,
    defaultValue='',
    defaultResult='',
    isDisabled,
    onChange, onWinBy,
    round,
    roundName,
    isConsolidation, isThird,
    checkCRC,
    node_id,
    isRR,
    rrPoints,
    isFinal_1st_double,
    tabsRef,
}) {
    const history = useHistory();
    const session = useStore(state => state.session);
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [refresh, setRefresh] = useState(false);
    const [isWinBy, setIsWinBy] = useState(false);
    const [result, setResult] = useState();
    const [entry, setEntry] = useState();
    const winnerSelectRef = useRef();
    const selectFormInputWinByRef = useRef();
    const scorecardRef = useRef();
    
    useEffect(() => {
        setResult(defaultResult);
    }, [defaultResult]);

    useEffect(() => {
        winnerSelectRef.current = defaultValue;
        let e = findBracketEntry(bracketEntries, {index, round, isLoser: isConsolidation, isFinal_1st_double, isThird});
        setEntry(e);
        setRefresh(!refresh);
    }, [defaultValue]);

    useEffect(() => {
        // setRefresh(!refresh);
    }, [bracketEntries, mainBracketEntries, isBracketEntriesUpdate]);

    const getWinByRegName = () => {
        if (!winnerSelectRef.current) {
            return 'Select';
        }
        let reg = registrations.find(r => r.membership === winnerSelectRef.current);
        return reg? UserModel.getMembershipName(reg.getMembership()) : '';
    }
    const getWinByOptions = () => {
        const {winbys} = currentDivision.getTournament().getLeague();
        let options = WINS_BY_OPTIONS;
        if (winbys && winbys.length > 0) {
            options = winbys.map(wb => ({label: wb.name, value: wb.code}));
        }
        if (isRR && rrPoints) {
            let winBys = Object.keys(rrPoints); 
            return options.filter(wb => winBys.includes(wb.value)).map(wb => ({value: wb.value, label: `${wb.label} (${rrPoints[wb.value]} pts)`}));
        }
        return options;
    }

    let winBy = getWinBy({bracketEntries, round, index, membershiId: winnerSelectRef.current || defaultValue, isConsolidation, roundName, isRR, isFinal_1st_double});
    let options = getCompetitorOptions({
        rrIndex, isRR, isThird, 
        registrations, round, index, 
        bracketEntries, isConsolidation, 
        mainBracketEntries, roundName, 
        currentDivision, isFinal_1st_double });
    let hasNextRoundEntry = () => {
        if (isFinal_1st_double) {
            if (round === 0) {
                return bracketEntries.find(be => be.round === 1 && be.isFinal_1st_double);
            }
            return false;
        }
        let nextRound = round + 1;
        return bracketEntries.find(be => {
            const getIndex = () => {
                if (isConsolidation) {
                    if (nextRound%2 > 0) {
                        return Math.floor(index/2) + 1;
                    }
                }
                return Math.floor(index/2);
            }
            return be.round===nextRound && getIndex()===be.index && be.membership;
        });
    };

    const getMatchEntries = () => {
        if (isRR) {
            let baseIndex = parseInt(round) * Math.ceil(currentDivision.getRegistrations().length/2);
            if (node_id !== undefined) {
                // node_R0_I2_C0_T0
                let match = baseIndex + parseInt(Utils.removeAllNonNumerics(node_id.split('_')[3]));
                return [bracketEntries[match*2], bracketEntries[match*2+1]];
            }
        }
        return getPrevBracketEntries(bracketEntries, round, index, isFinal_1st_double);
    }
    let entries = getMatchEntries().filter(be => be.membership !== BYE);
    return <div className={classNames(node_id, 'EditingCompetitor', CompetitorStyles.competitor, { [CompetitorStyles.hovered]: false }, CompetitorStyles.edit)}>
        {entries.length===2 && !hasNextRoundEntry() &&
            <div className="scorecard">
                <Tooltip title="Open Score Card">
                    <IonIcon icon={ScoreCardIcon} className="icon" 
                        onClick={e => {
                            e.stopPropagation();
                            scorecardRef.current && scorecardRef.current.fullscreen();
                        }}/>
                </Tooltip>
                {currentDivision.getTournament && 
                    <div className='ScoreCard-wrapper'>
                        <ScoreCard
                            ref={scorecardRef}
                            data={{schedule: {}, bracket_entry1: entries[0], bracket_entry2: entries[1]}}
                            onChange={data => {
                                const { schedule, bracket_entry1, bracket_entry2, clock } = data;
                                console.log(data);
                            }}
                            onExit={data => {
                                if (!data){
                                    return;
                                }
                                const { bracket_entry1: be1, bracket_entry2: be2 } = data;
                                Object.assign(entries[0], be1);
                                Object.assign(entries[1], be2);
                                let w = be1.result? be1.membership : (be2.result? be2.membership : '');
                                if (w) {
                                    onChange(w);
                                    winnerSelectRef.current = w;
                                    checkCRC();
                                }
                            }}
                            tournament={currentDivision.getTournament()}
                        />
                    </div>}
            </div>}
        <div className="info">
            {session.is_super && <div className="node-id">{node_id}</div>}
            {entry && <div className="goto-registration" 
                onClick={e => {
                    e.stopPropagation();
                    let reg = currentDivision.getRegistrations().find(r => r.membership===entry.membership);
                    gotoCompetitor(reg, tabsRef, history, currentDivision.getTournament());
                }
            }>Registration</div>}
            <div className="placement">{getPlacement(entry && entry.place)}</div>
        </div>
        <ThemeProvider theme={Theme}>
            <RoundHeader round={round} name={roundName} index={index} isThird={isThird}/>
            {!isWinBy? 
                <SelectFormInput width="100%" 
                    disabled={hasNextRoundEntry() || isDisabled}
                    name="competitor"
                    label={!winBy? '' :
                        <div className="select-competitor">
                            Select a competitor
                            {(round>0 || isRR || isFinal_1st_double) && winnerSelectRef.current && 
                                <b className="win-by" 
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (currentDivision.bracket_type === BRACKET.Round_Robin) {
                                            let hasBye = bracketEntries.find(be => {
                                                let first = be.index;
                                                let second = be.index + 1;
                                                if (first%2 !== 0) {
                                                    first = be.index - 1;
                                                    second = be.index;
                                                }
                                                return be.round===round && [first, second].includes(be.index) && be.membership===BYE;
                                            });
                                            if (hasBye) {
                                                return;
                                            }
                                        }else if (!isFinal_1st_double && hasByeInPrevRound(bracketEntries, round, index)) {
                                            return;
                                        }
                                        setIsWinBy(true);
                                    }}>
                                    ({winBy})
                                </b>}</div>}
                    value={winnerSelectRef.current}
                    options={options}
                    onChange={v => {
                        onChange(v);
                        winnerSelectRef.current = v;
                        checkCRC();
                    }}
                />
                :
                <SelectFormInput width="100%" ref={selectFormInputWinByRef}
                    disabled={isDisabled}
                    name="winBy"
                    label={<div className="select-winby"><b className="name">{getWinByRegName()}</b> win by</div>}
                    value={result}
                    options={[{}, {value: 'cancel', label: <b style={{color: 'red'}}>Cancel</b>}, ...getWinByOptions()]}
                    onChange={v => {
                        try {
                            if (v === 'cancel'){
                                return;
                            }
                            onWinBy && onWinBy(v);
                            setResult(v);
                            checkCRC();
                        }finally {
                            setIsWinBy(false);
                        }
                    }}
                    onRenderComplete={() => {
                        console.log('onRenderComplete');
                        setTimeout(() => selectFormInputWinByRef.current.setOpen(true), 500);
                    }}
                    onOptionClick={v => {
                        if (v.value === result){
                            setIsWinBy(false);
                        }
                    }}
                />}
        </ThemeProvider>
    </div>
}

export const ORIENTATION = {
    landscape: 'landscape',
    portrait: 'portrait'
}
async function generatePDF(bracketTypeRef, currentDivision, cb, pcb, isExportingAll) {
    // console.log('generatePDF:', currentDivision.name);
    let isRR = currentDivision.getBracketType() === BRACKET.Round_Robin;
    let isDouble = currentDivision.getBracketType().startsWith('de');

    let hasExportThirdPlace = currentDivision.bracket_type=== BRACKET.Single_Elimination && currentDivision.is_third;
    let roundFilterRef = bracketTypeRef.current?.roundFilterRef?.current;
    const doGenerate = async (getContentDiv, getPrintTitleRef, isEnd, pdfName='') => {
        let contentDiv = getContentDiv();
        let printTitleRef = getPrintTitleRef();
        let orientation = GROUP_OF===16? ORIENTATION.portrait : ORIENTATION.landscape;
        if (isRR) {
            orientation = ORIENTATION.portrait;
        }
        
        const init = (hide) => {
            // contentDiv.style.width = hide ? contentDiv.scrollWidth + 'px' : '';
            // contentDiv.style.height = hide ? contentDiv.scollHeight + 'px' : '';
            // !hide && roundFilterRef.handleClick({}, false);
        }
        // Use html2canvas to capture the HTML element as an image
        if (contentDiv) {
            const maxWidth = 1053;
            const maxHeight = Math.ceil((11 * maxWidth)/ 8.5);
            const pdfHeight = orientation===ORIENTATION.landscape? maxHeight:maxWidth;
            const pdfWidth= orientation===ORIENTATION.landscape? maxWidth:maxHeight;
            let totalPages = [0];
            let rfilter =  (roundFilterRef && roundFilterRef.getRoundFilters()) || [];
            let groupFilters = rfilter.filter(f => f.value.toString().startsWith('group_'));
            totalPages = groupFilters.map(gf => gf.value);
            let filters = rfilter;
            let final = filters.find(f => f.label === ROUND_NAME.Final);
            if (isRR) {
                totalPages = groupFilters.length>0? groupFilters.map(gf => gf.value) : filters.map(f => f.value);
            } else {
                totalPages.reverse();
            }
            
            if (groupFilters.length > 0 && final) {
                totalPages.unshift(final.value - 1); //semi
            } 

            // Create a new jsPDF instance
            // console.log('exporting create pdf:', currentDivision.name);
            let pdf = new jsPDF({
                orientation,
                unit: 'pt',
                format: [pdfWidth, pdfHeight]
            });

            let totalPage = totalPages.length;
            let total_pages = (totalPage||1) + (hasExportThirdPlace? 1 : 0);
            let p = 0;
            const doExport = (page) => {
                return new Promise(async (resolveDoExport) => {
                    try {
                        pcb(p++, total_pages, getPrintTitleRef());
                        contentDiv = getContentDiv();
                        init(true);
            
                        const pageExport = async () => {
                            const marginWidth = 20;
                            const marginHeight = 10;
            
                            let contentDivWidth = contentDiv.scrollWidth;
                            let contentDivHeight = contentDiv.scrollHeight;
            
                            const getRatio = () => 0.9; // Scale for html2canvas
                            let canvas = await html2canvas(contentDiv, {
                                scale: getRatio(),
                                width: contentDivWidth + marginWidth,
                                height: contentDivHeight,
                            });
            
                            const imgData = canvas.toDataURL("image/jpeg", 1.0);
                            let x = marginWidth;
                            let y = marginHeight;
            
                            pdf && pdf.addImage(imgData, "JPEG", x, y, canvas.width, canvas.height);
            
                            if (totalPages.length <= 0) {
                                if (hasExportThirdPlace && bracketTypeRef?.current?.bracketThirdRef?.current?.divRef?.current) {
                                    hasExportThirdPlace = false;
                                    pdf.addPage();
                                    contentDiv = bracketTypeRef.current.bracketThirdRef.current.divRef.current;
                                    printTitleRef = bracketTypeRef.current.bracketThirdRef.current.printTitleRef;
                                    pcb(p, total_pages, printTitleRef);
                                    await pageExport();
                                } else if (pdf){
                                    if (!isExportingAll) {
                                        openPDF(pdf);
                                    }
            
                                    pdfName = pdfName ? '_' + pdfName : '';
                                    pdfName = `${currentDivision.name || currentDivision.id}${pdfName}.pdf`;
                                    pdf.save(pdfName);
                                    pdf = null;
                                    init();
            
                                    if (!isDouble || (isDouble && isEnd)) cb();
                                    try {
                                        if (isRR) roundFilterRef.toggleCSS(true);
                                    } catch (e) {
                                        console.log(e);
                                    }
                                    resolveDoExport(true); // Ensure promise resolves here
                                }
                            } else {
                                pdf.addPage();
                                await doExport(totalPages.pop());
                                resolveDoExport(true); // Resolve after recursive call
                            }
                        };
            
                        if (totalPage > 1) {
                            roundFilterRef.handleClick({ value: page }, async () => {
                                await pageExport();
                                resolveDoExport(true); // Ensure resolution here
                            });
                        } else {
                            await pageExport();
                            resolveDoExport(true); // Resolve here too
                        }
                    } catch (error) {
                        console.error('Error in doExport:', error);
                        resolveDoExport(false); // Resolve even on error to prevent hanging
                    }
                });
            };

            let r = await doExport(totalPages.pop());
            return r;
        }
    }

    if (isDouble){
        try{
            bracketTypeRef.current.setFilter([]);
            bracketTypeRef.current.setDrawFilter(1);

            roundFilterRef = bracketTypeRef.current.mainBracketRef.current.roundFilterRef.current;
            let r = await doGenerate(
                () => bracketTypeRef.current.mainBracketRef.current.divRef.current, 
                () => bracketTypeRef.current.mainBracketRef.current.printTitleRef, 
                false,
                'main');
            console.log('exporting main r: ', currentDivision.name, r);
            roundFilterRef = bracketTypeRef.current.consolidationRef.current.roundFilterRef.current;
            let hasFinalRound = bracketTypeRef.current.finalDoubleEliminationRef.current? true:false;
            r = await doGenerate(
                () => bracketTypeRef.current.consolidationRef.current.divRef.current, 
                () => bracketTypeRef.current.consolidationRef.current.printTitleRef, 
                !hasFinalRound, 
                'consolidate');
            console.log('exporting consolidation r: ', currentDivision.name, r);
            if (hasFinalRound) {
                r = await doGenerate(
                    () => bracketTypeRef.current.finalDoubleEliminationRef.current.divRef.current, 
                    () => bracketTypeRef.current.finalDoubleEliminationRef.current.printTitleRef, 
                    true, 
                    'final');
                console.log('exporting final r: ', currentDivision.name, r);
            }
        }catch(e) {
            console.log(e);
            cb();
        }
    }else {
        await doGenerate(() => bracketTypeRef?.current?.divRef?.current, () => bracketTypeRef?.current?.printTitleRef, true);
    }
}

export default BracketWrapper;