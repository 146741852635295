import { FixedSizeList as List } from 'react-window';
import TextFormInput from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import { DATA_HAS_CHANGED_MESSAGE, DATA_HAS_CHANGED_MESSAGE_WARNING, STATUS, StatusUpdate, UpdateAction, getOptions, hasTableFormChange, includesBlankOption, reloadPage, setNestedValues, stopFormSubmitByEnter } from "../Form/Form";
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef, memo } from "react";
import { useForm } from "react-hook-form";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import FormStyles from "./../Form/Form.module.scss";
import "./../Form/Form.scss";
import "./Divisions.scss";
import { GENDER_TYPES, getGenderLabel } from "./WeightClasses";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import { getAgeGroupDisplay } from "./AgeGroups";
import { getWeightClassDisplay } from "./WeightClasses";
import {
  saveOutline as saveIcon,
  addCircleOutline as addIcon,
  flashOutline as generateIcon,
} from "ionicons/icons";
import {
  Alert,
  Badge,
  Checkbox,
  Chip,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import Utils from "../../serverUtils/Utils";
import TableFormInput, { IndexCell, ToolbarButtons } from "../FormInput/TableFormInput";
import { BRACKET_TYPES } from "../Bracket/Bracket";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import AlertPane from "../FormInput/AlertPane";
import { multiWordFilter } from "../FormInput/TransferList";
import { DivisionSchema } from "../../serverUtils/Models";
import { default as EditIcon} from "@mui/icons-material/DriveFileRenameOutline";

const getGenderTypes = () => [...includesBlankOption(GENDER_TYPES), {value: 'both', label: 'Both'}];

const createEntry = (id) => ({
  id: `-${id}`,
  name: "",
  getRank: () => null,
  getAgeGroup: () => null,
  getWeightClass: () => null,
  default_timer: {},
});

export default function Divisions({ league }) {
  const [selected, setSelected] = useState();
  const [message, setMessage] = useState();
  const [isGenerate, setIsGenerate] = useState(false);
  const divisionTableRef = useRef();

  const saveEntry = async (data) => {
    let id = data.id;
    data.league = league.id;
    if (id && !id.startsWith("-")) {
      let result = await LeagueModel.updateDivision(data);
      if (result && !result.errors) {
        data.crc = Utils.getCRC(data, CRC_Fields);
        return true;
      }
    } else {
      delete data.id
      let result = await LeagueModel.addDivision(data);
      if (result && !result.errors) {
        data.id = result.id;
        data.crc = Utils.getCRC(data, CRC_Fields);
        return true;
      }
    }
    return false;
  }

  return (
    <div className={`Divisions sub_form`}>
      {isGenerate && (
        <Generate
          league={league}
          onDone={newEntries => {
            if (newEntries && newEntries.length > 0) {
              divisionTableRef.current.tableRef.current.gotoLastPage();
            }
            setIsGenerate(false);
          }}
        />
      )}
      <AlertPane message={message} setTimeout={5000} setMessage={setMessage}/>
      <div className={FormStyles.fieldDescription}>
        <DivisionTable
          ref={divisionTableRef}
          setSelected={setSelected}
          setIsGenerate={setIsGenerate}
          isHide={selected || isGenerate}
          league={league}
          setMessage={setMessage}
          onClick={id => {
            setSelected(league.getDivisions().find((d) => d.id === id));
          }}
          saveEntry={saveEntry}
        />
      </div>
      {selected && (
        <div className={FormStyles.fields}>
          <Division
            value={selected}
            league={league}
            reload={d => {
              if (d.id.startsWith('-')) {
                let divisions = league.getDivisions().filter(ld => ld.id !== d.id);
                league.getDivisions = () => divisions;
              }
              setSelected(null);
            }}
            saveEntry={saveEntry}
          />
        </div>
      )}
    </div>
  );
}

export const typeOrder = ['grappling_type', 'gender', 'age_group', 'rank', 'weight_class'];
const DIVISION_GROUP_LABEL = {
  grappling_type: 'Grappling Type',
  gender: 'Gender',
  age_group: 'Age Group',
  rank: 'Rank',
  weight_class: 'Weight Class'
}

const getUnuses = (league) => {
  if (!league) {
    return [];
  }
  const filterWeightClass = (w, gender, rank, age_group, grappling_type) => {
    return w.genders.includes(gender) 
      && w.ranks.includes(rank.id)
      && w.age_groups.includes(age_group.id)
      && w.grappling_types.includes(grappling_type);
  }
  const getRanks = (gender, gtype) => {
    return league.getRanks && 
      league.getRanks().filter(r => r.grappling_types && r.grappling_types.includes(gtype) && 
      r.genders && r.genders.includes(gender)) || [];
  }
  const getWeightClasses = (ag, rank, gender, gtype) => {
    return league.getWeightClasses && 
      league.getWeightClasses().filter(w => filterWeightClass(w, gender, rank, ag, gtype)) || [];
  }

  let gens = [];
  for (let gtype of GRAPPLING_TYPES.map(gt => gt.value)) {
    for (let gender of getGenderTypes().map(g => g.value)) {
      for (let ag of (league.getAgeGroups && league.getAgeGroups(ag => ag.grappling_types.includes(gtype) && ag.genders.includes(gender))||[])) {
        for (let rank of getRanks(gender, gtype)) {
          for (let wc of getWeightClasses(ag, rank, gender, gtype)) {
            if (
              !league.getDivisions().find((div) => {
                return (
                  div.grappling_type === gtype &&
                  div.gender === gender &&
                  div.rank === rank.id &&
                  div.age_group === ag.id &&
                  div.weight_class === wc.id
                );
              })
            ) {
              let name = [
                getGrapplingTypeLabel(gtype),
                getGenderLabel(gender),
                ag.name,
                rank.name,
                wc.name,
              ];
              let newEntry = {
                name: name.filter((n) => n).join(", "),
                league: league.id,
                grappling_type: gtype,
                gender: gender==='both'? 'MF':gender,
                age_group: ag.id,
                rank: rank.id,
                weight_class: wc.id,
                getRank: () => rank,
                getWeightClass: () => wc,
                getAgeGroup: () => ag,
              };
              newEntry.code = generateDivisionCode({
                ...newEntry,
                rank: rank.code,
                age_group: ag.code,
                weight_class: wc.code,
              });
              gens.push(newEntry);
            }
          }
        }
      }
    }
  }
  return gens;
};

const Generate = ({ league, onDone }) => {
  const [divisionFilters, setDivisionFilters] = useState([]);
  const [generates, setGenerates] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const checksRef = useRef([]);
  const selectCountRef = useRef();
  const hashtagsRef = useRef();
  const renderComboListRef = useRef();

  useEffect(() => {
    setGenerates(getUnuses(league));
  }, [league]);

  const groups = {
    grappling_type: GRAPPLING_TYPES,
    gender: GENDER_TYPES,
    rank: league.getRanks().map((r) => ({ value: r.id, label: r.name })),
    age_group: league
      .getAgeGroups()
      .map((r) => ({ value: r.id, label: r.name })),
    weight_class: league
      .getWeightClasses()
      .map((r) => ({ value: r.id, label: r.name, max: r.weight_max }))
      .sort((a, b) => Utils.sorter(a, b, "max")),
  };
  const create = async () => {
    setIsCreating(true);
      try{
      let selects = checksRef.current;
      if (selects.length > 0) {
        for (let d of selects) {
          d.hashtags =  hashtagsRef.current.querySelector('input').value;
          d.league = league.id;
          d.status = 'A';
          let result = await LeagueModel.addDivision(d);
          if (result && !result.errors) {
            d.id = result.id;
          }
        }
        let divisions = [...league.getDivisions(), ...selects];
        league.getDivisions = () => divisions;
      }
      onDone(selects);
    }finally{
      setIsCreating(false);
    }
  };

  const baseGroupFilter = ['grappling_type', 'gender'];
  const FilterButton = ({ filter, gtype }) => {
    let variant = divisionFilters.find(
      (f) => !filter || f.value === filter.value
    )
      ? "filled"
      : "outlined";
    let color = divisionFilters.includes((filter || {}).value)
      ? "primary"
      : undefined;
    return (
      <Chip
        style={{ marginBottom: 2 }}
        key={filter.value}
        className="filter"
        label={filter.label}
        variant={variant}
        color={color}
        onClick={() => {
          let isBaseGroup = ['gi','no_gi','M','F'].includes(filter.value);
          let filters = divisionFilters;
          if (isBaseGroup) {
            filters = filters.filter(d => baseGroupFilter.includes(d.type));
          }
          if (filters.find(df => df.value === filter.value)) {
            filters = filters.filter(df => df.value !== filter.value);
          } else {
            filter.type = gtype;
            filters = [...filters, filter];
          }
          setDivisionFilters(filters);
          checksRef.current = [];
          selectCountRef.current.setSelects(checksRef.current.length);
          let unUses = getUnuses(league);
          setGenerates(unUses);
        }}
      />
    );
  };

  const doFilter = () => {
    let buttonFilter =
      divisionFilters.length === 0
        ? []
        : generates.filter(g => {
            for (let t of typeOrder) {
              let values = divisionFilters
                .filter(df => df.type === t)
                .map(df => df.value);
              if (values.length > 0 && !values.includes(g[t])) {
                return false;
              }
            }
            return true;
          });

    return buttonFilter;
  };

  const checkItem = (isChecked, item) => {
    if (isChecked ) {
      checksRef.current.push(item);
    }else {
      checksRef.current = checksRef.current.filter(i => i !== item);
    }
    selectCountRef.current.setSelects(checksRef.current.length);
  }

  const SelectCount = forwardRef(({}, ref) => {
    const [selects, setSelects] = useState(0);
    useImperativeHandle(ref, () => ({
      setSelects,
    }));

    return <div className="filter-count">
      <label>Filter Entries:</label>{" "}
      <span className="count">{selects}/{divFilter.length} selected</span>
    </div>;
  });

  const RenderChipList = ({list, group}) => {
    let isBaseGroup = baseGroupFilter.includes(group);
    list.sort((a, b) => Utils.sort(a.label, b.label));
    return <div className={`RenderChipList`}>
      <div className="group-name">{DIVISION_GROUP_LABEL[group]}</div>
      {list.map((f, i) => {
        let gtypeFilters = divisionFilters.filter(df => df.type === 'grappling_type').map(df => df.value);
        let genderFilters = divisionFilters.filter(df => df.type === 'gender').map(df => df.value);
        let ageGroupFilters = divisionFilters.filter(df => df.type === 'age_group').map(df => df.value);
        let rankFilters = divisionFilters.filter(df => df.type === 'rank').map(df => df.value);
        const getList = () => {
          const doBaseFilter = i => {
              let isGender = genderFilters.length===0 || Utils.intersection(i.genders, genderFilters).length>0;
              let isType = gtypeFilters.length===0 || Utils.intersection(i.grappling_types, gtypeFilters).length>0;
              return isGender && isType;
          };
          const doAgeRankFilter = i => {
              let isAgeGroup = ageGroupFilters.length===0 || Utils.intersection(i.age_groups, ageGroupFilters).length>0;
              let isRank = rankFilters.length===0 || Utils.intersection(i.ranks, rankFilters).length>0;
              return isAgeGroup && isRank;
          }
          if (group === 'rank') {
            return league.getRanks().filter(doBaseFilter);
          }
          if (group === 'age_group') {
            return league.getAgeGroups().filter(doBaseFilter);
          }
          if (group === 'weight_class') {
            return league.getWeightClasses().filter(doBaseFilter).filter(doAgeRankFilter);
          }
          return [];
        }
        let l = getList();
        if (l.length === 0 && !isBaseGroup) {
          return;
        }
        
        return (isBaseGroup || l.find(i => i.id === f.value)) && <FilterButton key={i} filter={f} gtype={group} />;
      }).filter(g => g)}
    </div>;
  }

  const RenderComboList = forwardRef(({list}, ref) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
      list.sort((a, b) => Utils.sort(a.name, b.name));
      setItems(list);
    }, [list]);
    useImperativeHandle(ref, () => ({
      setItems,
    }));
    const Render = () => { 
      let isSelectAll = items.length>0 && checksRef.current.length === items.length;
      const Row = memo(({ index, style }) => {
        const [refresh, setRefresh] = useState(false);
        const item = items[index];
        return !item? '' : 
          <li style={style} key={index}>
            <Checkbox checked={checksRef?.current.find(i => i===item)? true:false} 
              onChange={e => {
                checkItem(e.target.checked, item);
                setRefresh(!refresh);
              }} /> 
            {item.name && <Tooltip title={item.name}><span>{item.name}</span> </Tooltip>}
          </li>;
      });
      return <Paper className={`list-filter Render`}>
        <div className="select-all">
          <Checkbox defaultChecked={isSelectAll} 
            onChange={e => {
              let isChecked = e.target.checked;
              checksRef.current = isChecked? items:[];
              setItems([...items]);
            }}/> {`Select ${isSelectAll? 'None':'All'}`}</div>
        <List className="List"
          height={300} // Adjust height to the viewport size
          itemCount={list.length}
          itemSize={Utils.isMobile()? 60:24} // Adjust item height as needed
          width="100%">
          {Row}
        </List>
      </Paper>;
    }
    return <div className="RenderComboList">
      <Render />
    </div>;
  });

  let divFilter = doFilter();
  return (
    <Paper elevation={3} className="Generate">
      <Alert severity="info">{`Found ${generates.length} unused division combinations.`}</Alert>
      <div className="chips-wrapper">
        <div className="chips">
          {typeOrder.map((g, k) => {
            return (
              <Paper key={k}
                elevation={2}
                className={`group-${g}`}
                style={{ marginTop: 20 }}
              >
                <RenderChipList list={groups[g]} group={g}/>
              </Paper>
            );
          })}
        </div>
        <SelectCount count={checksRef.current.length} ref={selectCountRef}/>
      </div>
      <TextField className="filter-text" label="Filter" placeholder="type to filter..."
        onChange={e => {
          let f = e.target.value.toLowerCase();
          renderComboListRef.current.setItems(multiWordFilter(f, divFilter, 'name'));
      }}/>
      <RenderComboList list={divFilter} ref={renderComboListRef}/>

      <TextField ref={hashtagsRef} label="Categories" className="hashtags" autoComplete="off"/>
      <div className="buttons">
        <button className="button" onClick={() => create()}>
          <IonIcon icon={addIcon} />
          Create {isCreating && <IonSpinner name="circles" className="spinner"/>}
        </button>
        <button className="button" onClick={() => onDone()}>
          Cancel
        </button>
      </div>
    </Paper>
  );
};

export const GRAPPLING = {
  Gi: "gi",
  No_Gi: "no_gi",
};
export const GRAPPLING_TYPES = [...getOptions(GRAPPLING)];
export const getGrapplingTypeLabel = (t) => {
  let f = GRAPPLING_TYPES.find((gt) => gt.value === t);
  return f ? f.label : "";
};

export const getDivisionDisplay = (division, isText) => {
  if (!division || !division.id) {
    return "";
  }
  let gtype = GRAPPLING_TYPES.find(gt => gt.value===division.grappling_type);
  gtype = gtype? gtype.label : '';
  let gender = GENDER_TYPES.find(g => g.value===division.gender);
  gender = gender? gender.label:'MF';
  let ag = division.getAgeGroup && getAgeGroupDisplay(division?.getAgeGroup());
  let wc = division.getWeightClass && getWeightClassDisplay(division?.getWeightClass());
  let rank = division.getRank && division.getRank()? Utils.capitalize(division.getRank().name):'';
  if (isText) {
    return [division.name, `[${gtype}][${gender}]${ag}[${rank}]${wc}`, division.code].filter(d => d).join(' ');
  }
  let name = [
    <span key={0}>{division.name}</span>,
    <b key={1}>{`[${gtype}][${gender}]${ag}[${rank}]${wc}`}</b>,
    <span key={2}>{division.code}</span>,
  ].filter(d => d);

  return <div className="division-display">
    {name.map(n => n)}
  </div>;
};

export const generateCode = (name, length = 2) => {
  if (!name) {
    return "";
  }
  let names = name
    .split(" ")
    .filter((n) => n)
    .filter((n) => n.charCodeAt(0) > 47);
  const getChar = (s, i) => s.toUpperCase().charAt(i || 0);
  const setCode = (s) => (isNaN(s) ? getChar(s) : s);
  return names
    .map((n, i) => i < length && setCode(n))
    .filter((n) => n)
    .join("");
};

const generateDivisionCode = (division) => {
  let gender = getGenderTypes().find(g => g.value === division.gender)? division.gender:'';
  gender = gender==='both'? 'MF':gender;
  return [
    generateCode(division.name),
    division.grappling_type===GRAPPLING.Gi? GRAPPLING.Gi:'NG',
    gender,
    division.age_group,
    division.rank,
    division.weight_class,
  ]
    .filter((c) => c)
    .map((c) => c.toUpperCase())
    .join("\\");
};

export const validateUniqueCode = codes => {
  let counts = Utils.groupCount(codes);
  return Object.keys(counts).map(c => counts[c] > 1 && c).filter(c => c);
}

const CRC_Fields = [...Utils.listObjectKeys(DivisionSchema().model), 'timer', 'over_timer', 'interstitial'];
const Division = ({ value = {}, league, reload, saveEntry }) => {
  const [formData, setFormData] = useState(value);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [codeError, setCodeError] = useState('');
  const [title, setTitle] = useState(value.name);
  const [ageGroups, setAgeGroups] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [weightClasses, setWeightClasses] = useState([]);

  const codeRef = useRef();
  const codeValueRef = useRef();
  const checkCRCTimeout = useRef();
  const [hasChange, setHasChange] = useState(false);
  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...formData, ...getValues()};
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields  },
    control,
    getValues,
    setValue,
  } = useForm();

  useEffect(() => {
  }, []);

  useEffect(() => {
    let isCustomCode = getValues().is_custom_code;
    codeRef?.current.setReadOnly(!isCustomCode);
    if (!isCustomCode) {
      let code = getGenerateDivisioncode();
      codeRef?.current.setInputValue(code);
    }
  }, [
    getValues().is_custom_code, 
    getValues().gender, 
    getValues().grappling_type, 
    getValues().rank, 
    getValues().age_group, 
    getValues().weight_class,
    getValues().name,
  ]);

  const clearValues = fields => {
    fields.forEach(f => {
      if (dirtyFields[f]) {
        setValue(f, '');
      }
    });
  }
  const ageGroupsFilterOptions = () => {
    const {grappling_type, gender} = getValues();
    return league.getAgeGroups().filter(ag => {

      return ag.grappling_types.includes(grappling_type) && (gender==='both' || ag.genders.includes(gender));
    }).map(o => ({value: o.id, label: o.name}));
  }
  useEffect(() => {
    setAgeGroups(ageGroupsFilterOptions());
    setRanks([]);
    setWeightClasses([]);
  }, [getValues().grappling_type]);
  useEffect(() => {
    setAgeGroups(ageGroupsFilterOptions());
    setRanks([]);
    setWeightClasses([]);
  }, [getValues().gender]);

  const getRanksFilter = () => {
    const {grappling_type, gender} = getValues();
    let list = league.getRanks().filter(r => {
      return r.grappling_types.includes(grappling_type) && 
        (gender==='both' || r.genders.includes(gender));
    });
    return list.map(o => {
      let wCount = getWeigtClassesFilter(o.id).length;
      return {value: o.id, label: `${o.name} [${wCount} WC]`};
    });
  }
  useEffect(() => {
    setRanks(getRanksFilter());
    setWeightClasses([]);
  }, [getValues().age_group]);

  const getWeigtClassesFilter = (_rank) => {
    const {grappling_type, gender, age_group, rank} = getValues();
    return league.getWeightClasses().filter(wc => {
      return wc.grappling_types.includes(grappling_type) && 
      (gender==='both' || wc.genders.includes(gender)) && 
        wc.age_groups.includes(age_group) &&
        wc.ranks.includes(_rank || rank);
    }).map(o => ({value: o.id, label: o.name}));
  }
  useEffect(() => {
    setWeightClasses(getWeigtClassesFilter());
  }, [getValues().rank]);

  useEffect(() => {
  }, [getValues().weight_class]);

  useEffect(() => {
    value && setNestedValues(value, setValue)
    if (!getGenderTypes().find(g => g.value === value.gender)) {
      value.gender = '';
    }
    setFormData(value);
    !value.is_custom_code && updateCode();
    value.crc = Utils.getCRC(value, CRC_Fields);
  }, [value]);

  useEffect(() => {
    let codes = league.getDivisions().map(d => d.code);
    if (!validateUniqueCode(codes)) {
      setCodeError('code is duplicated');
    }else {
      setCodeError('');
    }
  }, [codeValueRef.current]);

  const onSubmit = async (d) => {
    let params = { ...formData, ...d };
    let r = await saveEntry(params);
    if (r) {
      Object.assign(formData, params);
      formData.getAgeGroup = () => league.getAgeGroups().find(ag => ag.id === formData.age_group);
      formData.getRank = () => league.getRanks().find(r => r.id === formData.rank);
      formData.getWeightClass = () => league.getWeightClasses().find(wc => wc.id === formData.weight_class);
      setMessage("success: Successfully updated.");
      return setTimeout(() => reload(formData), 1000);
    };
    setMessage("error: Error updating server.");
  };

  const getGenerateDivisioncode = () => {
    let rank = league
        .getRanks()
        .find(r => {
          return getValues("rank") === r.id;
        });
    let ageGroup = league
      .getAgeGroups()
      .find(r => [getValues("age_group")].includes(r.id));
    let weightClass = league
      .getWeightClasses()
      .find(r =>
        [getValues("weight_class")].includes(r.id)
      );
    return generateDivisionCode({
      // name: getValues("name") || formData.name,
      grappling_type: getValues("grappling_type") || formData.grappling_type,
      gender: getValues("gender") || formData.gender,
      age_group: ageGroup && ageGroup.code,
      rank: rank && rank.code,
      weight_class: weightClass && weightClass.code,
    });
  }

  const updateCode = () => {
    try{
      if (getValues("is_custom_code")) {
        return;
      }
      setValue("code", getGenerateDivisioncode(), { shouldDirty: true });
    }finally{
      setRefresh(!refresh);
    }
  };
  
  return (
    <div className="Division">
      <AlertPane message={message} setMessage={setMessage}/>
      {hasChange && <AlertPane isFloat message={DATA_HAS_CHANGED_MESSAGE_WARNING} setMessage={setHasChange}/>}
      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        <div className={FormStyles.fieldDescription}>
          <h3>{title}</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="Division Name"
            isRequired
            register={register} setValue={setValue}
            errors={errors}
            inputProps={{className: "capitalize",}}
            onChange={v => {
              setTitle(Utils.capitalize(v));
              checkCRC();
            }}
          />
          <SelectFormInput
            value={getValues('status') || formData?.status}
            name="status"
            label="Status"
            isRequired
            setValue={setValue}
            errors={errors}
            placeholder="Select status"
            options={includesBlankOption(STATUS)}
            control={control}
            onChange={checkCRC}
          />
          <div className="code-wrapper">
            <CheckboxFormInput
              label="Is Custom Code?"
              name="is_custom_code"
              value={formData.is_custom_code}
              register={register} setValue={setValue}
              onChange={v => {
                !v && updateCode();
                setRefresh(!refresh);
                checkCRC();
              }}
            />
            <TextFormInput ref={codeRef}
              value={getValues('code')}
              className="capitalize"
              name="code"
              label="Code"
              isRequired
              register={register} setValue={setValue}
              errorText={codeError}
              inputProps={{ style: { textTransform: "uppercase" } }}
              isLabelShrunk
              isCapitalized
              onChange={v => {
                if (!v) {
                  return;
                }
                let codes = league.getDivisions().filter(d => d.id !== formData.id).map(d => d.code);
                if (!validateUniqueCode([...codes, v])) {
                  setCodeError('code is duplicated');
                }else {
                  setCodeError('');
                }
                checkCRC();
              }}
            />
          </div>
          <div className="requirements-wrapper">
            <SelectFormInput
              value={getValues('grappling_type')}
              name="grappling_type"
              label="Grappling Type"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select grappling type"
              options={includesBlankOption(GRAPPLING_TYPES)}
              control={control}
              onChange={() => {
                clearValues(['age_group', 'rank', 'weight_class']);
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValues('gender')}
              name="gender"
              label="Gender"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select gender"
              options={getGenderTypes()}
              control={control}
              onChange={() => {
                clearValues(['age_group', 'rank', 'weight_class']);
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValues('age_group')}
              name="age_group"
              label="Age Group"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select age group"
              options={includesBlankOption(ageGroups)}
              control={control}
              onChange={v => {
                clearValues(['rank', 'weight_class']);
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValues('rank')}
              name="rank"
              label="Rank"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select rank"
              options={includesBlankOption(ranks)}
              control={control}
              onChange={v => {
                clearValues(['weight_class']);
                updateCode();
                checkCRC();
              }}
            />
            <SelectFormInput
              value={getValues('weight_class')}
              name="weight_class"
              label="Weight Class"
              isRequired
              setValue={setValue}
              errors={errors}
              placeholder="Select weight class"
              options={includesBlankOption(weightClasses)}
              control={control}
              onChange={(v) => {
                updateCode();
                checkCRC();
              }}
            />
          </div>
        </div>

        <div className={FormStyles.fieldDescription}>
          <h6>Default Timer (minutes)</h6>
        </div>
        <div className={`default-timer-wrapper ${FormStyles.fields}`}>
          <TextFormInput
            value={formData?.default_timer?.timer}
            name="default_timer.timer"
            label="Timer"
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
          <TextFormInput
            value={formData?.default_timer?.over_timer}
            type="number"
            name="default_timer.over_timer"
            label="Over Timer"
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
          <TextFormInput
            value={formData?.default_timer?.interstitial}
            type="number"
            name="default_timer.interstitial"
            label="Interstitial Timer"
            register={register} setValue={setValue}
            errors={errors}
            onChange={checkCRC}
          />
        </div>
        <div className={FormStyles.fieldDescription} />
        <div className={FormStyles.fields}>
          <TextFormInput
                value={getValues("hashtags") || formData?.hashtags}
                name="hashtags"
                label="Categories"
                register={register} setValue={setValue}
                helperText="(seperate by space)"
                onChange={checkCRC}
              />
        </div>
        <div className={FormStyles.fieldDescription} />
        <div className={FormStyles.fields}>
          <CheckboxFormInput
            label="Is Block?"
            name="is_block"
            value={formData.is_block}
            register={register} setValue={setValue}
            onChange={checkCRC}
          />
          <div className="buttons">
            <IonButton type="submit">
              <IonIcon icon={saveIcon}></IonIcon>Save
            </IonButton>
            <IonButton onClick={() => reload(formData)}>Done</IonButton>
            <AlertPane message={message} setMessage={setMessage} setTimeout={5000}/>
          </div>
        </div>
      </form>
    </div>
  );
};

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Division",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
  },
  {
    id: "grappling_type",
    disablePadding: false,
    label: "Grappling Type",
  },
  {
    id: "gender",
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "age_group",
    disablePadding: false,
    label: "Age Group",
  },
  {
    id: "rank",
    disablePadding: false,
    label: "Rank",
  },
  {
    id: "weight_class",
    disablePadding: false,
    label: "Weight Class",
  },
  {
    id: "default_timer",
    disablePadding: false,
    label: "Default Timer",
  },
  {
    id: "is_block",
    disablePadding: false,
    label: "Is Block",
  },
  {
    id: "hashtags",
    disablePadding: false,
    label: "Categories",
  },
];

const DivisionTable = React.forwardRef(
  ({ league, onClick, setMessage, isHide, setIsGenerate, setSelected, saveEntry }, ref) => {
    const tableRef = useRef();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [filterData, setFilterData] = useState();
    const [filters, setFilters] = useState([]);
    const [orderBys, setOrderBys] = useState([]);

    const toolbarButtonsRef = useRef();
    const toolbarButtonsRef1 = useRef();
    const dataHasChangeRef = useRef();
    useImperativeHandle(ref, () => ({
      tableRef,
    }));

    useEffect(() => {
      league.getDivisions().forEach(d => d.crc = Utils.getCRC(d, CRC_Fields));
    }, []);

    useEffect(() => {
      setLoading(!loading);
    }, [league.getDivisions]);

    useEffect(() => {
      tableRef.current && tableRef.current.headerRef.current.setHeadCellsSelect(filters);
      tableRef.current && tableRef.current.setOrderBys(orderBys);
    }, [refresh]);

    const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
      let words = Utils.separateWords(filterString);
      if (headCell.id === "name") {
        return (league.getDivisions()).filter(d => {
          return isFilter(words, () => getDivisionDisplay(d, true));
        });
      }  
      if (headCell.id === "bracket_type") {
        return (league.getDivisions()).filter(d => {
          return isFilter(words, () => BRACKET_TYPES.find(b => b.value === d.bracket_type));
        });
      }
      if (headCell.id === "age_group") {
        return (league.getDivisions()).filter(d => {
          return isFilter(words, () =>  d.getAgeGroup() && d.getAgeGroup().name);
        });
      }
      if (headCell.id === "rank") {
        return (league.getDivisions()).filter(d => {
          return isFilter(words, () => d.getRank() && d.getRank().name);
        });
      }
      if (headCell.id === "weight_class") {
        return (league.getDivisions()).filter(d => {
          return isFilter(words, () => d.getWeightClass() && d.getWeightClass().name);
        });
      }
      if (headCell.id === "status") {
        return (league.getDivisions()).filter(d => {
          return isFilter(words, () => {
              let found = STATUS.find(s => s.value===d.status);
              return found? found.label:'';
            });
        });
      }
    };

    const checkCRC = () => {
      if (hasTableFormChange(league.getDivisions(), CRC_Fields)) {
          toolbarButtonsRef.current.setIndicator({save: 'yellow'});
          toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
          dataHasChangeRef.current.style.display = '';
      }else {
          toolbarButtonsRef.current.setIndicator({save: ''});
          toolbarButtonsRef1.current.setIndicator({save: ''});
          dataHasChangeRef.current.style.display = 'none';
      }
    }

    const doAdd = () => {
      let division = createEntry(league.getDivisions().length);
      let divisions = [...league.getDivisions(), division];
      league.getDivisions = () => divisions;
      tableRef.current.setSelected([division.id]);
      reloadPage(setLoading, () => tableRef.current.gotoLastPage());
      setSelected(division);
    };

    const doDelete = async () => {
      let selected = tableRef.current.selected;
      if (!Array.isArray(selected)) {
        selected = [selected];
      }
      if (selected.length === 0) {
        return setMessage('warning: Nothing is selected');
      }
      let fails = [];
      let deleteds = [];
      let isServerDelete = false;
      for (let s of selected) {
        if (s.startsWith('-')){
          deleteds.push(s);
          continue;
        }
        isServerDelete = true;
        let result = await LeagueModel.deleteDivision(s);
        if (result && !result.error) {
          deleteds.push(s);
        } else {
          fails.push(s);
        }
      }
      let divisions = league
        .getDivisions()
        .filter((d) => !deleteds.includes(d.id));
      league.getDivisions = () => divisions;
      setLoading(!loading);
      if (fails.length > 0) {
        setMessage(
          `error: Error deleting ${fails
            .map((f) => league.getDivisions().find((d) => d.id === f).name)
            .join(", ")}`
        );
      } else {
        setMessage("success: Successfully deleted selected divison(s)");
        isServerDelete && setTimeout(() => document.location.reload(), 2000);
      }
    };

    const doSave = async () => {
      let selecteds = league.getDivisions().filter(d => d.crc !== Utils.getCRC(d, CRC_Fields));
      for (let d of selecteds) {
        await saveEntry(d);
      }
      tableRef.current.setSelected([]);
      setMessage("success: Successfully updated server.");
      checkCRC();
    }

    const toolbarButtons = ref => {
      return <ToolbarButtons ref={ref} doAdd={doAdd} doDelete={doDelete} doSave={doSave}
        buttons={
          [
            <Badge key={0} className="import-divisions-badge" badgeContent={getUnuses(league).length.toString()} color="primary">
              <button key={0}
                className="button"
                onClick={() => setIsGenerate(true)}
              >
                <IonIcon icon={generateIcon} />
                <Tooltip title={`Import from age groups, ranks, and weight classes combinations ${getUnuses(league).length} availables`}>
                  <span>Create Multiple Divisions</span>
                </Tooltip>
              </button>
            </Badge>
          ]
        }
      />;
    }

    const tableComparator = (a, b, orderBy) => {
      if (orderBy === 'rank') {
          return {
              a: a.getRank() && a.getRank().name,
              b: b.getRank() && b.getRank().name,
          }
      }
      if (orderBy === 'weight_class') {
        return {
            a: a.getWeightClass() && a.getWeightClass().name,
            b: b.getWeightClass() && b.getWeightClass().name,
        }
      }
      if (orderBy === 'age_group') {
        return {
            a: a.getAgeGroup() && a.getAgeGroup().name,
            b: b.getAgeGroup() && b.getAgeGroup().name,
        }
      }
      if (orderBy === 'division') {
        return {
            a: getDivisionDisplay(a, true),
            b: getDivisionDisplay(b, true),
        }
      }
    }

    return (
      <div
        className="DivisionTable"
        style={{ display: isHide ? "none" : "block" }}
      >
        <div className="data-has-changed" ref={dataHasChangeRef} style={{display: 'none'}}>
          <AlertPane message={DATA_HAS_CHANGED_MESSAGE_WARNING} isFloat/>
        </div>
        <TableFormInput name="DivisionTable"
          comparator={tableComparator}
          onFilterData={(fd, headerFilter, orderBys)=> {
            if (headerFilter.find(h => h.filterString)){
                if (!filterData) {
                    setFilterData(fd);
                    setFilters(headerFilter);
                    setOrderBys(orderBys || []);
                }
            }else if (filterData){
                setFilterData(null);
                setFilters([]);
                setOrderBys([]);
            }
          }}
          isEditable
          ref={tableRef}
          getFilterData={getFilterData}
          headCells={headCells}
          data={filterData || (league.getDivisions && league.getDivisions()) || []}
          toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
          toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
          renderTRow={({ row, isSelected, index, handleClick }) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const getGrapplingType = (t) => {
              let gt = GRAPPLING_TYPES.find((g) => g.value === t);
              return gt ? gt.label : "";
            };

            const TagUpdate = () => {
              const [tags, setTags] = useState(row.hashtags||'');
              const [isUpdate, setIsUpdate] = useState(false);
              return <div className="TagUpdate">
                {!isUpdate? 
                  <div className="hashtags">
                    <EditIcon className="EditIcon" 
                      onClick={e => {
                        e.stopPropagation();
                        setIsUpdate(true);
                      }}/> 
                    {tags}
                  </div>
                  : 
                  <UpdateAction field="hashtags" 
                    row={row}
                    setRefresh={() => setRefresh(!refresh)}
                    tableRef={tableRef}
                    list={league.getDivisions()}
                    value={tags} 
                    onUpdate={v => {
                      if (v) {
                        row.hashtags = tags;
                      }
                      setIsUpdate(false);
                      checkCRC();
                    }}
                    updateComp={<TextFormInput label="Tags" value={tags} onChange={setTags}/>}
                  />
                }
              </div>;
            }

            const Block = () => {
              const [block, setBlock] = useState(row.is_block? 'true':'false');
              const [isUpdate, setIsUpdate] = useState(false);

              return <div className="Block">
                {!isUpdate? 
                  <div className="is_block">
                    <EditIcon className="EditIcon" 
                      onClick={e => {
                        e.stopPropagation();
                        setIsUpdate(true);
                      }}/> 
                    {block==='true' && <BlockIcon style={{ color: "red" }} />}
                  </div>
                :
                  <UpdateAction field="is_block" 
                    row={row}
                    setRefresh={() => setRefresh(!refresh)}
                    tableRef={tableRef}
                    list={league.getDivisions()}
                    value={block==='true'} 
                    onUpdate={v => {
                      if (v) {
                        row.is_block = Boolean(block);
                      }
                      setIsUpdate(false);
                      checkCRC();
                    }}
                    updateComp={<SelectFormInput 
                      options={includesBlankOption([{value: true.toString(), label: 'Block'}, {value: false.toString(), label: 'Un Block'}])} 
                      onChange={v => {
                        setBlock(v);
                      }}
                      value={block}/>}
                    />
                }
              </div>
            }

            const DefaultTimerUpdate = () => {
              const [defaultTimer, setDefaultTimer] = useState(row.default_timer || {});
              const [isUpdate, setIsUpdate] = useState(false);
              return <div className="DefaultTimerUpdate">
                {!isUpdate? 
                  <div className="default_timer">
                    <EditIcon className="EditIcon" 
                      onClick={e => {
                        e.stopPropagation();
                        setIsUpdate(true);
                      }}/> 
                    <div className="default-timer">
                      <div style={{ whiteSpace: "nowrap" }}>
                        Timer: {defaultTimer.timer}
                      </div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        Over timer: {defaultTimer.over_timer}
                      </div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        Interstitial Time: {defaultTimer.interstitial}
                      </div>
                    </div>
                  </div>
                  :
                  <UpdateAction field="default_timer" 
                    row={row}
                    setRefresh={() => setRefresh(!refresh)}
                    tableRef={tableRef}
                    list={league.getDivisions()}
                    value={defaultTimer} 
                    onUpdate={v => {
                      if (v) {
                        row.default_timer = defaultTimer;
                        checkCRC();
                      }
                      setIsUpdate(false);
                    }}
                    updateComp={
                      <div className="default-timer">
                        <TextFormInput
                          label="Timer"
                          type="number"
                          value={defaultTimer.timer || ""}
                          onChange={v => setDefaultTimer({...defaultTimer, timer: v})}
                        />
                        <TextFormInput
                          label="Over Timer"
                          type="number"
                          value={defaultTimer.over_timer || ""}
                          onChange={v => setDefaultTimer({...defaultTimer, over_timer: v})}
                        />
                        {/* <TextFormInput
                          label="Interstitial Time"
                          type="number"
                          value={defaultTimer.interstitial || ""}
                          onChange={v => setDefaultTimer({...defaultTimer, interstitial: v})}
                        /> */}
                      </div>
                    }
                  />
                }
              </div>
            }

            const checkboxRef = row.checkboxRef = React.createRef();
            return (
              <TableRow
                className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
                hover
                onClick={e => {
                  handleClick(e, row.id, checkboxRef);
                }}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={index}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" className="index">
                  <IndexCell ref={checkboxRef} 
                    isItemSelected={isItemSelected} 
                    labelId={labelId} index={index + 1} 
                    onEdit={() => onClick(row.id)}
                  /> 
                </TableCell>
                <TableCell
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {getDivisionDisplay(row)}
                  {row.id.startsWith("-") && !row.name && 'click to edit division'}
                </TableCell>
                <TableCell align="center">
                  <StatusUpdate row={row} 
                    list={league.getDivisions()} 
                    tableRef={tableRef} 
                    setRefresh={() => setRefresh(!refresh)}
                    onChange={checkCRC}/>
                </TableCell>
                <TableCell align="center">{row.code}</TableCell>
                <TableCell align="center">
                  {getGrapplingType(row.grappling_type)}
                </TableCell>
                <TableCell align="center">{row.gender || ""}</TableCell>
                <TableCell align="center">
                  {row.getAgeGroup() && row.getAgeGroup()?.name}
                </TableCell>
                <TableCell align="center">
                  {row.getRank() && row.getRank()?.name}
                </TableCell>
                <TableCell align="center">
                  {row.getWeightClass() && row.getWeightClass()?.name}
                </TableCell>
                <TableCell align="center"><DefaultTimerUpdate/></TableCell>
                <TableCell align="center"><Block/></TableCell>
                <TableCell align="center"><TagUpdate/></TableCell>
              </TableRow>
            );
          }}
        />
      </div>
    );
  }
);
