import TextFormInput from "../FormInput/TextFormInput";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import "./Profile.scss";
import FormStyles from "./../Form/Form.module.scss";
import PageStyles from "../../pages/Page.module.scss";
import { useForm } from "react-hook-form";
import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import SelectFormInput from "../FormInput/SelectFormInput";
import {
  IonContent,
  IonPage,
  IonSpinner,
  useIonViewWillEnter
} from "@ionic/react";
import {
  GENDER_TYPES,
  WEIGHT_UNIT,
} from "../LeagueInformationForm/WeightClasses";
import { GI_LEVEL_OPTIONS, NO_GI_LEVEL_OPTIONS } from "../LeagueInformationForm/Ranks";
import {
  FormCheckBox,
  MONTH_OPTIONS,
  IMAGE_TYPE,
  RemoveSubFormIcon,
  FormActionButtons,
  stopFormSubmitByEnter, hasTableFormChange,
  isKeyEnter,
  DATA_HAS_CHANGED_MESSAGE_WARNING,
  includesBlankOption,
  getFieldValue
} from "../Form/Form";
import { Alert, FormGroup, ThemeProvider, Link, Checkbox } from "@mui/material";
import { handleSubListAdd } from "../Form/Form";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import UserModel, {
  getMembershipName,
} from "../../serverUtils/models/UserModel";
import GymModel from "../../serverUtils/models/GymModel";
import { useHistory } from "react-router-dom";
import ImageFormInput from "../FormInput/ImageFormInput";
import ImageModel from "../../serverUtils/models/ImageModel";
import { RequestUtils } from "../../serverUtils/requests";
import ContactInfos from "../Form/ContactInfos";
import { useStore } from "../../Store";
import { STATUS_LABEL, STATUS_OPTIONS } from "../Bracket/Bracket";
import { AddressForm } from "../Form/AddressForm";
import { default as AddIcon } from "@mui/icons-material/AddCircleOutline";
import Theme from "../FormInput/Theme";
import { UserName } from "../SignUpForm/SignUpForm";
import classNames from "classnames";
import Utils from "../../serverUtils/Utils";
import { MembershipSchema } from "../../serverUtils/Models";
import AlertPane from "../FormInput/AlertPane";
import DateFormInput1 from "../FormInput/DateFormInput1";


export const COACH_TITLES = [{ value: "Head" }, { value: "Supporting" }];

export const FAMILY_RELATION = [
  { value: "Spouse" },
  { value: "Father" },
  { value: "Mother" },
  { value: "Sister" },
  { value: "Brother" },
  { value: "Grand Parent" },
];

export const PROFILE_ICON = require(`../../icons/blank-profile.svg`);
export const USER_ROLES = [
  "user",
  "league",
  "team",
  "gym",
  "tournament",
  "super",
];

const CRC_Fields = [...Utils.listObjectKeys(MembershipSchema().model).filter(k => !['display'].includes(k)), 'image_fileName', 'new_password'];
export default function Profile({ membership: _data }) {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields  },
    control,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
  } = useForm();
  const history = useHistory();
  const session = useStore((state) => state.session);
  const [data, setData] = useState(_data);
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUsernameAvail, setIsUsernameAvail] = useState();
  const [refresh, setRefresh] = useState(false);
  const [addGym, setAddGym] = useState(false);
  const entityGym = useRef();
  const checkCRCTimeout = useRef();
  const isOpen = useRef();
  const [hasChange, setHasChange] = useState(false);
  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...data, ...getValues()};
      // console.log(d);
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }
  
  let isProfilePath = document.location.pathname.endsWith("/profile");
  const onSubmit = async (_d) => {
    setMessage("");
    setIsSubmitting("save");
    try {
      let d = { ..._d };
      console.log(d);
      if (d._roles) {
        let roles = [];
        Object.keys(d._roles).forEach((r) => {
          if (d._roles[r]) {
            roles.push(r);
          }
        });
        delete d._roles;
        d.roles = roles;
      }
      if (d.gih) {
        for (let gs of d.gih) {
          gs.start_date = gs.start_date && new Date(gs.start_date).getTime();
          gs.end_date = gs.end_date && new Date(gs.end_date).getTime();
          gs.paid_date = gs.paid_date && new Date(gs.paid_date).getTime();
          gs.cancel_date = gs.cancel_date && new Date(gs.cancel_date).getTime();
        }
      }
      if (d.primary_gym) {
        if (d.primary_gym.id === -1) {
          if (!d.primary_gym.country || !d.primary_gym.city){
            return setError('primary_gym.city', { message: "This field is required" });
          }
        }else {
          d.primary_gym = d.primary_gym.id;
        }
      }
      if (d.primary_team && d.primary_team.id !== -1) {
        d.primary_team = d.primary_team.id;
      }
      if (d?.age !== data.age) {
        let y = new Date().getFullYear() - d.age;
        let dob = new Date();
        dob.setFullYear(y);
        dob.setMonth(parseInt(1));
        dob.setDate(parseInt(1));
        d.dob = dob.getTime();
      }
      if (d.user_name) {
        d.user_name = d.user_name.trim();
      }
      if (d.new_password) {
        d.new_password = d.new_password.trim();
      }
      d.id = data.id;

      let image = d.image;
      delete d.image;
      d.user_name = d.user_name.trim();
      d.email = d.email.trim();
      let r = await UserModel[data && data.id ? "updateUser" : "addUser"](d);
      if (r && r.id) {
        if (image) {
          d.id = r.id;
          await ImageModel.addEntityImage(image, data, IMAGE_TYPE.profile);
          setData({ ...d });
        }
        session.is_super && setTimeout(() => history.push('/users'), 2000);
        setMessage("success: Successfully updated user.");
        return setTimeout(() => document.location.reload(), 1500);
      }
      setMessage("error: Error updating user.");
    } finally {
      setIsSubmitting(null);
    }
  };

  const init = async () => {
    if (!session) {
      return;
    }
    let u = session;
    let userId = RequestUtils.getURLId("users");
    if (userId && u.id !== userId) {
      if (userId === "add") {
        u = {};
      } else {
        u = await UserModel.getUser(userId, true);
      }
    }

    let _roles = {};
    (u?.roles || []).forEach((r) => (_roles[r] = true));
    setValue("_roles", _roles);
    setValue("city", u.city);
    setValue("user_name", u.user_name);
    // u.primary_gym && setValue("primary_gym", {display: u.getPrimaryGym().name, value: u.primary_gym});

    u.crc = Utils.getCRC({...u, ...getValues()}, CRC_Fields);
    console.log('crc: ', u.crc);
    setData(u);
  };

  useEffect(() => {
    // console.log(getValues('primary_gym'));
  }, [getValues('primary_gym')]);

  useEffect(() => {
    if (session || _data) {
      init();
    }
  }, [_data && _data.id, session]);

  useIonViewWillEnter(() => {
    init();
  }, [session]);

  const handleCheckUserName = async () => {
    setIsUsernameAvail(false);
    setMessage("");
    const { user_name } = getValues();
    if (!user_name || user_name === data.user_name) {
      return;
    }
    let result = await UserModel.checkUserName(user_name);
    if (result.error) {
      return setError("user_name", { message: "username is not available" });
    } else {
      setIsUsernameAvail(true);
      clearErrors(["user_name"]);
      setTimeout(() => setIsUsernameAvail(false), 5000);
    }
    setMessage(
      <Alert severity="info">
        Username <b style={{ textDecoration: "underline" }}>{user_name}</b> is
        available.
      </Alert>
    );
  };

  const doDelete = async () => {
    setMessage("");
    setIsSubmitting("delete");
    let result = await UserModel.deleteMembership(data.id);
    if (result && result.id) {
      setMessage("success: Successfully deleted user.");
      return setTimeout(() => {
        document.location.href = "/users";
      }, 1000);
    }
    setMessage("error: Error deleting user.");
  };

  const MergeUser = () => {
    const [mergeUser, setMergeUser] = useState();
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isMerging, setIsMerging] = useState(false);

    const validateUser = async () => {
      try {
        let r = await UserModel.validateUser(mergeUser.id, password);
        return r && !r.error;
      }catch(e) {
        setMessage('error: Failed to validate authentication');
      }
    }

    const merge = async () => {
      setMessage('');
      try{
        if (!data.id || !mergeUser) {
          return setMessage('error: Missing merging information');;
        }
        let isValid = await validateUser();
        if (isValid){
          let r = await GymModel.mergeMemberships(data.id, mergeUser.id);
          if (!r || r.error){
            setIsMerging(false);
            return setMessage('error: Error merge user');
          }
          setMessage('success: Successfully merge user');
          return setTimeout(() => document.location.reload(), 2000);
        }
        setMessage('error: Failed to validate user');
      }finally {
        setIsMerging(false);
      }
    }

    return <div className="MergeUser">
      <h3>Merge User</h3>
      <span className="note">
        If you find other memberhips that belong to you, 
        you can merge those memberships to this membership.
      </span>
      <AlertPane message={message} />
      <MultiSelectFormInput
        multiple={false}
        value={mergeUser}
        name="merge"
        label=""
        optionLabel="display"
        optionValue="id"
        fetchOptions={searchVal => UserModel.searchUser(searchVal)
            .then(users => {
              return users.filter(u => u.id !== data.id);
            })
        }
        onChange={m => {
          setMergeUser(m);
        }}
      />
      <div className="merge">
        <TextFormInput
          name="pwd"
          label="Enter User Password"
          type="password"
          value={password}
          onChange={pwd => {
            setPassword(pwd);
          }}
        />
        <button className="button"
          onClick={() => {
            setIsMerging(true);
            merge();
          }}>
          Merge {isMerging && <IonSpinner name="circles" className="spinner" />}
        </button>
      </div>
    </div>;
  }

  const renderForm = () => {
    const getPrimaryGym = () => {
      if (dirtyFields.primary_gym || getValues().primary_gym !== undefined) {
        return getValues().primary_gym;
      }
      let pt = data.getPrimaryGym();
      if (pt) {
        return {value: pt.id, display: pt.name};
      }else {
        return {value: '', display: ''};
      }
      
    };
    const updatRoles = (v) => {
      data.roles = data.roles || [];
      let field = v.field.split(".")[1];
      if (v.isChecked && !data.roles?.includes(field)) {
        data.roles?.push(field);
      } else {
        data.roles.splice(data.roles.indexOf(field), 1);
      }
      setValue(
        "_roles",
        { ...getValues("_roles"), [field]: v.isChecked },
        { shouldDirty: true }
      );
      setData({ ...data });
      checkCRC();
    };

    // console.log('errors: ', errors, data.primary_gym, getValues().primary_gym);
    const lastUpdate = data.last_update || data.created_on;
    return (
      <IonPage>
        <IonContent>
        <ThemeProvider key={1} theme={Theme}>
          <div className={classNames('Profile', PageStyles.page)}>
            <h2 className="title">
              {!data.id && "New User"}
              {data.id === session.id
                ? "Your Profile"
                : getMembershipName(data)}
            </h2>
            {data && data.id &&
              <div className="user-public-link">
                <Link href={`/users/${data.id}`}>
                  {UserModel.getMembershipName(data)}
                </Link>
              </div>}
            <div className="form-wrapper">
              <form
                onKeyDown={stopFormSubmitByEnter}
                className={FormStyles.form}
                onSubmit={handleSubmit(onSubmit)}
              >
                {session &&
                  session.is_super && [
                    <div key={0} className={FormStyles.fieldDescription}>
                      <h3>GIH Status</h3>
                    </div>,
                    <div key={1} className={FormStyles.fields}>
                      <SelectFormInput
                        value={getValues('status') || data.status || ""}
                        name={`status`}
                        label="Status"
                        setValue={setValue}
                        placeholder="Set Status"
                        options={[{ value: "" }, ...STATUS_OPTIONS]}
                        onChange={checkCRC}
                      />
                    </div>,
                  ]}
                <div className={FormStyles.fieldDescription}>
                  <h3>Image</h3>
                  <p>best at 80x80px</p>
                </div>
                <div className={FormStyles.fields}>
                  <ImageFormInput
                    name="image"
                    images={ImageModel.setImageFormInput(
                      IMAGE_TYPE.profile,
                      getValues,
                      getFieldState,
                      data,
                      "image"
                    )}
                    size={600}
                    onImage={d => {
                      setValue("image", d, { shouldDirty: true });
                      setValue('image_fileName', d.fileName);
                      checkCRC();
                    }}
                  />
                </div>
                <RenderRoles
                  data={data}
                  register={register}
                  control={control}
                  updatRoles={updatRoles}
                />
                <div className={FormStyles.fieldDescription}>
                  <h3>Privacy</h3>
                </div>
                <div className={FormStyles.fields}>
                  <Checkbox 
                    defaultChecked={data.is_private} 
                    onChange={e => {
                      setValue('is_private', e.target.checked);
                      checkCRC();
                    }}/>
                  Set Private
                </div>
                <div className={FormStyles.fieldDescription}>
                  <h3>Update Password</h3>
                </div>
                <div className={FormStyles.fields}>
                  <TextFormInput
                    name="new_password"
                    label="New Password"
                    type="password"
                    autoComplete="new-password"
                    value={""}
                    register={register} setValue={setValue}
                    isRequired={!data.id} validateWithTrim
                    errorText={errors && errors?.new_password?.message}
                    onChange={checkCRC}
                  />
                </div>
                <div className={FormStyles.fieldDescription}>
                  <h3>Primary Gym</h3>
                </div>
                <div className={`primary-gym-wrapper ${FormStyles.fields}`}>
                  <MultiSelectFormInput
                    freeSolo={true}
                    control={control}
                    value={getPrimaryGym()}
                    multiple={false}
                    name="primary_gym"
                    label="Primary Gym"
                    fetchOptions={(searchVal) =>
                      GymModel.searchGym(searchVal)
                    }
                    setValue={setValue}
                    onChange={(v) =>{
                      if (v && v.id === -1 && v.display){
                        setValue('primary_gym', v);
                        console.log('onChange: ', isOpen.current)
                        setAddGym(!isOpen.current);
                      }else {
                        setAddGym(false);
                      }
                      checkCRC();
                    }}
                    onKeyDown={e => {
                      if (isKeyEnter(e)) {
                        entityGym.current && entityGym.current.addressFormRef.countryRef.selectRef.click();
                      }
                    }}
                    onOpen={o => {
                      isOpen.current = o? 'gym':'';
                      let gym = getValues('primary_gym');
                      setAddGym(!o && gym && gym.id === -1);
                    }}
                    onBlur={() => {
                      isOpen.current = '';
                      let gym = getPrimaryGym();
                      setAddGym(gym && gym.id===-1);
                    }}
                  />
                  {addGym && 
                    <AddEntity ref={entityGym}
                      key="gym" 
                      name="gym" 
                      getValues={getValues}
                      register={register}
                      setValue={setValue}
                    />}
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>User Name</h3>
                </div>
                <div className={FormStyles.fields}>
                  <div className="username">
                    <UserName
                      errors={errors}
                      isUsernameAvail={isUsernameAvail}
                      user_name={data.user_name || getValues().user_name}
                      register={register}
                      isSubmitting={isSubmitting}
                      handleCheckUserName={handleCheckUserName}
                      onChange={checkCRC}
                      setValue={setValue}
                      canCheckUsername
                    />
                  </div>
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>Name</h3>
                </div>
                <div className={FormStyles.fields}>
                  <div className="name">
                    <div style={{ minWidth: 375 }}>
                      <TextFormInput
                        value={data?.first_name}
                        name="first_name"
                        label="First Name"
                        register={register} setValue={setValue}
                        isRequired validateWithTrim
                        onChange={checkCRC}
                      />
                    </div>
                    <div>
                      <TextFormInput
                        value={data?.last_name}
                        name="last_name"
                        label="Last Name"
                        register={register} setValue={setValue}
                        isRequired validateWithTrim
                        onChange={checkCRC}
                      />
                    </div>
                    <div>
                      <TextFormInput
                        value={data?.middle_name}
                        name="middle_name"
                        label="Middle Name"
                        register={register} setValue={setValue}
                        onChange={checkCRC}
                      />
                    </div>
                  </div>
                </div>
                <div className={FormStyles.fieldDescription}>
                  <h3>Contact</h3>
                </div>
                <div className={FormStyles.fields}>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ minWidth: 200 }}>
                      <TextFormInput
                        value={data?.phone}
                        name="phone"
                        label="Phone"
                        register={register} setValue={setValue}
                        onChange={checkCRC}
                      />
                    </div>
                    <div style={{ minWidth: 300 }}>
                      <TextFormInput
                        type="email"
                        value={data?.email}
                        name="email"
                        label="Email"
                        register={register} setValue={setValue}
                        isRequired validateWithTrim
                        onChange={checkCRC}
                      />
                    </div>
                  </div>
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>Division</h3>
                </div>
                <div className={FormStyles.fields}>
                  <div className="division-wrapper">
                    <div className="profile">
                      <SelectFormInput
                        value={getValues('gi_level') || data?.gi_level || ""}
                        name={`gi_level`}
                        label="BJJ Gi Belt"
                        setValue={setValue}
                        placeholder="Select BJJ Gi Belt"
                        options={includesBlankOption(GI_LEVEL_OPTIONS)}
                        onChange={checkCRC} 
                      />
                      <SelectFormInput
                        name={`gender`}
                        label="Gender"
                        value={getValues('gender') || data?.gender || ""}
                        setValue={setValue}
                        placeholder="Select gender"
                        options={includesBlankOption(GENDER_TYPES)}
                        onChange={checkCRC}
                      />
                      <div className="weight-wrapper">
                        <TextFormInput
                          value={data?.weight}
                          name="weight"
                          label="Weight"
                          type="float"
                          inputProps={{ min: 1 }}
                          register={register} setValue={setValue}
                          isRequired
                          onChange={checkCRC}
                        />
                      </div>
                      
                      <SelectFormInput
                        value={getValues('weight_unit') || data?.weight_unit || ""}
                        name={`weight_unit`}
                        label="Unit"
                        setValue={setValue}
                        placeholder="Select Weight Unit"
                        options={includesBlankOption(WEIGHT_UNIT)}
                        onChange={checkCRC}
                      />
                    </div>
                    <div className="age">
                      <TextFormInput
                        value={data?.age}
                        name="age"
                        label="Age"
                        register={register} setValue={setValue}
                        isRequired
                        onChange={checkCRC}
                      />
                    </div>
                    <div className="next-age-update">
                      <div className="label">Date of next age update</div>
                      <SelectFormInput
                        value={data?.next_age_update_date?.month || 1}
                        name={`next_age_update_date.month`}
                        label="Month"
                        setValue={setValue}
                        placeholder="Select a month"
                        options={MONTH_OPTIONS}
                        onChange={checkCRC}
                      />
                      <TextFormInput
                        value={data?.next_age_update_date?.day || 1}
                        inputProps={{ min: 1, max: 31 }}
                        type="number"
                        name={`next_age_update_date.day`}
                        label="Day"
                        register={register} setValue={setValue}
                        placeholder="Enter Day of Month"
                        onChange={checkCRC}
                      />
                    </div>
                  </div>
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>Experience</h3>
                  {lastUpdate && <span className="note">As of {Utils.formatDate(lastUpdate)}</span>}
                </div>
                <div className={FormStyles.fields}>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ minWidth: 200 }}>
                      <TextFormInput
                        value={data?.years_of_wrestling}
                        name="years_of_wrestling"
                        label="Years of Wrestling"
                        type="float"
                        inputProps={{ min: 0 }}
                        register={register} setValue={setValue}
                        onChange={checkCRC}
                      />
                    </div>
                    <div style={{ minWidth: 200 }}>
                      <TextFormInput
                        value={data?.years_of_judo}
                        name="years_of_judo"
                        label="Years of Judo"
                        type="float"
                        inputProps={{ min: 0 }}
                        register={register} setValue={setValue}
                        onChange={checkCRC}
                      />
                    </div>
                  </div>
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>Location</h3>
                </div>
                <div className={FormStyles.fields}>
                  <AddressForm isRequired={['country']}
                    address={{
                      country: getFieldValue('country', data, getValues),
                      state: getFieldValue('state', data, getValues),
                      city: getFieldValue('city', data, getValues),
                      region: getFieldValue('region', data, getValues),
                      suburb: getFieldValue('suburb', data, getValues),
                    }}
                    register={register} setValue={setValue} 
                    onChange={checkCRC}
                  />
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>
                    Emergency Contacts
                    <AddIcon
                      className={FormStyles.add_icon}
                      onClick={() => {
                        handleSubListAdd(setData, data, "contact_infos");
                        checkCRC();
                      }}
                    />
                  </h3>
                </div>
                <div className={FormStyles.fields}>
                  <ContactInfos
                    omitEmail
                    setFormData={setData}
                    data={data.contact_infos}
                    register={register} setValue={setValue}
                    errors={errors} setError={setError}
                    refresh={() => setRefresh(!refresh)}
                    onChange={checkCRC}
                  />
                </div>

                <div className={FormStyles.fieldDescription}>
                  <h3>
                    Family
                    <AddIcon
                      className={FormStyles.add_icon}
                      onClick={() => {
                        handleSubListAdd(setData, data, "family_permissions");
                        checkCRC();
                      }}
                    />
                  </h3>
                </div>
                <Families
                  data={data.family_permissions}
                  control={control}
                  register={register} setValue={setValue}
                  errors={errors} setError={setError}
                  onChange={checkCRC}
                />

                <div className={FormStyles.fieldDescription}>
                  <h3>
                    Coaches
                    <AddIcon
                      className={FormStyles.add_icon}
                      onClick={() => {
                        handleSubListAdd(setData, data, "coaches");
                        checkCRC();
                      }}
                    />
                  </h3>
                </div>
                <Coaches
                  data={data.coaches}
                  control={control}
                  register={register}
                  setValue={setValue}
                  onChange={checkCRC}
                />

                <div className={FormStyles.fieldDescription} />
                <FormActionButtons hasCRCChange={hasChange}
                  doDelete={session.is_super && doDelete}
                  message={message}
                  setMessage={setMessage}
                  id={data.id}
                  isSubmitting={isSubmitting}
                />
              </form>
            </div>
            {hasChange && <AlertPane isFloat message={DATA_HAS_CHANGED_MESSAGE_WARNING}/>}
            {session && <MergeUser />}
          </div>
        </ThemeProvider>
        </IonContent>
      </IonPage>
    );
  };
  return !data || !session ? (
    <LoadingScreen />
  ) : !isProfilePath ? (
    renderForm()
  ) : (
    <IonPage>
      <IonContent>{renderForm()}</IonContent>
    </IonPage>
  );
}

function Coaches({ data, control, register, setValue, onChange }) {
  const NAME = "coaches";
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    setValue(NAME, data);
  }, []);
  return <div className={classNames('Coaches', FormStyles.fields)} >
      {(data || []).map((c, i) => {
        let name = `${NAME}[${i}]`;
        return <div className="coach-entry" key={i} >
          <div className={"coach-wrapper"} >
            <div className="coach" >
              <div className="coach-member">
                <MultiSelectFormInput
                  control={control}
                  value={c}
                  multiple={false}
                  name={name}
                  label="Coach"
                  register={register}
                  fetchOptions={(searchVal) => {
                    return UserModel.searchUser(searchVal);
                  }}
                  setValue={setValue}
                  onChange={(v) => {
                    data[i] = {
                      ...v,
                      title: c?.title || COACH_TITLES[0].value,
                    };
                    onChange();
                  }} />
                </div>
            </div>
          </div>
          <RemoveSubFormIcon
            label="Remove"
            onClick={() => {
              data.splice(i, 1);
              setValue(NAME, data);
              setRefresh(!refresh);
              onChange();
            }}
          />
        </div>
      })}
    </div>
    ;
}

function Families({ data, setValue, control, register, onChange }) {
  const [refresh, setRefresh] = useState(false);
  const NAME = "family_permissions";
  useEffect(() => {
    setValue(NAME, data);
  }, []);
  return <div className={classNames('Families', FormStyles.fields)}>
      {(data || []).map((fp, i) => {
        let name = `${NAME}[${i}]`;
        return <div
          className={NAME}
          key={i}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="family-member-wrapper">
            <div className="name-label">Family {i + 1}</div>
            <div className="entry">
              <div>
                <SelectFormInput
                  onChange={(v) => {
                    if (!data[i]) {
                      data[i] = {};
                    }
                    data[i].relation = v;
                    setValue(name, data[i], {
                      shouldDirty: true,
                    });
                    onChange();
                  }}
                  value={fp?.relation}
                  setValue={setValue}
                  name={`relation`}
                  label="Relation"
                  placeholder="Relation"
                  options={includesBlankOption(FAMILY_RELATION)}
                />
              </div>
              <div className="family-member">
                <MultiSelectFormInput
                  control={control}
                  value={fp}
                  multiple={false}
                  label="Family Member"
                  name={name}
                  optionLabel="display"
                  optionValue="id"
                  register={register}
                  fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
                  setValue={setValue}
                  onChange={(v) => {
                    data[i] = {
                      ...v,
                      relation: fp?.relation || FAMILY_RELATION[0].value,
                    };
                    onChange();
                  }}
                />
              </div>
            </div>
          </div>
          <RemoveSubFormIcon
            label="Remove"
            onClick={() => {
              data.splice(i, 1);
              setValue(NAME, data);
              setRefresh(!refresh);
              onChange();
            }}
          />
        </div>
        ;
      })}
    </div>
    ;
}

const RenderRoles = ({ data, register, control, updatRoles }) => {
  const session = useStore((state) => state.session);
  if (
    document.location.pathname === "/profile" ||
    !UserModel.isAdmin(session, null, data) ||
    data.is_super
  ) {
    return "";
  }
  return [
    <div key={0} className={FormStyles.fieldDescription}>
      <h3>Roles</h3>
    </div>,
    <ThemeProvider key={1} theme={Theme}>
      <div className={FormStyles.fields}>
        <FormGroup>
          <FormCheckBox
            name="_roles.user"
            label="User"
            control={control}
            register={register}
            value={data?.roles?.includes("user")}
            onChange={updatRoles}
          />
          <FormCheckBox
            name="_roles.league"
            label="League"
            control={control}
            register={register}
            value={data?.roles?.includes("league")}
            onChange={updatRoles}
          />
          <FormCheckBox
            name="_roles.team"
            label="Team"
            control={control}
            register={register}
            value={data?.roles?.includes("team")}
            onChange={updatRoles}
          />
          <FormCheckBox
            name="_roles.gym"
            label="Gym"
            control={control}
            register={register}
            value={data?.roles?.includes("gym")}
            onChange={updatRoles}
          />
          <FormCheckBox
            name="_roles.tournament"
            label="Tournament"
            control={control}
            register={register}
            value={data?.roles?.includes("tournament")}
            onChange={updatRoles}
          />
        </FormGroup>
      </div>
    </ThemeProvider>,
  ];
};

function GIHSubscription({ membership, register, control, reload }) {
  const deleteEntry = (i) => {
    membership.gih.splice(i, 1);
    reload();
  };

  const addEntry = () => {
    membership.gih.push({});
    reload();
  };

  return (
    <div className={FormStyles.GIHSubscription}>
      <div key={-1} className={FormStyles.fieldDescription}>
        <h3>GIH Status</h3>
      </div>
      <div className={FormStyles.fields}>
        <SelectFormInput
          value={membership.status || ""}
          name={`status`}
          label="Status"
          placeholder="Set Status"
          options={[{ value: "" }, ...STATUS_OPTIONS]}
        />
      </div>

      <div key={-2} className={FormStyles.fieldDescription}>
        <h6>
          History <AddIcon className={FormStyles.add_icon} onClick={addEntry} />
        </h6>
      </div>
      <div className={FormStyles.fields}>
        {membership.gih?.map((s, i) => {
          return (
            <div key={i}>
              <div className={FormStyles.GIHSubscription_index_field}>
                <DateFormInput1
                  value={s.start_date}
                  label="Start Date"
                  name={`gih[${i}].start_date`}
                  control={control}
                />
              </div>
              <div className={FormStyles.GIHSubscription_index_field}>
                <DateFormInput1
                  value={s.end_date}
                  label="End Date"
                  name={`gih[${i}].end_date`}
                  control={control}
                />
              </div>
              <div className={FormStyles.GIHSubscription_index_field}>
                <DateFormInput1
                  value={s.cancel_date}
                  label="Cancel Date"
                  name={`gih[${i}].cancel_date`}
                  control={control}
                />
              </div>
              <div className={FormStyles.GIHSubscription_index_field}>
                <DateFormInput1
                  value={s.paid_date}
                  label="Paid Date"
                  name={`gih[${i}].paid_date`}
                  control={control}
                />
              </div>
              <div className={FormStyles.GIHSubscription_index_field}>
                <TextFormInput
                  value={s.amount}
                  name={`gih[${i}].amount`}
                  label="Amount"
                  register={register}
                />
              </div>
              <RemoveSubFormIcon
                onClick={() => deleteEntry(i)}
                label={`Payment option ${i + 1}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export const AddEntity = forwardRef(({name, getValues, register, setValue}, ref) => {
  const [entityData, setEnitityData] = useState({});
  const [isRequired, setIsRequired] = useState();
  const addressFormRef = useRef();
  useImperativeHandle(ref, () => ({
    addressFormRef: addressFormRef.current,
  }));
  useEffect(() => {
    if (name === 'gym') {
      setIsRequired(['city']);
      Object.assign(addGymData, {...getValues().primary_gym});
      setEnitityData(addGymData);
    }else {
      Object.assign(addTeamData, {...getValues().primary_team});
      setEnitityData(addTeamData);
    }
    
  }, []);

  const addGymData = {country: getValues().country,
    state: '',
    city: '',
    region: ''
  };
  const addTeamData = {country: getValues().country};

  return <div className={`AddEntity ${name}`}>
      <div className="title">The {name} you have entered is not 
        currently in our system. If you want it recorded, please 
        fill out the required fields of your {name} below.
      </div>
      <AddressForm isRequired={isRequired} ref={addressFormRef}
        name={`primary_${name}.`}
        address={entityData}
        register={register} setValue={setValue}
      />
    </div>;
});
