import { IMAGE_TYPE } from "../../components/Form/Form";
import { RequestImage, RequestUtils } from "../requests";
import Utils from "../Utils";

export default class ImageModel {
    static setImageFormInput(image_type, getValues, getFieldState, formData, field) {
        let image = getValues()[field];
        if (image) {
            image = [{
                data: image.data,
                ref: formData.id,
                image_type
            }]
        }
        let fromForm = (!getFieldState(field).isDirty &&
            [formData && formData.getImage && formData.getImage(image_type)])
        return image || [...(fromForm || [])];
    }

    static async addEntityImage(imageObj, formData, image_type) {
        if (imageObj){
            let image = {
                data: imageObj.data,
                ref: formData.id,
                image_type,
            }
            return await ImageModel.addImage(image, (p) => console.log(`${p}%`));
        }
    }

    static async photosUpload(newImages, ref) {
        if (!newImages) {
            return;
        }
        let errors = [];
        let uploads = []
        for (let i = 0; i < newImages.length; i++) {
            let { fileName, data } = newImages[i];
            let image = {
                data,
                ref,
                image_type: IMAGE_TYPE.photo,
            }
            let result = await ImageModel.addImage(image, (p) => console.log(`${p}%`));
            if (!result || !result.id) {
                errors.push(fileName);
            }else {
                image.id = result.id;
                uploads.push(image);
            }
        }
        return { uploads, errors };
    }

    static async getImageByRefs({ids, image_type, image_size, page, page_size}) {
        let response = await RequestImage.getImagesByRefRequest({ids, image_type, image_size, page, page_size});
        return RequestUtils.getResponseData(response);
    }

    static async getImageById(id, size=200) {
        let images = await ImageModel.getImagesByIds([id], size);
        if (images && images.length > 0){
            return images[0];
        }
    }

    static async getImagesByIds(ids, size) {
        let response = await RequestImage.getImagesRequest(ids, size);
        return RequestUtils.getResponseData(response);
    }

    static async resizeImage(data, size, el, imageType) {
        return Utils.resizeImageData(data, size, el, imageType);
    }

    static async updateImage(params) {
        let response = await RequestImage.updateImage(params);
        return RequestUtils.getResponseData(response);
    }
    
    static async deleteImage(id) {
        let response = await RequestImage.deleteImage(id);
        return RequestUtils.getResponseData(response);
    }
    
    static async addImage(params, updateProgress) {
        if (!params.data){
            return {error: 'Empty image'};
        }
        let chunkSize = 10*1000;
        delete params.id;
        let data = Array.isArray(params.data)? params.data.pop():params.data;
        try {
            let index = 0;
            let size = 0;
            let progress = 0;
            const getChunk = () => {
                size = data.length > chunkSize ? chunkSize : data.length;
                let ck = data.substr(index, size);
                index += size;
                return ck;
            }
            params.length = params.data.length;
            params.data = getChunk();
            let response = await RequestImage.addImage(params);
            let result = RequestUtils.getResponseData(response);
            let isAppend = index < data.length;
            if (!result.error && isAppend) {
                params.id = result.id;
                const append = async () => {
                    updateProgress && updateProgress((progress / data.length) * 100);
                    params.data = getChunk();
                    progress += params.data.length;
                    result = await ImageModel.updateImage(params);
                    if (result.error) {
                        index -= size;
                        await append();
                    } else if (!result.error && index < data.length) {
                        await append();
                    } else {
                        updateProgress && updateProgress(100, result);
                        return result;
                    }
                }
                await append();
                updateProgress && updateProgress(!isAppend ? 100 : 0, result);
                return result;
            }
            return result;
        } finally {
            params.data = [data];
        }
    }
}