import styles from "./FormInput.module.scss";
import { FormControl, TextField, ThemeProvider, Tooltip } from "@mui/material";
import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Theme from "./Theme";
import { isEmailFormatValid } from "../Form/Form";
import {default as ClearIcon} from "@mui/icons-material/Clear";
import "./TextFormInput.scss";

const TextFormInput = forwardRef(({ 
    style = {},
    type,
    value,
    autoComplete,
    name,
    label,
    disabled,
    isRequired = false,
    register, setValue,
    helperText, errorText,
    hidden = false,
    onChange,
    onKeyUp,
    onBlur,
    onMouseLeave,
    validateWithTrim,
    InputProps, inputProps,
    isLabelShrunk,
    isCapitalized,
    isFocus
}, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [readOnly, setReadOnly] = useState(disabled);
    const textFieldRef = useRef();
    useImperativeHandle(ref, () => ({
        textFieldRef,
        setReadOnly,
        setInputValue,
        inputValue,
    }));
    
    useEffect(() => {
        let v = '';
        if (type === 'number') {
            v = value;
        }else {
            try{
                v = [undefined, null].includes(value)? '' : value;
                v = !isNaN(parseInt(v))? value.toString() : v;
                v = isCapitalized && v? v.toUpperCase() : v;
            }catch(e) {
                console.log(e);
            }
        }
        let isTrim = ['email', 'password'].includes(type);
        if (isTrim) {
            v = v.trim();
        }
        setInputValue(v);
        setValue && setValue(name, v);
        if (isFocus) {
            textFieldRef.current.focus();
            textFieldRef.current.click();
            setTimeout(() => {
                textFieldRef.current && textFieldRef.current.scrollIntoView({
                    behavior: 'smooth', 
                    block: 'center',     
                });
            }, 1000);
        } 
    }, [value]);

    const handleOnChange = e => {
        let v = e.target.value;
        if (validateWithTrim && !v.trim()) {
            v = null;
        }
        v = isCapitalized? v.toUpperCase():(v||'');
        setInputValue(v);
        setValue && setValue(name, v);
        onChange && onChange(v);
    }
    const customValidate = () => {
        if (type==='email' && !isEmailFormatValid(inputValue)) {
            return 'Email format is invalid';
        }
    }
    const reactHookProps = !register ? 
        {} :
        register(name, 
            {
                required: isRequired && !inputValue? 'This field is required':false,
                validate: customValidate
            }
        );

    const isError = () => {
        if (errorText) {
            return true;
        }
        return isRequired && (!inputValue || customValidate());
    }
    const getClassName = () => (name||'').split('.').pop();
    const getLabel = () => {
        if (typeof label === 'object') {
            return label;
        } 
        return !label? '':`${label} ${isRequired ? '*' : ''}`;
    }
    return <FormControl id={name} 
        className={classNames(hidden ? styles.hiddenInput : '', `TextFormInput-wrapper ${getClassName()}`)}>
        <ThemeProvider theme={Theme}>
            <Tooltip title={name==='password'? '':inputValue || ''}>
                <div className={`TextFormInput ${getClassName()}`}>
                    <TextField className="TextField" inputRef={textFieldRef}
                        InputLabelProps={{
                            shrink: isLabelShrunk,
                        }}
                        style={style}
                        autoComplete={autoComplete}
                        hidden={hidden}
                        type={type}
                        disabled={readOnly}
                        label={getLabel()}
                        value={inputValue || ''}
                        margin="normal"
                        error={isError()}
                        helperText={customValidate() || helperText || errorText}
                        FormHelperTextProps={{
                            style: { color: helperText && 'green' || errorText && 'red'},
                        }}
                        inputProps={inputProps}
                        InputProps={InputProps}
                        {...reactHookProps}
                        onClick={e => e.stopPropagation()}
                        onBlur={e => {
                            onBlur && onBlur(e);
                        }}
                        onKeyUp={e => {
                            onKeyUp && onKeyUp(e);
                        }}
                        onMouseLeave={e => {
                            onMouseLeave && onMouseLeave(e);
                        }}
                        onChange={handleOnChange}
                    />
                    <ClearIcon className="ClearIcon" 
                        onClick={e => {
                            e.stopPropagation();
                            setInputValue('');
                            setValue && setValue(name, '');
                            onChange && onChange(!inputValue? undefined : '');
                        }}/>
                </div>
            </Tooltip>
        </ThemeProvider>
    </FormControl>;
});

export const isError = (errors, name) => {
    if (!errors) {
        return '';
    }
    let fields = name.split('.').map(f => {
        if (isNaN(f)) {
            return f;
        }
        return `[${f}]`;
    });
    try{
        let e = eval(`errors?.${fields.join('?.')}`);
        return e && e.message;
    }catch(e) {
        console.log('errors eval:', e);
    }
}

export default TextFormInput;