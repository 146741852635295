import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Grid from '@mui/material/Grid';
import { FixedSizeList as List } from 'react-window';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { IonIcon } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import './TransferList.scss';
import Utils from "../../serverUtils/Utils";
import { TextField, Tooltip } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Theme from "./Theme";
import { default as CloseIcon} from '@mui/icons-material/CloseOutlined';

function not(a, b) {
  return a.filter((av) => {
    return !b.find(bv => bv.value === av.value);
  }).sort((a, b) => Utils.sorter(a, b, 'label'));
}

const TransferList = React.forwardRef(({ 
    availables, 
    selecteds, 
    onDone, 
    buttons, 
    onSelect, 
    onFilterReset,
    availFilter='', 
    selectFilter='', 
    filterLabel,
    selectButtonTitle, unSelectButtonTitle, selectTitle, unSelectTitle }, ref) => {
  const [availableList, setAvailableList] = React.useState([]);
  const [selectList, setSelectList] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const availableListRef = React.useRef();
  const selectListRef = React.useRef();
  const availableListFilterTextRef = React.useRef();
  const selectListFilterTextRef = React.useRef();
  const selectButtonRef = React.useRef();
  const unSelectButtonRef = React.useRef();
  React.useEffect(() => {
    unSelectButtonRef.current.disabled = true;
    selectButtonRef.current.disabled = true;
  }, []);
  React.useEffect(() => {
    setAvailableList(availables);
    setSelectList(selecteds);
  }, [availables, selecteds]);
  React.useImperativeHandle(ref, () => ({
    getSelected: () => selectList.map(v => v.value),
  }));


  const handleMoveSelect = (e) => {
    e.stopPropagation();
    let availableChecks = availableListRef.current.checkRef;
    let newSelects = selectList.concat(availableChecks);
    setSelectList(newSelects);
    let newAvailables = not(availableList, availableChecks);
    setAvailableList(newAvailables);
    onSelect && onSelect(null, availableChecks.map(i => i.value));
    Utils.resetArray(availableListRef.current.checkRef);
    selectListRef.current.toggleButton();
  };

  const handleMoveUnSelect = (e) => {
    e.stopPropagation();
    let selectChecks = selectListRef.current.checkRef;
    let newSelects = not(selectList, selectChecks);
    setSelectList(newSelects);
    let newAvailables = availableList.concat(selectChecks);
    setAvailableList(newAvailables);
    onSelect && onSelect(selectChecks.map(i => i.value)); 
    Utils.resetArray(selectListRef.current.checkRef);
    selectListRef.current.toggleButton();
  };

  const CustomList = forwardRef(({ title, items = [], extFilter, buttonRef, filterTextRef, isSelect}, ref) => {
    const checkListRef = useRef([]);
    const checkMessageRef = useRef();
    const listRef = useRef();
    const [list, setList] = useState(items);
  
    useEffect(() => {
      items.sort((a, b) => Utils.sort((a, b) => Utils.sort(a.label, b.label)));
      setList(items);
      filterTextRef.current && 
        setList(multiWordFilter(filterTextRef.current.toLowerCase(), items, 'label'));
      // buttons && (checkRef.current = []); //if button then this usually means there external filter
      toggleButton();
    }, [items]);
  
    const toggleButton = () => {
      buttonRef.current.disabled = checkListRef.current.length === 0;
    }

    const handleToggleAll = () => {
      if (checkListRef.current.length > 0) {
        checkListRef.current = [];
      } else {
        checkListRef.current = list;
      }
      setList([...list]);
      toggleButton();
    };
  
    useImperativeHandle(ref, () => ({
      checkRef: checkListRef.current,
      listRef: listRef.current,
      toggleButton
    }));
  
    const SelectMessage = React.forwardRef(({ length }, ref) => {
      const [m, setM] = useState(length);
      useEffect(() => setM(length), [length]);
      useImperativeHandle(ref, () => ({
        setM,
      }));
  
      return <span className="SelectMessage">{list.length > 0 && `${m || 0}/${list.length}`} Select</span>;
    });
  
    const renderRow = ({ index, style }) => {
      const value = list[index];
      const labelId = `transfer-list-all-item-${value.value}-label`;
  
      return (
        <ListItem className="ListItem"
          key={`${value.value}-${index}`} // Ensure unique keys
          style={style}
          role="listitem"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <Checkbox
              defaultChecked={!!checkListRef.current.find(c => c.value === value.value)}
              tabIndex={-1}
              disableRipple
              inputProps={{
                'aria-labelledby': labelId,
              }}
              onChange={e => {
                const isChecked = e.target.checked;
                const currentIndex = checkListRef.current.indexOf(value);
                if (isChecked) {
                  checkListRef.current.push(value);
                } else {
                  checkListRef.current.splice(currentIndex, 1);
                }
                checkMessageRef.current.setM(checkListRef.current.length);
                toggleButton();
              }}
            />
          </ListItemIcon>
          <Tooltip title={value.label}>
            <ListItemText className="ListItemText" id={labelId} primary={value.label} />
          </Tooltip>
        </ListItem>
      );
    };
  
    const reset = () => {
      filterTextRef.current = '';
      setRefresh(!refresh);
      onFilterReset && onFilterReset(isSelect);
    }

    return <ThemeProvider theme={Theme}>
      <Card className="CustomList">
        <h5 className="title">{title}</h5>
        <div className="header-filter">
          <div className="title-wrapper">
            <div className="extFilter">
              {extFilter}
            </div>
            <div className="inFilter">
              <TextField className="TextField"
                value={filterTextRef.current}
                label={filterLabel || "Filter"} variant="outlined"
                onClick={e => e.stopPropagation()}
                onChange={e => {
                  let f = e.target.value;
                  setList(multiWordFilter(f.toLowerCase(), items, 'label'));
                  filterTextRef.current = f;
                  checkListRef.current = [];
                }} 
              />
              <button className="button" onClick={reset}>Reset</button>
            </div>
          </div>
        </div>
        <Divider />
        <div className="select-all">
          <Checkbox
            onClick={e => e.stopPropagation()}
            onChange={handleToggleAll}
            inputProps={{
              'aria-labelledby': 'all items selected',
            }}
          />
          <div className="count">
            <SelectMessage length={checkListRef.current.length} ref={checkMessageRef} />
          </div>
        </div>
        <List className="List"
          height={230}
          itemCount={list.length}
          itemSize={24}
          width="100%"
          ref={listRef}
        >
          {renderRow}
        </List>
      </Card>
    </ThemeProvider>;
  });

  return <ThemeProvider theme={Theme}>
    <div className="TransferList">
      <Tooltip title="Disgard change and close">
        <CloseIcon className="CloseIcon" onClick={e => onDone(selectList.map(v => v.value))}/>Cancel
      </Tooltip>
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="row" columns={{ xs: 12 }}>
        <Grid item>
          <Grid item xs={12} >
            <CustomList title={selectTitle || 'Selected Items'} isSelect
              buttonRef={unSelectButtonRef} filterTextRef={selectListFilterTextRef}
              items={selectList} extFilter={selectFilter} ref={selectListRef}/>
          </Grid>
          <Grid className="buttons" container direction="row" alignItems="center" spacing={2}>
            <button className="button" ref={unSelectButtonRef} 
              size="small"
              onClick={handleMoveUnSelect}
            >
              <IonIcon icon={chevronDown} />{unSelectButtonTitle || 'UnSelect'}
            </button>
            <button className="button" ref={selectButtonRef} 
              size="small"
              onClick={handleMoveSelect}
            >
              <IonIcon icon={chevronUp} />{selectButtonTitle || 'Select'}
            </button>
            {buttons ? buttons : 
              <button className="button"
                size="small"
                onClick={() => {
                  onDone(selectList.map(v => v.value));
                }}
              >Done</button>
              }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CustomList title={unSelectTitle || 'Availables'} 
            buttonRef={selectButtonRef} filterTextRef={availableListFilterTextRef}
            disabled={selectListRef?.current?.checkRef.length > 0}
            items={availableList} extFilter={availFilter} ref={availableListRef}/>
        </Grid>
      </Grid>
    </div>
  </ThemeProvider>;
});

export default TransferList;
export const multiWordFilter = (f, items, field) => {
  f = f.toLowerCase();
  let swords = Utils.separateWords(f);
  return items.filter(i => {
    let txt = Utils.extractTextFromElement(i[field]);
    for (let w of swords) {
      if (Utils.isWordsInString(txt, w)) {
        continue;
      }
      return false;
    }
    return true;
  });
}