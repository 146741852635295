import "./ScoreCard.scss";
import buzzer from "./bell-2s.wav";
import usFlagIcon from '../../icons/flags/svg/us.svg';

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Utils from "../../serverUtils/Utils";
import { WINS_BY } from "./bracketUtils";
import { default as AddIcon } from "@mui/icons-material/AddCircleOutline";
import { default as MinusIcon } from "@mui/icons-material/RemoveCircleOutline";
import { default as FlipRegIcon } from "@mui/icons-material/SwapHoriz";
import UserModel from "../../serverUtils/models/UserModel";
import { IonIcon } from "@ionic/react";
import { default as FullScreenIcon } from '@mui/icons-material/Fullscreen';

const formatTimer = cl => {
    cl = cl < 0 ? 0 : cl;
    let mins = Math.floor(cl / 60);
    let seconds = cl % 60;
    return `${Utils.padZeros(mins, 2)}:${Utils.padZeros(seconds, 2)}`;
}

const timerToClock = timer => {
    let mmss = timer.split(':');
    return (parseInt(mmss[0]) * 60) + parseInt(mmss[1]);
}

const ScoreCard = forwardRef(({ data, tournament, readOnly, onChange, onExit }, ref) => {
    const [division, setDivision] = useState();
    const [promoterIcon, setPromoterIcon] = useState()
    const [reg1, setReg1] = useState();
    const [reg2, setReg2] = useState();
    const [flag1, setFlag1] = useState();
    const [flag2, setFlag2] = useState();
    const [isBuzzer, setIsBuzzer] = useState(false);
    const [winner, setWinner] = useState();
    const [scoreCardData, setScoreCardData] = useState();
    const [clockTimer, setClockTimer] = useState();
    const fullscreenRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState();
    const [isClockRunning, setIsClockRunning] = useState(false);

    const getReg = (bracketEntry, setFlag) => {
        const { membership, division } = bracketEntry;
        let reg = tournament.getRegistrations()
            .find(r => r.membership === membership && [r.division, ...(r.pool||'').split('|')].includes(division));
        if (reg && (!reg.country || reg.country !== 'US')) {
            // import(getFlag(reg.country || 'us')).then(
            //     ({ default: flag }) => {
            //         setFlag(flag);
            //     });
        }
        return reg;
    }

    useImperativeHandle(ref, () => ({
        fullscreen,
    }));

    useEffect(() => {
        if (!tournament || !data) {
            return;
        }
        initRegs(Utils.deepCopy(data));
        let { timer } = tournament.getLeague();
        if (!timer || !timer.duration || !timer.overtime) {
            timer = { duration: '03:00', overtime: '01:00' };
        }
        setClockTimer(timer);
    }, [data]);

    const initRegs = (sdata) => {
        setScoreCardData(sdata);
        let league = tournament.getLeague();
        let picon = league && league.getIcon && league.getIcon() && league.getIcon()?.sdata.join('');
        setPromoterIcon(picon);
        const { schedule, bracket_entry1, bracket_entry2 } = sdata;
        setDivision(tournament.getAvailableDivisions().find(d => d.id === bracket_entry1.division));
        setReg1(getReg(bracket_entry1, setFlag1));
        setReg2(getReg(bracket_entry2, setFlag2));
    }

    const Reg = ({ className, reg, winBy }) => {
        const SelectWinButton = () => {
            useEffect(() => {
                selectWinnerRef.current.style.display = isClockRunning? 'none':'';
            }, [isClockRunning]);
            const selectWinnerRef = useRef();
            return <button className="button select-winner"
                ref={selectWinnerRef}
                onClick={e => {
                    e.stopPropagation();
                    const {bracket_entry1, bracket_entry2} = scoreCardData;
                    bracket_entry1.result = '';
                    bracket_entry2.result = '';
                    if (bracket_entry1.membership === reg.membership) {
                        bracket_entry1.result = WINS_BY.WIN.value;
                    }else {
                        bracket_entry2.result = WINS_BY.WIN.value;
                    }
                    onExit(scoreCardData);
                }}
            >Select as Winner</button>;
        }
        return !reg ? '' :
            <div className={`reg-wrapper ${className}`} >
                {/* <SelectFormInput options={WINS_BY_OPTIONS} name={className}
                    label={'Select Win By'}
                    value={reg.result}
                    onChange={v => {
                        console.log(reg, v)
                        v = !v? null:v;
                        setWinner(v? reg.id: null);
                        reg.result = v;
                        winBy(v);
                        onChange({});
                    }}/> */}
                <span className={winner === reg.id ? 'blink' : ''}>
                    {UserModel.getMembershipName(reg.getMembership())}
                </span>
                <span className="gym-name">{reg.getGym().name}</span>
                <SelectWinButton />
            </div>
    }

    const { bracket_entry1, bracket_entry2 } = scoreCardData || {};
    const updateWinner = (r, be) => {
        bracket_entry1.result = null;
        bracket_entry2.result = null;
        be.result = r;
    }

    const fullscreen = (isDone) => {
        if (fullscreenRef.current) {
            if (!isDone) {
                fullscreenRef.current.style.display = '';
                setIsFullscreen(true);
            }
            Utils.toggleFullscreen(fullscreenRef.current, (status) => {
                if (!status) {
                    fullscreenRef.current.style.display = 'none';
                    setIsFullscreen(false);
                    onExit && onExit();
                }
            });
        }
    }

    const onPointChange = () => onChange && onChange(scoreCardData);
    return !scoreCardData ? '' :
        <div className={`ScoreCard ${isFullscreen? 'fullscreen':''}`} ref={fullscreenRef} style={{display: 'none'}}>
            <div className="screen-buttons">
                <FlipRegIcon className="FlipRegIcon" 
                    onClick={e => {
                        e.stopPropagation();
                        let sdata = {
                            bracket_entry1: scoreCardData.bracket_entry2, 
                            bracket_entry2: scoreCardData.bracket_entry1, 
                            schedule: scoreCardData.schedule
                        };
                        initRegs(sdata);
                    }}/>
                <FullScreenIcon className="FullScreenIcon" 
                    onClick={e => {
                        e.stopPropagation();
                        fullscreen(true);
                    }}/>
            </div>
            <div className="division">{division && (division.name || division.pool)}</div>
            <div className="scorecard-competitors">
                <Reg reg={reg1} className="reg1" winBy={r => updateWinner(r, bracket_entry1)}/>
                <Reg reg={reg2} className="reg2" winBy={r => updateWinner(r, bracket_entry2)}/>
            </div>
            <div className="timer">
                <div className="flag-holder">
                    <IonIcon
                        icon={flag1 || usFlagIcon}
                        className="reg1"
                    />
                </div>
                <Clock clockTimer={clockTimer}
                    data={scoreCardData}
                    readOnly={readOnly}
                    setIsClockRunning={setIsClockRunning}
                    onChange={({ clock }) => {
                        scoreCardData.clock = clock;
                        if (clock <= 0) {
                            onPointChange();
                        }
                    }} />
                <div className="flag-holder">
                    <IonIcon
                        icon={flag2 || usFlagIcon}
                        className="reg2"
                    />
                </div>
            </div>
            <div className='neg-points'>
                <div className='reg1'>
                    <Point className="point" bracketEntry={bracket_entry1} field='scorep' onChange={onPointChange} />
                    <Point className="point2" bracketEntry={bracket_entry1} field='score2' onChange={onPointChange} />
                </div>
                <div className='promoter-icon'>
                    {promoterIcon && (
                        <img
                            alt='promoter-icon'
                            src={promoterIcon}
                        />
                    )}
                </div>
                <div className='reg2'>
                    <Point className="point2" bracketEntry={bracket_entry2} field='score2' onChange={onPointChange} />
                    <Point className="point" bracketEntry={bracket_entry2} field='scorep' onChange={onPointChange} />
                </div>
            </div>
            <div className='points'>
                <div className='reg1'>
                    <Point className="point" bracketEntry={bracket_entry1} field='score' onChange={onPointChange} />
                </div>
                <div className='reg2'>
                    <Point className="point" bracketEntry={bracket_entry2} field='score' onChange={onPointChange} />
                </div>
            </div>
            {isBuzzer && (
                <audio
                    src={buzzer}
                    autoPlay
                    onEnded={() => setIsBuzzer(false)}
                />
            )}
        </div>
})

const IncDecButtons = ({ onInc, onDec }) => {
    return <div className='buttons'>
        {onInc && <AddIcon className="button"
            onClick={e => {
                e.stopPropagation();
                onInc();
            }}
        />}

        {onDec && <MinusIcon className="button"
            onClick={e => {
                e.stopPropagation();
                onDec();
            }}
        />}
    </div>
}

const Point = ({ className, bracketEntry, field, onChange }) => {
    const [point, setPoint] = useState(0);
    useEffect(() => {
        bracketEntry && setPoint(bracketEntry[field] || 0);
    }, [bracketEntry]);

    const updateResult = (inc) => {
        let v = point + inc;
        bracketEntry[field] = v;
        setPoint(v);
        onChange(bracketEntry);
    }
    return <div
        className={className}
        onClick={() => updateResult(1)}>
        <IncDecButtons
            onDec={() => updateResult(-1)}
        />
        {Utils.padZeros(point, 2)}
    </div>
}

const Clock = ({ clockTimer, data, readOnly, onChange, setIsClockRunning }) => {
    const [clock, setClock] = useState();
    const [runningTimer, setRunningTimer] = useState();
    const [isRunning, setIsRunning] = useState(false);
    const [timerInterval, setTimerInterval] = useState();
    useEffect(() => {
        if (clockTimer) {
            let cl = timerToClock(clockTimer.duration);
            setClock(cl || 0);
            setRunningTimer(formatTimer(cl || 0));
            onChange(cl);
        }
    }, [clockTimer]);
    useEffect(() => {
        if (isRunning) {
            runClock();
        } else {
            timerInterval && clearInterval(timerInterval);
        }
    }, [isRunning])

    const runClock = () => {
        if (!data.schedule.start_ts) {
            data.schedule.start_ts = new Date().getTime();
        }
        let cl = clock;
        const doRun = () => {
            cl--;
            setClock(cl);
            onChange({ clock: cl });
            setRunningTimer(data.schedule.duration = formatTimer(cl));
            if (cl <= 0) {
                timerInterval && clearInterval(timerInterval);
                setIsClockRunning(false);
                return setIsRunning(false);
            }
        }
        setTimerInterval(setInterval(doRun, 1000));
    }

    const updateClock = (inc) => {
        let cl = clock + inc;
        setClock(cl);
        setRunningTimer(formatTimer(cl));
    }
    return <div
        className='clock'
        onClick={() => {
            if (readOnly) {
                return;
            }
            setIsRunning(!isRunning);
            setIsClockRunning(!isRunning);
        }}>
        <IncDecButtons
            onInc={() => updateClock(15)}
            onDec={() => updateClock(-15)}
        />
        <div>
            {runningTimer}
        </div>
    </div>
}

export default ScoreCard;