import React, { useEffect, useRef, useState } from "react";

import './DashboardPage.scss';
import Utils from "../../serverUtils/Utils";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import TableFormInput, { IndexCell, ToolbarButtons } from "../../components/FormInput/TableFormInput";
import { RequestCommon } from "../../serverUtils/requests";
import AlertPane from "../../components/FormInput/AlertPane";
import { default as ClearIcon } from "@mui/icons-material/Clear";
import { default as FileIcon } from "@mui/icons-material/FileOpen";
import Checkbox from '@mui/material/Checkbox';
import HelpModel from "../../serverUtils/models/HelpModel";
import TextFormInput from "../../components/FormInput/TextFormInput";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import { DATA_HAS_CHANGED_MESSAGE, DATA_HAS_CHANGED_MESSAGE_WARNING, hasTableFormChange, includesBlankOption } from "../../components/Form/Form";
import { HelpSchema } from "../../serverUtils/Models";


const headCells = [
    {
        id: "url",
        label: "URL",
    },
    {
        id: "title",
        label: "Title",
    },
    {
        id: "file_name",
        label: "File Name",
    },
    {
        id: "file_size",
        label: "Size",
    },
    {
        id: "last_date_modified",
        label: "Date",
    },
    {
        id: "images",
        label: "Images",
    },
]

const CRC_Fields = [...Utils.listObjectKeys(HelpSchema().model), 'name', 'size'];
const HelpSetup = () => {
    const [helps, setHelps] = useState([]);
    const [message, setMessage] = useState();
    const tableRef = useRef();
    const toolbarButtonsRef = useRef();
    const toolbarButtonsRef1 = useRef();
    const dataHasChangeRef = useRef();
    useEffect(() => {
        HelpModel.getHelps() 
            .then(hs => {
                if (!hs) {
                    return;
                }
                const {helps, files_doc} = hs;
                helps.forEach(h => {
                    h.getImages = () => files_doc.filter(f=> h.images.includes(f.id));
                    h.getFile = () => files_doc.find(f => f.id === h.file);
                    h.crc = Utils.getCRC(h, CRC_Fields);
                });
                setHelps(helps);
            });
    }, []);

    const checkCRC = () => {
        let r = hasTableFormChange(helps, CRC_Fields);
        try{
            if (r) {
            toolbarButtonsRef.current.setIndicator({save: 'yellow'});
            toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
            }else {
            toolbarButtonsRef.current.setIndicator({save: ''});
            toolbarButtonsRef1.current.setIndicator({save: ''});
            }
        }finally {
            dataHasChangeRef.current.style.display = r? '':'none';
        }
    }

    const doAdd = () => {
        helps.push({
            id: `-${helps.length}`,
        });
        setHelps([...helps]);
    }

    const doSave = async () => {
        let error;
        try{
            for (let h of helps) {
                if (h.crc !== Utils.getCRC(h, CRC_Fields)){
                    let imagesData = h.getImages && h.getImages() && h.getImages().filter(i => i.id) || [];
                    let file = h.file;
                    let images = h.images;
                    delete h.file;
                    delete h.images;
                    let id = h.id;
                    if (id.startsWith('-')){
                        delete h.id;
                        let r = await HelpModel.addHelp(h);
                        if (!r || r.error) {
                            h.id = id;
                            return error=true;
                        }
                        h.id = r.id;
                    }

                    if (!file){
                        return error = true;
                    }
                    if (typeof file !== 'string') {
                        let data = await Utils.readLocalFile(file, 'text');
                        let fileData = {
                            data, 
                            name: file.name, 
                            lastModifiedDate: file.lastModified, 
                            size: file.size,
                        };
                        let r = await RequestCommon.uploadFile(fileData, p => console.log(p));
                        if (!r || r.error) {
                            return error = true;
                        }
                        fileData.data = [data];
                        fileData.id = h.file = r.id;
                        h.getFile = () => fileData;
                    }else {
                        h.file = file;
                    }
                    for (let i=0; i<(images||[]).length; i++) {
                        let image = images[i];
                        if (typeof image !== 'string'){
                            let data = await Utils.readLocalFile(image);
                            let imageData = {
                                data, 
                                name: image.name, 
                                lastModifiedDate: image.lastModified, 
                                size: image.size,
                            };
                            let r = await RequestCommon.uploadFile(imageData, p => console.log(p));
                            if (!r || r.error) {
                                return error = true;
                            }
                            imageData.id = images[i] = r.id;
                            imageData.data = [data];
                            imagesData.push(imageData);
                        }else {
                            images[i] = image;
                        }
                    }
                    h.images = images;
                    h.getImages = () => imagesData;
                    let r = await HelpModel.updateHelp(h);
                    if (!r ||r.error) {
                        return error = true;
                    }
                    h.crc = Utils.getCRC(h, CRC_Fields);
                }
            }
        }finally{
            setMessage(error? `error: Error uploading rule file.`:'success: Successfully save helps');
            checkCRC();
        }
    }

    const doDelete = async () => {
        let error;
        for (let r of tableRef.current.selected) {
            let findex = helps.findIndex(h => h.id === r);
            if (!r.startsWith('-')){
                let result = await HelpModel.deleteHelp(r);
                if (!result || result.error) {
                    return error = true;
                }
            }
            helps.splice(findex, 1);
        }
        tableRef.current.setSelected([]);
        setHelps([...helps]);
        setMessage(error? 'error: Error deleting help':'success: Successfully deleted help');
    }

    const toolbarButtons = ref => (
        <ToolbarButtons ref={ref} doAdd={doAdd} doSave={doSave} doDelete={doDelete} />
    );
    return <div className="HelpSetup">
        <AlertPane message={message} setMessage={setMessage} timeOut={3000} />
        <div className="data-has-changed" ref={dataHasChangeRef} style={{display: 'none'}}>
            <AlertPane message={DATA_HAS_CHANGED_MESSAGE_WARNING} isFloat/>
        </div>
        <TableFormInput ref={tableRef}
            toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
            toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
            name="HelpSetup"
            className="HelpSetup-table"
            isEditable
            headCells={headCells}
            data={helps}
            renderTRow={({ row, index, isSelected, handleClick }) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const getFileName = () => {
                    if (row.id.startsWith('-') || (row.file && typeof row.file === 'object')) {
                        return <span className="new-file">{(row.file && row.file.name) || ''}</span>;
                    }
                    return  <span>{(row.getFile && row.getFile() && row.getFile().name) || ''}</span>;
                }
                const getFileSize = () => {
                    if (typeof row.file === 'object') {
                        return (row.file && Utils.bytesToSize(row.file.size)) || '';
                    }
                    return (row.getFile && row.getFile() && Utils.bytesToSize(row.getFile().size)) || '';
                }
                const getFileDate = () => {
                    if (typeof row.file === 'object') {
                        return Utils.formatDateTime(row.file && row.file.lastModified) || '';
                    }
                    return (row.getFile && row.getFile() && Utils.formatDateTime(row.getFile().lastModifiedDate)) || '';
                }
                const getFileImageNames = () => {
                    const getImageFileName = (img) => {
                        if (img && typeof img === 'object') {
                            return <span className="new-file">{(img && img.name) || ''}</span>;
                        }
                        let found = row.getImages && row.getImages().find(i => i.id === img);
                        return  <span>{!found? '':found.name}</span>;
                    }
                    const removeFile = f => {
                        row.images = row.images.filter(img => img != f);
                        checkCRC();
                        setHelps([...helps]);
                    }

                    if (row.id.startsWith('-')) {
                        return <ul className="images">
                            {(row.images||[]).map((i, k) => {
                                return <div className="image-name" key={k}>
                                    {i.name}
                                    <ClearIcon onClick={() => removeFile(i)}/>
                                </div>
                            })}
                        </ul>
                    }
                    return !row.images? '' : 
                        <ul className="images">
                            {row.images.map((i, k) => 
                                <div className="image-name" key={k}>
                                    {getImageFileName(i)}
                                    <ClearIcon onClick={() => removeFile(i)}/>
                                </div>)}
                        </ul>;
                }
           
                const checkboxRef = row.checkboxRef = React.createRef();
                return <TableRow className="Help" key={index}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={e => handleClick(e, row.id, checkboxRef)}
                >
                    <TableCell padding="checkbox">
                        <IndexCell ref={checkboxRef} isItemSelected={isItemSelected} labelId={labelId} index={index + 1} /> 
                    </TableCell>
                    <TableCell >
                        <TextFormInput
                            label={`URL`}
                            name={`url`}
                            value={row.url || ''}
                            onChange={v => {
                                row.url = v;
                                checkCRC();
                            }}
                        />
                    </TableCell>
                    <TableCell >
                        <TextFormInput
                            label={`Title`}
                            name={`title`}
                            value={row.title || ''}
                            onChange={v => {
                                row.title = v;
                                checkCRC();
                            }}
                        />
                    </TableCell>
                    <TableCell >
                        <LocalFiles 
                            getFileName={getFileName}
                            accept=".html, .htm"
                            onChange={e => {
                                const { files } = e.target;
                                row.file = files[0];
                                checkCRC();
                                setHelps([...helps]);
                            }}
                        />
                    </TableCell>
                    <TableCell >{getFileSize()}</TableCell>
                    <TableCell >{getFileDate()}</TableCell>
                    <TableCell >
                        <LocalFiles 
                            multiple
                            getFileName={getFileImageNames}
                            accept="image/*"
                            onChange={e => {
                                const { files } = e.target;
                                row.images = [...(row.images||[]), ...files];
                                checkCRC();
                                setHelps([...helps]);
                            }}
                        />
                    </TableCell>   
                </TableRow>
            }}
        />
    </div>
}

const LocalFiles = ({title, getFileName, multiple=false, accept='*', onChange}) => {
    const fileInputRef = useRef();

    return <div className="LocalFiles">
        <span className="filename">{getFileName()}</span>
        <Tooltip title={title || "Add file from local disk"}>
            <FileIcon
                onClick={() => {
                    fileInputRef.current.click();
                }}/>
        </Tooltip>
        <input
            accept={accept}
            multiple={multiple}
            ref={fileInputRef}
            hidden
            type="file"
            onChange={f => {
                onChange(f);
            }}
        />
    </div>
}

export default HelpSetup;