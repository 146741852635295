//password reset by entity admin.
//admin should be able to clean duplicate profiles.
//look into merging profile from gym admin only.
//able add combination with new pricing.


import {
  GymSchema, ImageSchema,
  LeagueSchema,
  MessageSchema,
  MembershipSchema,
  TournamentSchema,
  DivisionSchema,
  GroupScoreSchema,
  TeamSchema,
  PromoSchema,
  MatchSchema,
  PlacementSchema,
  RegistrationSchema,
  BanSchema,
  AgeGroupSchema,
  WeightClassSchema,
  RankSchema,
  CartSchema,
  BracketSchema,
  ClaimRequestSchema,
  DivisionComboSchema,
  DivisionTagSchema,
  TransactionCreditSchema,
  HelpSchema,
  FileSchema,

} from "./Models";
import Utils from "./Utils";
import axios from "axios";

//helpers
export const getPathnameWithQuery = () => document.location.pathname + document.location.search;
export const event_sources = new Set();
export const setupSSE = (id, callback) => {
  const url = `${getSSEUrl()}/${id}`;

  // Immediately add the URL to the Set to prevent duplicate connections
  if (event_sources.has(url)) {
    console.log('SSE connection already exists for this URL.');
    return callback(true);
  }

  // Mark the URL as "pending" to avoid race conditions
  event_sources.add(url);

  const eventSource = new EventSource(url);

  // Handle incoming messages
  eventSource.onmessage = function(event) {
    console.log('Received message:', event);
    try{
      let messageObj = JSON.parse(decodeURIComponent(event.data));
      messageObj.message && callback(messageObj);
    }catch(e) {
      console.log('onmessage: ', e);
    }
  };

  // Handle errors
  eventSource.onerror = function(event) {
    console.error('EventSource failed:', event);
    
    // Remove the URL from the Set to allow reconnection
    eventSource.close();
    event_sources.delete(url);
    callback(false);
  };

  // When connection is established, confirm URL is active
  eventSource.onopen = function(event) {
    console.log('Connection to server opened.', url);
    callback(true);
  };
};



const TEST_SERVER = '64.190.226.22';
export let GRAPH_SERVER_HOST = '/';
let GRAPH_SERVER_URL;
let getGraphServerUrl = async () => {
  if (!GRAPH_SERVER_URL) {
    let isLocal = getRunningMode() === RUNNING_MODE.DEV;
    GRAPH_SERVER_HOST = isLocal? `https://${document.location.hostname}:3001`:'';
    let gurl = isLocal ? `${GRAPH_SERVER_HOST}/graphql` : '/graphql';
    try {
      let response = await fetch(gurl);
      console.log(response);  
      let json = await response.json();
      if (json) {
        return GRAPH_SERVER_URL = gurl;
      }
      throw 'No GraphQl';
    } catch (e) {
      console.log(e);
      if (document.location.hostname === 'localhost' && !Utils.cookie('gih_debug')){
        GRAPH_SERVER_HOST = `https://${TEST_SERVER}:3001`;
        GRAPH_SERVER_URL = `${GRAPH_SERVER_HOST}/graphql`;
      }
    }
  }
  return GRAPH_SERVER_URL;
}
export const setGraphServerUrl = url => {
  GRAPH_SERVER_URL = url;
}

export const RUNNING_MODE = {
  TEST_NGINX: 1,
  DEV: 2,
  PRODUCTION: 0,
  TEST_SERVER: 3,
}
export const getRunningMode = () => {
  const {hostname, port} = document.location;
  const isTestNginx = Utils.intersection(['localhost', '4000'], [hostname, port]).length === 2;
  const isDevEnv =  Utils.intersection(['localhost', '3000'], [hostname, port]).length === 2;
  const isTestServer =  Utils.intersection([TEST_SERVER, '3001'], [hostname, port]).length === 2;

  if (isTestNginx) {
    return RUNNING_MODE.TEST_NGINX;
  }
  if (isDevEnv) {
    return RUNNING_MODE.DEV;
  }
  if (isTestServer) {
    return RUNNING_MODE.TEST_SERVER;
  }
  return RUNNING_MODE.PRODUCTION;
}
export const getSSEUrl = () => {
  let mode = getRunningMode();
  if (mode === RUNNING_MODE.DEV) {
    return `https://localhost:3001/sse_events`;
  }
  return '/sse_events';
}

// export const graphQlURL = 'https://3678-24-193-76-237.ngrok.io/graphql';
const GRAPH_TEMPLATE_UPDATE =
   `error
    id`;
const REQUESTS = {};

const REQUEST_HEADERS = {
  withCredentials: true,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Access-Control-Allow-Headers": "Content-Type, access-control-allow-origin, access-control-allow-headers",
      crossdomain: true,
    }
}
export class RequestUtils {
  static requestHeaders = REQUEST_HEADERS;

  static mutationRequest = (func, param, queryType) => {
    let isObject = typeof param === 'object';
    return RequestUtils.serverPost({
      query: `${queryType? 'query':'mutation'} {
        rs:${func}(${isObject ? 'params' : 'id'}:"${isObject ? RequestUtils.encodeParameter(param) : param}"){
          ${GRAPH_TEMPLATE_UPDATE}
        }
      }`,
      params: {func, param, queryType}
    });
  }

  static encodeParameter = (param) => {
    return encodeURIComponent(JSON.stringify(param));
  }

  static getURLId = (entity) => {
    let path = document.location.href.split(`/${entity}/`);
    if (path.length >= 2){
      return path[1].split('/')[0].split('?')[0];
    }
  }

  static getURLParameters = () => {
    let param = {};
    let paramString = document.location.href.split('?');
    if (paramString.length > 1) { 
      let queryString = new URLSearchParams(paramString[1]);
      for (let pair of queryString.entries()) {
        param[pair[0]] = pair[1];
      }
    }
    return param;
  }

  static insertURLParam = (key, value, history, isReset) => {
    if (!history) {
      return;
    }
    if (!key) {
      return history.push(window.location.pathname);
    }

    key = encodeURIComponent(key);
    value = value && encodeURIComponent(value);

    let kvp = window.location.search.substr(1).split('&');
    if (isReset || kvp[0] === '') {
      const path = window.location.pathname + '?' + key + '=' + value;
      history.push(path);
    } else {
        let i = kvp.length; let x; while (i--) {
            x = kvp[i].split('=');

            if (x[0] === key) {
                if (!Utils.isEmpty(value)) {
                  x[1] = value;
                  kvp[i] = x.join('=');
                }else {
                  kvp[i] = value;
                }
                break;
            }
        }

        if (i < 0) { 
            kvp[kvp.length] = [key, value].join('=');
        }

      const refresh = window.location.pathname + '?' + kvp.filter(k => k).join('&');
      history.push(refresh);
    }
  }

  static minifyQuery = (query) => query.replace(/\s+/g, ' ');
  static serverPost = async (graph) => {
    graph.query = RequestUtils.minifyQuery(graph.query);
    let md5 = Utils.md5(graph.query);
    const isCache = () => {
      return ['mutation', 'query { login(user_login:']
                .find(e => graph.query.startsWith(e));
    }
    if (!isCache() && REQUESTS[md5]) {
      graph.response = REQUESTS[md5];
      // console.log('graphQL: cache: ', md5, Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'), ': ', graph);
      return graph.response;
    }
    
    let reqHeaders = {...REQUEST_HEADERS};
    graph.session = Utils.cookie('gih_session');
    const api = axios.create(reqHeaders);
    let gurl = await getGraphServerUrl();
    let isAddMessage = graph.query.includes('addMessage') || graph.query.includes('addBulkMessage');
    if (isAddMessage && [RUNNING_MODE.TEST_NGINX, RUNNING_MODE.PRODUCTION].includes(getRunningMode())) {
      gurl += '_sendmessage';
    }

    let graphConsole = {...graph, query: `${graph.query.substring(0, 100)}${graph.query.length>100 && '...'}`};
    return REQUESTS[md5] = api.post(gurl, graph)
                              .then(r => {
                                setTimeout(() => delete REQUESTS[md5], 10*1000);
                                graph.response = r;
                                console.log('graphQL:', Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'), {...graphConsole, response: r});
                                if (r.status === 200 && 
                                    r.data.errors && 
                                    r.data.errors.find(e => e.message.includes('"not authenticated"'))){
                                  Utils.deleteCookie('gih_session');
                                  Utils.deleteCookie('last_url');
                                  if (!['/login', '/logout'].find(p => document.location.pathname.includes(p))) {
                                    return document.location.href = '/login';
                                  }
                                }
                                return r;
                              }).catch(e => {
                                graph.post_error = e;
                                console.log('graphQL: error: ', Utils.formatDateTime(new Date().getTime(), 'hh:mm:ss'), {...graphConsole, post_error: e});
                                delete REQUESTS[md5];
                              });
  }

  static isResponseGood(response) {
    return response && response.status === 200 && (response?.data?.data);
  }

  static getResponseData(response, key) {
    if (RequestUtils.isResponseGood(response)) {
      const {data} = response.data;
      if (!key){
        let _rs = [];
        for (let k of Object.keys(data)) {
          if (k === 'rs') {
            return data[k];
          }
           _rs.push(data[k])
        }
        return _rs;
      }
      return data[key];
    }
    return {error: 'server error'};
  }
}

//server calls
export class RequestCommon {
  static requestClaimProfile = params => RequestUtils.mutationRequest('requestClaimProfile', params);
  static approvedClaimProfile = params =>  RequestUtils.mutationRequest('approvedClaimProfile', params);
  static unapproveClaimProfile = id =>  RequestUtils.mutationRequest('unapproveClaimProfile', id);

  static getClaimRequests = (approved=false, search='') => RequestUtils.serverPost({
    query: `query {
        rs:getClaimRequests(approved: ${approved}, search: "${search}") {
          claim_requests { ${ClaimRequestSchema().graphql} }
          memberships_doc { id, first_name, last_name }
          teams_doc { id name country }
          gyms_doc { id name city state region suburb country }
        }
    }`
  });

  static getClaimRequest = id => RequestUtils.serverPost({
    query: `query {
        rs:getClaimRequest(id: "${id}") {
          ${ClaimRequestSchema().graphql}
        }
    }`
  });

  static getFile = async id => {
    let response = await RequestUtils.serverPost({
      query: `query {
        rs:getFile(id: "${id}") {
          id
          data
          ext
          lastModifiedDate
          name
        }
      }`,
      params: {id}
    });
    return RequestUtils.getResponseData(response);
  };

  static uploadFile = (params, cbProgress) =>  {
    return new Promise(async resolve => {
      const chunk = 20*1000;
      let uploaded = 0;
      let total = params.data.length;
      let chunks = Utils.stringToChunks(params.data, chunk);
      for (let c of chunks) {
        let _params = {...params, data: c}
        let response = await RequestUtils.mutationRequest('uploadFile', _params);
        let result = RequestUtils.getResponseData(response);
        if (!result || result.error) {
          return resolve({error: 'server error'});
        }
        params.id = result.id;
        cbProgress && cbProgress(Math.ceil(((uploaded+=c.length) * 100) / total));
      }
      resolve({id: params.id});
    });
  };

  static deleteFile = id => {
    let response = RequestUtils.serverPost({
      query: `mutation {
        rs:deleteFile(name: "${id}") {
          error
          id
        }
      }`,
      params: {id}
    });
    return RequestUtils.getResponseData(response);
  };

  static getNewsRequest = () => RequestUtils.serverPost({
    query : `query {
      rs:searchYouTube1 {
        description
        publish_date
        thumbnail {url height width}
        title
        id
      }
    }`
  });

  static getGIHNews = () => RequestUtils.serverPost({
    query: `query { 
      rs: getGIHNews{ 
        dates {start_date end_date close_date} 
        locations {address city state country}
        name id news_type 
        image {id data}
      } 
    }`
  });

  static siteSearch = (search_string) => RequestUtils.serverPost({
    query: `query { 
      rs: searchSite (search_string: "${search_string}")`
  });
}

//Session request
export class RequestSession {
  static gihSubscription = (payment_detail) => RequestUtils.mutationRequest('gihSubscription', payment_detail);

  static getGIHSubscription = () => RequestUtils.serverPost({
    query: `query {
      rs:getGIHSubscription
    }`,
  });

  static loginRequest = (username, pwd) => RequestUtils.serverPost({
    query: `query {
      rs:login(user_login: "${username.trim()}", password: "${pwd.trim()}") {
        memberships { ${MembershipSchema().graphql} }
      }
    }`,
    params: {username, pwd}
  });

  static logoutRequest = () => RequestUtils.serverPost({
    query: `query {
      rs:logout{
        id
      }
    }`
  });

  static getSessionMessagesRequest = (ts='') => RequestUtils.serverPost({
    query : `query {
      rs:getMessageTo(ts: "${ts}") {
        first_name
        last_name
        id
        message_type
        created_on
        content
        status
        from
      }
    }`,
    params: {ts}
  });

  static getSessionMessagesFromRequest = (ts = '') => RequestUtils.serverPost({
    query: `query {
      rs:getMessageFroms(ts: "${ts}") {
        messages {
          from count to message_type created_on
        }
        memberships_doc {id first_name, last_name}
        tournaments_doc {id name}
        leagues_doc {id name}
        gyms_doc {id name}
        teams_doc {id name}
      }
    }`,
    params: { ts }
  });

  static getSessionRequest = () => RequestUtils.serverPost({
    query : `{
      session {
        memberships {
          ${MembershipSchema().graphql}
        }
        images_doc {
          id
          data
          post_by
          created_on
          created_by
          last_update
          update_by
          ref
          image_type
          length
        }
        gyms_doc {
          id
          name
          status
          teams {
            id
            status
          }
        }
        leagues_doc {
          id
          name
          status
        }
        teams_doc {
          id
          name
          status
          captains {id display}
          leagues {
            id
            status
          }
        }
        tournaments_doc {
          id
          name
          dates {
            start_date
            end_date
            close_date
            coach_change_date
            member_change_date
            refund_date
          }
          locations {
            venue
            address
            city
            state
            country
            zip
            region
            suburb
          }
          status
          publish {
            competitors
            brackets
            schedules
            teams
            gyms
            results
            competitor_columns
          }
        }
        registrations_doc {
          ${RegistrationSchema().graphql}
        }
        carts_doc {
          ${CartSchema().graphql}
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        divisions_doc {
          ${DivisionSchema().graphql}
        }
        friends_doc {
          id
          requestor
          requestee
          request_date
          confirm_date
        }
        families_doc {
          ${MembershipSchema().graphql_min}
        }
      },
      ${RequestSession.GET_ADMIN_INFO}
    } 
    `
  });

  static GET_ADMIN_INFO = `getAdminInfo{
        tournaments{id name status created_by trans_fee dates{start_date}}
        teams{id name status created_by trans_fee}
        leagues{id name status created_by trans_fee}
        gyms{id name status created_by trans_fee}
        payouts
        athletes {id first_name last_name}
      }`;

  static getAdminInfo = () => RequestUtils.serverPost({
    query: `query {
      rs:${RequestSession.GET_ADMIN_INFO},
    }`
  });

  static getSupportMessages = () => RequestUtils.serverPost({
    query: `query {
      rs:getSupportMessages{
        messages{${MessageSchema().graphql}}
        memberships_doc{id first_name last_name}
        images_doc{${ImageSchema().graphql}}
      }
    }`
  });

  static getMatches = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getMatches(id: "${id}"){
        bracket_locations 
        tournaments_doc { ${TournamentSchema().graphql_min} divisions {id is_block} }
        divisions_doc { ${DivisionSchema().graphql_min} }
        memberships_doc { ${MembershipSchema().graphql_min} }
        opponents 
      }
    }`,
    params: {id}
  });

}

export class RequestDivision {
  static getDivisions = ids => {
    return RequestUtils.minifyQuery(`query {
      rs:getDivisions(ids: "${RequestUtils.encodeParameter(ids)}") {
        ${DivisionSchema().graphql}
      }}`);
  }

  static getTournamentDivisions = id => {
    return RequestUtils.minifyQuery(`query {
      rs:getTournamentDivisions(id: "${id}") {
        ${DivisionSchema().graphql}
      }}`);
  }

  static getLeagueDivisions = id => {
    return RequestUtils.minifyQuery(`query {
      rs:getLeagueDivisions(id: "${id}") {
        ${DivisionSchema().graphql}
      }}`);
  }
}

// Tournament requests
export class RequestTournament {
  static changeRegistrationGym = params => RequestUtils.serverPost({
    query: `mutation {
      rs:changeRegistrationGym(params: "${RequestUtils.encodeParameter(params)}") {
        id error
      }
    }`,
    params: { params }
  });

  static changeRegistrationTeam = params => RequestUtils.serverPost({
    query: `mutation {
      rs:changeRegistrationTeam(params: "${RequestUtils.encodeParameter(params)}") {
        id error
      }
    }`,
    params: { params }
  });

  static getTournamentDates = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getTournamentDates(id: "${id}") {
        start_date end_date close_date member_change_date coach_change_date refund_date
      }
    }`,
    params: { id }
  });

  static checkUrlPath = ({ league_id, url_path }) => RequestUtils.serverPost({
    query: `query {
      rs:checkUrlPath(league_id: "${league_id}", url_path: "${url_path}") {is_avail}
    }`,
    params: { league_id, url_path }
  });

  static importRegistrations = (file, tournament) => RequestUtils.serverPost({
    query: `mutation {
      rs:importRegistrations(file: "${file}", tournament: "${tournament}") {
        id error
      }
    }`,
    params: { file, tournament }
  });

  static getScheduleEntriesQuery = tournament => {
    return RequestUtils.minifyQuery(`query {
      rs:getSchedules(tournament: "${tournament}") {
        id
        status
        start_ts
        duration
        overtime_duration
        station
        priority
        bracket_entry1
        bracket_entry2
        tournament
        division
        mat
    }}`);
  }
  static getScheduleEntries = (tournament) => RequestUtils.serverPost({
    query: RequestTournament.getScheduleEntriesQuery(tournament),
    tournament
  });

  static addScheduleEntry = (params) => RequestUtils.serverPost({
    query: `mutation {
      rs:addSchedule(params: "${RequestUtils.encodeParameter(params)}") {
        id error
      }
    }`,
    params: { params }
  });

  static updateScheduleEntry = (params) => RequestUtils.serverPost({
    query: `mutation {
      rs:updateSchedule(params: "${RequestUtils.encodeParameter(params)}") {
        id error
      }
    }`,
    params: { params }
  });

  static updateScheduleEntries = (params, tournament, division, isAppend = false) => RequestUtils.serverPost({
    query: `mutation {
      rs:updateSchedules(params: "${RequestUtils.encodeParameter(params)}", 
        tournament:"${tournament}", division:"${division}", isAppend:${isAppend}) {
        id error
      }
    }`,
    params: { params }
  });

  static getBracketEntriesQuery = tournament => {
    return RequestUtils.minifyQuery(`query {
      rs:getBracketEntries(tournament: "${tournament}") {
        ${BracketSchema().graphql}
      }
    }`);
  }

  static getBracketEntries = (tournament) => RequestUtils.serverPost({
    query: RequestTournament.getBracketEntriesQuery(tournament),
    params: { tournament }
  });

  static addBracketEntry = (params) => RequestUtils.serverPost({
    query: `mutation {
      rs:addBracketEntry(params: "${RequestUtils.encodeParameter(params)}") {
        id error
      }
    }`,
    params: { params }
  });

  static updateBracketEntry = (params) => RequestUtils.serverPost({
    query: `mutation {
      rs:updateBracketEntry(params: "${RequestUtils.encodeParameter(params)}") {
        id error
      }
    }`,
    params: { params }
  });

  static updateBracketEntries = (params, tournamentId, divisionId, bracket_type, isAppend) => RequestUtils.serverPost({
    query: `mutation {
      rs:updateBracketEntries(params: "${RequestUtils.encodeParameter(params)}",
        tournament: "${tournamentId}", 
        division: "${divisionId}", 
        bracket_type: "${bracket_type}",
        isAppend:${isAppend}) {
        id error
      }
    }`,
    params: { params, tournamentId, divisionId, bracket_type }
  });

  static deleteBracketEntry = (id) => RequestUtils.serverPost({
    query: `mutation {
      rs:deleteBracketEntry(id: "${id}") {
        id error
      }
    }`,
    params: { id }
  });

  static requestRefund = (id, refund) => RequestUtils.serverPost({
    query: `mutation {
      rs:requestRefund(id: "${id}", refund: "${RequestUtils.encodeParameter(refund)}"){
        id error
      }
    }`
  });

  static searchTournament = (params) => RequestUtils.serverPost({
    query: `query {
      rs:searchTournament(params: "${RequestUtils.encodeParameter(params)}") {
        id display
      }
    }`,
    params: { params }
  });

  static getBusinessInfo = id => RequestUtils.serverPost({
    query: `query {
      rs:getTournamentInfo(id: "${id}") {
        id
        league
        is_lock
        trans_fee
        name
        email
        phone
        payment_info {
          payment_type
          account
          private_key
          location_id
          notes
          label
          cart_notes
          is_sandbox
        }
        currency
        waiver
        trans_fee
        t_shirt {
          expiration_date 
          sizes {
            name code
          }
        }
        dates {
          start_date end_date close_date member_change_date coach_change_date refund_date
        }
        divisions {
          id
          bracket_type
          is_bracket_by_seed
          is_block
          max_reg
          is_third
        }
        fee_info {
          fee_name
          logic {
            divisions
            operator
          }
          fees {
            period_name
            amount
            start_date
            end_date
          }
        }
      }
    }`,
    params: {id}
  });

  static getTournamentInfo = id => RequestUtils.serverPost({
    query: `query {
      rs:getTournamentInfo(id: "${id}") {
        id
        is_lock
        trans_fee
        last_update
        update_by
        created_on
        created_by
        status
        league
        name
        dates {
          start_date
          end_date
          close_date
          member_change_date
          coach_change_date
          refund_date
        }
        email
        phone
        owner
        admins {
          id
          display
        }
        divisions {
          id
          bracket_type
          is_bracket_by_seed
          is_block
          max_reg
          is_third
        }
        social_medias {
          name
          url
        }
        volunteers {
          id
          display
        }
        payment_info {
          payment_type
          account
          private_key
          location_id
          notes
          label
          cart_notes
          is_sandbox
        }
        currency
        fee_info {
          fee_name
          logic {
            divisions
            operator
          }
          fees {
            period_name
            amount
            start_date
            end_date
          }
        }
        t_shirt {
          expiration_date
          sizes {
            name
            code
          }
        }
        promo_codes
        confirmation {
          waiver
          info
        }
        contact_infos {
          name
          title
          phone
          email
        }
        locations {
          venue
          address
          city
          state
          country
          zip
          region
          suburb
          geo {
            long
            lat
          }
        }
        registration_limits {
          max_reg
          max_per_sub_team
          max_per_division
          max_per_gi
          max_per_no_gi
          max_per_age_group
        }
        notification_options
        group_scores
        registration_requirements {
          team
          league
          restricted_to_teams{
            display id
          }
        }
        publish {
          competitors
          brackets
          schedules
          teams
          gyms
          results
        }
        poolings {
          pool
          bracket_type
          group_score
        }
        waiver
        time_zone
        rule
      }
    }`,
    params: {id}
  });

  static getDisplayTournaments = ids => RequestUtils.serverPost({
    query: `query {${
      ids.map((id, i) => `_r${i}:getDisplayTournament(id: "${id}") {
        id name city state country
      }`).join(',')
    }}`,
    params: {ids}
  });

  static getCompetitors = ids => RequestUtils.serverPost({
    query: `query {${
      ids.map((id, i) => `_r${i}:getTournamentRegistrations(id: "${id}") {
        id
      }`).join(',')
    }}`,
    params: {ids}
  });

  static deleteTournament = id => {
    return RequestUtils.mutationRequest('deleteTournament', id);
  }

  static deleteRegistration = id => {
    return RequestUtils.serverPost({
      query: `mutation {
        rs:deleteRegistration(id: "${id}") {
          error
          id
        }
      }`,
      params: {id}
    });
  }

  static addPromo = params => {
    return RequestUtils.mutationRequest('addPromo', { ...params, id: undefined })
  };

  static updatePromo = params => RequestUtils.mutationRequest('updatePromo', params);

  static deletePromo = id => {
    return RequestUtils.serverPost({
      query: `mutation {
        rs:deletePromo(id: "${id}") {
          error
          id
        }
      }`,
      params: {id}
    });
  };

  static updateTournamentMatch = params => {
    let func = 'updateMatch';
    if (!params.id) {
      func = 'addMatch';
    }
    return RequestUtils.mutationRequest(func, params);
  }

  static deleteTournamentDivisions = (params) => RequestUtils.mutationRequest('deleteTournamentDivisons', params);
  static updateTournamentRequest = params => RequestUtils.mutationRequest(params.id? 'updateTournament':'addTournament', params);
  static cloneTournamentRequest = id => RequestUtils.mutationRequest('cloneTournament', id);

  static updateTournamentDivisionsRequest = (params) => RequestUtils.mutationRequest('updateTournamentDivisions', params);

  static updateTournamentFeeInfo = (params) => RequestUtils.mutationRequest('updateTournamentFeeInfo', params);

  static updateTournamentPoolingsRequest = (params) => RequestUtils.mutationRequest('updateTournamentPool', params);
  

  static saveTournamentRegistrationRequest = (params) => RequestUtils.mutationRequest('updateRegistration', params);

  static hideBracketRequest = (tournament_id, id, isHide=false) => RequestUtils.serverPost({
    query: `mutation {
        rs:hideBracket(tournament: "${tournament_id}" ,id: "${id}", isHide: ${isHide}){
          error
          id
        }
    }`,
    params: {tournament_id, id, isHide}
  });
  
  static getTournamentsRequest = ({
            page = 0, 
            page_size = 20, 
            search_string = '', 
            search_field = 'name'}) => RequestUtils.serverPost({
      query: `query {
        rs:getTournaments(page:${page}, page_size:${page_size}, search_string:"${search_string}", search_field:"${search_field}") {
          tournaments {
            id
            league
            name
            time_zone
            dates {
              start_date
              end_date
              close_date
            }
            locations {
              venue
              address
              city
              state
              country
              zip
              region
              suburb
            }
            admins {id}
          }
          images_doc {
            id ref data
          }
          leagues_doc {
            id name
          }
        }
      }`,
      params: {page, page_size, search_string, search_field}
  });
  
  static getTournamentQuery = (id) => {
    return RequestUtils.minifyQuery(`query {
        rs:getTournament(id: "${id}") {
    tournaments {
      ${TournamentSchema().graphql}
    }
    images_doc {
      ${ImageSchema().graphql}
    }
    memberships_doc {
      ${MembershipSchema().graphql_min} phone
      teams {
        id status
      }
      primary_gym
      gyms {
        id status
      }
      leagues {
        id status
      }
      gih {
        status
        start_date
        end_date
        recurrence
        amount
        paid_date
        cancel_date
        transaction
        is_sandbox
      }
    }
    teams_doc {
      ${TeamSchema().graphql_min}
    }
    matches_doc {
      ${MatchSchema().graphql}
    }
    placements_doc {
      ${PlacementSchema().graphql}
    }
    promos_doc {
      ${PromoSchema().graphql}
    }
    registrations_doc {
      ${RegistrationSchema().graphql}
    }
    bans_doc {
      ${BanSchema().graphql}
    }
    leagues_doc {
      ${LeagueSchema().graphql}
    }
    divisions_doc {
      ${DivisionSchema().graphql}
    }
    group_scores_doc {
      ${GroupScoreSchema().graphql}
    }
    age_groups_doc {
      ${AgeGroupSchema().graphql}
    }
    weight_classes_doc {
      ${WeightClassSchema().graphql}
    }
    ranks_doc {
      ${RankSchema().graphql}
    }
    gyms_doc {
      ${GymSchema().graphql_min}
      teams {
        id status
      }
    }
    carts_doc {
      ${CartSchema().graphql}
    }
    brackets_doc {
      ${BracketSchema().graphql}
    }
    files_doc {
      id name
    }
  }
}`);
  }
  static getTournamentRequest = (id) => RequestUtils.serverPost({
      query: RequestTournament.getTournamentQuery(id),
      params: [id]
  });
  
  static getTournamentCountriesRequest = () => RequestUtils.serverPost({
    query: `query { rs:getTournamentCountries }`
  });
  
  static getTournamentMessagesRequest = (id) => RequestUtils.serverPost({
    query : `query {
      rs:getMessages(id: "${id}", type: "tournament") {
        messages {
          from count to message_type created_on
        }
        memberships_doc {
          id first_name last_name
        }
      }
    }`,
    params: {id}
  });

  static getMerchantCartRequest = (id, search_string='') => (
      RequestCart.getMerchantCartRequest({id, search_string, ref_type: 'tournament'}));

  static getComboPrice = (params) => RequestUtils.mutationRequest('getComboPrice', params);

}
export class RequestCart {
  static sendConfirmation = (cartId, payment_status) => RequestUtils.serverPost({
    query: `mutation {
      rs:sendConfirmation(id: "${cartId}", payment_: "${payment_status}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: { cartId, payment_status }
  });

  static getPromo = ({ code, ref }) => RequestUtils.serverPost({
    query: `query {
      rs:getPromo(code: "${code}", ref: "${ref}") {
        ${PromoSchema().graphql}
      }
    }`,
    params: { code, ref }
  });

  static getPromos = ({ ref }) => RequestUtils.serverPost({
    query: `query {
      rs:getPromos(ref: "${ref}") {
        ${PromoSchema().graphql}
      }
    }`,
    params: { ref }
  });

  static payout = (ref, secret_key, amount) => RequestUtils.serverPost({
    query: `mutation {
      rs:payOut(ref: "${ref}", secret_key: "${secret_key}", amount: ${amount}){
        error id
      }
    }`,
    params: { ref, secret_key, amount }
  });

  static submitCart = cart => RequestUtils.serverPost({
    query: `mutation {
      rs:submitCart(params: "${RequestUtils.encodeParameter(cart)}"){
        error id
      }
    }`,
    params: {cart}
  });

  static deleteCart = id => RequestUtils.serverPost({
    query: `mutation {
      rs:deleteCart(id: "${id}"){
        error id
      }
    }`,
    params: {id}
  });

  static updateCart = (params) => RequestUtils.mutationRequest('updateCart', params);

  static addCart = (params) => RequestUtils.mutationRequest('addCart', params);

  static getCart = id => RequestUtils.serverPost({
    query: `query {
      rs:getCart(id: "${id}") {
        ${CartSchema().graphql}
      }
    }`,
    params: {id}
  });

  static getCartsByRefMembership = ref => RequestUtils.serverPost({
    query: `query {
      rs:getCartsByRefMembership(ref: "${ref}") {
        ${CartSchema().graphql}
      }
    }`,
    params: {ref}
  });

  static getMerchantCartRequest = ({id, search_string='', ref_type=''}) => RequestUtils.serverPost({
    query: `query {
      rs:getMerchantCarts(ref: "${id}", ref_type: "${ref_type}", search_string: "${search_string}") {
        carts {
          id
          status
          last_update
          update_by
          created_on
          created_by
          membership
          ref
          ref_type
          ref_display
          items {
            sku
            name
            description
            quantity
            unit_price
            tax_rate
            shipping_cost
            other_cost
            item_type
            original_unit_price
          }
          payment {
            payment_type
            account
            private_key
          }
          payment_info {
            payment_type
            account
            private_key
            notes
          }
          submitted_on
          tax
          shipping
          other_fee
          append_changes {
            date
            detail
            by
          }
          payment_status
          currency
          t_shirt
          is_waiver
          vendor_notes
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        images_doc {
          id
          data
          post_by
          created_on
          created_by
          last_update
          update_by
          ref
          image_type
          length
        }
        tournaments_doc {
          name
          t_shirt {
            sizes {
              name
              code
            }
          }
        }
      }
    }
    `,
    params: {id, search_string, ref_type}
  });
}

export class RequestGym {
  static getGymInfo = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getGymInfo(id: "${id}") {
        ${GymSchema().graphql}
      }
    }`,
    params: {id}
  });

  static getGymRequest = (id) => RequestUtils.serverPost({
    query: `query {
    rs:getGym(id: "${id}") {
      gyms { ${GymSchema().graphql}}
      images_doc { ${ImageSchema().graphql}}
      claim_requests_doc { ${ClaimRequestSchema().graphql}}
      memberships_doc {
        id
        first_name
        last_name
        dob
        gender
        teams {
          id
          status
          note
        }
        gyms {
          id
          status
          note
        }
        leagues {
          id
          status
          note
        }
        status
      }
      gym_transactions_doc {
        id
        last_update
        update_by
        created_on
        created_by
        status
        gym
        amount
        name
        cart
        transaction_type
        transaction_type_id {
          id
          name
        }
        is_transferable
        note
        reversal_of_payment {
          date
          amount
          reason
        }
        credit_issue {
          reason
          start_date
          expiration_date
          amount
        }
        credit_redemptions {
          date
          reason
          amount
          credit_redemption_type
          credit_redemption_type_id
        }
      }
      leagues_doc {
        id
        name
        status
      }
    }
    }`,
    params: {id}
  });

  static getGymMembershipsRequest = ({id, page = 0, page_size = 20, search_string='', search_field=''}) => {
    return RequestUtils.serverPost({
      query: `query {
        rs:getGymMembershipsRequest() {
          ${GymSchema().graphql}
        }
      }`
    })
  };
  static updateGymMembership = params => RequestUtils.mutationRequest('updateGymMembership', params);

  static registerGym = (id, member_id='') => RequestUtils.serverPost({
    query: `mutation {
      rs: registerGym(id: "${id}", member_id:"${member_id}") {id error}
    }`,
    params: {id, member_id}
  });
  
  static getDisplayGyms = ids => RequestUtils.serverPost({
    query: `query {${
      ids.map((id, i) => `_r${i}:getDisplayGym(id: "${id}") {
        id name city state country
      }`).join(',')
    }}`,
    params: {ids}
  });

  static searchGymRequest = ({page = 0, page_size = 20, search_string='', search_field = '', admin=false}) => RequestUtils.serverPost({
    query: `query {
      rs:searchGym(params: "${RequestUtils.encodeParameter({page, page_size, search_string, search_field, admin})}") {
        id display
      }
    }`,
    params: {page, page_size, search_string, search_field}
  });

  static getGymsRequest = ({page = 0, page_size = 20, search_string = '', search_field = ''}) => RequestUtils.serverPost({
    query: `query {
        rs:getGyms(page:${page}, page_size:${page_size}, search_string:"${search_string}", search_field:"${search_field}") {
            gyms {
              id
              name
              country
            }
            images_doc {
              id
              ref
              data
            }
            memberships_doc {
              id
              first_name
              last_name
              dob
              gender
              teams {
                id
                status
              }
              gyms {
                id
                status
              }
              leagues {
                id
                status
              }
              status
            }
            teams_doc {
              id
              name
              status
            }
            gym_transactions_doc {
              id
              last_update
              update_by
              created_on
              created_by
              status
              gym
              amount
              name
              cart
              transaction_type
              transaction_type_id {
                id
                name
              }
              is_transferable
              note
              reversal_of_payment {
                date
                amount
                reason
              }
              credit_issue {
                reason
                start_date
                expiration_date
                amount
              }
              credit_redemptions {
                date
                reason
                amount
                credit_redemption_type
                credit_redemption_type_id
              }
            }
            leagues_doc {
              id
              name
              status
            }
        }
    }`,
    params: [page, page_size, search_string, search_field]
  });

  static mergeGym = (to, from) => RequestUtils.serverPost({
    query: `mutation {
        rs:mergeGym(to: "${to}", from: "${from}") {
          error id
        }
    }`
  });

  static getGymsByCountry = (country) => RequestUtils.serverPost({
    query: `query {
        rs:getGymsByCountry(country: "${country}") {
          id name
        }
    }`
  });

  static getGymInfo = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getGymInfo(id: "${id}") {
        ${GymSchema().graphql}
      }
    }`,
    params: {id}
  });

  static getGymMembershipsRequest = ({id, page = 0, page_size = 20, search_string='', search_field=''}) => {
    return RequestUtils.serverPost({
      query: `query {
      rs:getGymMemberships(search_string:"${search_string}", search_field:"${search_field}", id: "${id}", page: ${page||0}, page_size: ${page_size||20}) {
        memberships { ${MembershipSchema().graphql} }
        images_doc { ${ImageSchema().graphql} }
        gyms_doc {
          id
          name
          status
          teams {
            id
            status
          }
        }
        teams_doc {
          id
          name
          status
          leagues {
            id
            status
          }
        }
        leagues_doc {
          id
          name
          status
        }
      }
      }`,
      params: {id, page, page_size, search_string, search_field}
    });
  };

  static updateGymMembershipRegistrationRequest = (params) => RequestUtils.mutationRequest('updateMembershipRegistration', params);


  static getGymMessagesRequest = ({ id,
    page = 0,
    page_size = 100,
    search_string = '',
    search_field = '' }) => RequestUtils.serverPost({
      query: `query {
        rs:getMessages(id: "${id}", 
                    type: "gym",
                    page: ${page}, 
                    page_size: ${page_size}, 
                    search_string: "${search_string}", 
                    search_field: "${search_field}"
                    ) {
          messages {
            id
            last_update
            update_by
            created_on
            created_by
            read_on
            status
            to
            from
            date
            message_type
            content
            subject
            ref
            audiences {
              audience_type
              search_string
              ids
            }
          }
          memberships_doc {
            id
            first_name
            last_name
          }
          teams_doc {
            id
            name
          }
          leagues_doc {
            id
            name
          }
          gyms_doc {
            id
            name
          }
          tournaments_doc {
            id
            name
          }
          images_doc {
            id
            data
            post_by
            created_on
            created_by
            last_update
            update_by
            ref
            image_type
            length
          }
        }
      }`,
      params: {id, page, page_size, search_string, search_field}
    });

  static getGymTransactionsRequest = (trans_ids, gym_id) => RequestUtils.serverPost({
    query: `query {
    {
      rs:getGymTransactions(ids: "${RequestUtils.encodeParameter(trans_ids)}", gym: "${gym_id}") {
        transactions {
          id
          last_update
          update_by
          created_on
          created_by
          status
          gym
          amount
          name
          cart
          transaction_type
          transaction_type_id {
            id
            name
          }
          is_transferable
          note
          reversal_of_payment {
            date
            amount
            reason
          }
          credit_issue {
            reason
            start_date
            expiration_date
            amount
          }
          credit_redemptions {
            date
            reason
            amount
            credit_redemption_type
            credit_redemption_type_id
          }
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
      }
    }
    }`,
    params: {trans_ids, gym_id}
  });

  static updateGymTransactionRequest = (params) => RequestUtils.mutationRequest('updateGymTransaction', params);

  static addGymTransactionRequest = (params) => RequestUtils.mutationRequest('addGymTransaction', params);

  static deleteGymTransactionRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteGymTransaction(id: "${id}" ){ error id } }`,
    params: {id}
  });

  static updateGymRequest = (params) => RequestUtils.mutationRequest('updateGym', params);


  static deleteGymRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteGym(id: "${id}" ){ error id } }`,
    params: {id}
  });

  static addGymRequest = (params) => RequestUtils.mutationRequest('addGym', params);


  
}

// User requests
export class RequestUser {
  static validateMembership = (id, password) => RequestUtils.serverPost({
    query: `query {
      rs:validateMembership(id: "${id}", password: "${password}"){
        ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: { id }
  });

  static mergeMemberships = (tid, fid) => RequestUtils.serverPost({
    query: `mutation {
      rs:mergeMemberships(tid: "${tid}", fid: "${fid}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: { tid, fid }
  });

  static sendTemporaryPassword = id => RequestUtils.serverPost({
    query: `mutation {
      rs:sendTemporaryPassword(id: "${id}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: { id }
  });

  static getMatches = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getMatches1(id: "${id}"){
        brackets {id division tournament isThird isLoser round index membership score score2 scorep place result}
        tournaments_doc { ${TournamentSchema().graphql_min} divisions{id is_block} poolings{pool bracket_type}}
        divisions_doc { ${DivisionSchema().graphql_min} }
        memberships_doc { ${MembershipSchema().graphql_min} }
      }
    }`,
    params: { id }
  });

  static getDisplayUsers = ids => RequestUtils.serverPost({
    query: `query {${
      ids.map((id, i) => `_r${i}:getDisplayUser(id: "${id}") {
        id first_name last_name city state country
      }`).join(',')
    }}`,
    params: {ids}
  });

  static searchLeagueMembership = (id, search_string='') => RequestUtils.serverPost({
    query: `query {
      rs:searchLeagueMembership(id: "${id}", search_string: "${search_string}") {
        id display
      }
    }`,
    params: {id, search_string}
  });

  static searchUser = (search_string='', include_profile=false) => RequestUtils.serverPost({
    query: `query {
      rs:searchMemberships(search_string: "${search_string}", include_profile:${include_profile}) {
        id city state country last_name first_name profile_image 
      }
    }`,
    params: {search_string, include_profile}
  });

  static searchMembershipGroup = (params) => RequestUtils.mutationRequest('searchMembershipGroup', params);
 

  static updateUser = params => RequestUtils.mutationRequest('updateMembership', params);
  
  static deleteMembership = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteMembership(id: "${id}" ){ error id } }`
  });

  static resetPassword = email => RequestUtils.serverPost({
    query: `mutation {
      rs:resetPassword(email: "${email}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: {email}
  });

  static updateMembershipRegistration = params => RequestUtils.mutationRequest('updateMembershipRegistration', params);
  
  static updateMembershipStatus = ({id, status}) => RequestUtils.serverPost({
    query: `mutation {
      rs:updateMembershipStatus(id: "${id}" status: "${status}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: {id, status}
  });

  static updateEntityStatus = ({id, entity_type, entity_id, status}) =>  RequestUtils.serverPost({
    query: `mutation {
      rs:updateEntityStatus(id: "${id}" entity_type: "${entity_type}", entity_id: "${entity_id}", status: "${status}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: {id, entity_type, entity_id, status}
  });

  static switchUser = (id) => RequestUtils.serverPost({
    query: `mutation {
      rs:switchUser(id: "${id}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
  }`,
  params: {id}
  });

  static disassociateGymMembershipRegistrationRequest = (params) => RequestUtils.mutationRequest('disassociateMembershipRegistration', params);


  static addMembershipRequest = (params) => RequestUtils.mutationRequest('addMembership', params);

  
  static getUserSupportMessagesRequest = () => RequestUtils.serverPost({
     query: `query {
      rs:getSupportMessages {
        messages {
          id
          last_update
          update_by
          created_on
          created_by
          read_on
          status
          to
          from
          date
          message_type
          content
          subject
          ref
          audiences {
            audience_type
            search_string
            ids
          }
        }
        memberships_doc {
          id
          first_name
          last_name
        }
        images_doc {
          id
          data
          post_by
          created_on
          created_by
          last_update
          update_by
          ref
          image_type
          length
        }
      }
    }`
  });
  
  static updateGIHMembershipStatusRequest = (id, status) => RequestUtils.serverPost({
     query: `mutation {
      rs:updateMembershipStatus(id: "${id}", status: "${status}") {
        error
        id
      }
    }`,
    params: {id, status}
  });
  
  static checkUserNameRequest = (username) => RequestUtils.serverPost({
    query: `query {
      rs:checkUserName(user_name: "${username}"){ error id } 
    }`,
    params: {username}
  });
  
  static registrationRequest = (params) => RequestUtils.mutationRequest('addMembership', params);

  static resendValidationEmail = () => RequestUtils.serverPost({
    query: "mutation { rs:resendValidationEmail { error id } }"
  });

  static confirmValidationEmail = (id) => RequestUtils.serverPost({
    query: `mutation { rs:confirmValidationEmail(id: "${id}") { error id } }`
  });

  static getUserRequest = (id, isProfile) => RequestUtils.serverPost({
      query: `query {
          rs:getMembership(id: "${id}") {
              memberships {
                city state country suburb region
                coaches {
                  id display title
                }
                id status is_private
                ${isProfile ? 'user_name address1 address2 region suburb city state zip country phone' : ''}
                first_name
                last_name
                email
                created_on
                dob
                gender
                weight
                weight_unit
                gi_level
                primary_gym primary_team
                roles
                gih {
                  status 
                  start_date 
                  end_date 
                  recurrence 
                  amount 
                  paid_date 
                  cancel_date 
                  transaction 
                  is_sandbox
                }
                teams {
                  id status note registered_date membership_type period {start_date end_date} transaction
                }
                gyms {
                  id status note registered_date membership_type period {start_date end_date} transaction
                }
                leagues {
                  id status note registered_date membership_type period {start_date end_date} transaction
                }
                family_permissions {
                  id display relation date
                }
                contact_infos {
                  name title phone email
                }
                coaches {
                  id display title since
                }
                social_medias {
                  name url
                }
                next_age_update_date{
                  month day last_update
                },
              }
              images_doc {
                id
                ref
                data
              }
              teams_doc{
                id
                name
              }
              gyms_doc {
                id
                name
              }
              leagues_doc {
                id name
              }
              friends_doc {
                requestor requestee request_date confirm_date id
              }
              coachof_doc {
                id first_name last_name
              }
              families_doc {
                id first_name last_name
              }
              memberships_doc {
                id
                first_name
                last_name
              }
              registrations_doc {
                id tournament
              }
         }
      }`,
      params: [id]
  })
  
  static getUsersRequest = (page=0, page_size=20, search_string='', search_field='') => {
    return RequestUtils.serverPost({
      query: `query {
        rs:getMemberships(page: ${page}, page_size: ${page_size}, search_string: "${search_string}", search_field: "${search_field}") {
          memberships {
            id created_on
            status is_private
            first_name
            last_name
            country
            gyms {id status}
            teams {id status}
            leagues {id status}
          }
          images_doc {
            id
            ref
            data
          }
          gyms_doc {
            id name
          }
          teams_doc {
            id name
          }
          leagues_doc {
            id name
          }
        }
      }`,
      params: {page, page_size, search_string, search_field}
  })};
  
  static getUserMatchesRequest = (user_id) => RequestUtils.serverPost({
    query: `query {
      rs:getMatches(id: "${user_id}") {
        bracket_locations
        tournaments_doc {
          id
          name
          dates {
            start_date
            end_date
            close_date
            coach_change_date
            member_change_date
            refund_date
          }
          status
          divisions {
            id
            is_block
          }
        }
        divisions_doc {
          id
          name
          status
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        opponents
      }
    }`,
    params: {user_id}
  });
  
  static getMembershipByIdsRequest = (ids) => RequestUtils.serverPost({
    query: `query {
      rs:getMembershipsByIds(ids: "${RequestUtils.encodeParameter(ids)}"){
        memberships {
          id
          first_name
          last_name
          created_on is_private
        }
        images_doc {
          id
          ref
          data
        }
    }}`,
    params: {ids}
  });

}

// Team requests
export class RequestTeam {
  static registerTeam = (id, member_id='') => RequestUtils.serverPost({
    query: `mutation {
      rs: registerTeam(id: "${id}", member_id:"${member_id}") {id error}
    }`
  });

  static updateTeamMembership = (params) => RequestUtils.mutationRequest('updateTeamMembership', params);

  static getDisplayTeams = ids => RequestUtils.serverPost({
    query: `query {${
      ids.map((id, i) => `_r${i}:getDisplayTeam(id: "${id}") {
        id name city state country
      }`).join(',')
    }}`
  });

  static getTeamMembershipsRequest = ({id, page=0, page_size=250, search_string='', search_field=''}) => RequestUtils.serverPost({
    query: `query {
     rs:getTeamMemberships(search_string:"${search_string}", search_field:"${search_field}", page:${page}, page_size:${page_size}, id:"${id}") {
       memberships {
         id
         is_super
         status is_private
         created_by
         created_on
         last_update
         update_by
         first_name
         last_name
         middle_name
         dob
         next_age_update_date {
           month
           day
           last_update
         }
         gender
         weight
         weight_unit
         email
         user_name
         address1
         address2
         region
         suburb
         password
         city
         state
         zip
         country
         phone
         time_zone
         reset_password
         years_of_wrestling
         years_of_judo
         gi_level
         is_coach
         impersonate
         admins {
           id
           display
         }
         roles
         contact_infos {
           name
           title
           phone
           email
         }
         family_permissions {
           id
           display
           relation
           date
         }
         coaches {
           id
           display
           title
           since
         }
         subscriptions {
           subscription_type
           id
           since
         }
         social_medias {
           name
           url
         }
         session {
           key
           user_agent
         }
         notification_subscriptions {
           endpoint
           expirationTime
           keys {
             p256dh
             auth
           }
           userAgent
           created_on
         }
         teams {
           id
           status
           note
           registered_date
           last_update
           update_by
           period {
             start_date
             end_date
           }
         }
         primary_gym
         gyms {
           id
           status
           registered_date
           last_update
           update_by
           membership_level
           note
           permissions
           gi_level {
             id
             date
             approve_by
           }
           period {
             start_date
             end_date
           }
         }
         leagues {
           id
           since
           status
           registered_date
           last_update
           update_by
           note
           membership_level
           period {
             start_date
             end_date
           }
         }
         gih {
           status
           start_date
           end_date
           recurrence
           amount
           paid_date
           cancel_date
           transaction
           is_sandbox
         }
       }
       images_doc {
         id
         data
         post_by
         created_on
         created_by
         last_update
         update_by
         ref
         image_type
         length
       }
       gyms_doc {
         id
         name
         status
         teams {
           id
           status
         }
       }
       leagues_doc {
         id
         name
         status
       }
       teams_doc {
         id
         name
         status
         leagues {
           id
           status
         }
       }
       tournaments_doc {
         id
         name
         dates {
           start_date
           end_date
           close_date
           coach_change_date
           member_change_date
           refund_date
         }
         status
       }
       registrations_doc {
         id
         last_update
         update_by
         created_on
         created_by
         status
         membership
         registered_on
         tournament
         cart
         amount
         unit_price
         promo_code
         waiver
         change_logs {
           date
           note
         }
         division
         bracket_locations {
           competitor
           division
           member_id
           index
           isLoser
           level
           name
           opponent
           score
           score2
           scorep
           score_opp
           place
           group
           group_round
           result
           isThirdPlace
           clock
           start_ts
           end_ts
           station
           priority
         }
         pool
         seed
         combo_ids
         gym
         team
         refund {
           request_date
           reason
           request_notes
           admin_notes
           admin
           status
           resolve_date
           resolve_type
           amount
           auto_refund
           transaction
           history
         }
       }
       carts_doc {
         id
         status
         last_update
         update_by
         created_on
         created_by
         membership
         ref
         ref_type
         ref_display
         items {
           sku
           name
           description
           quantity
           unit_price
           tax_rate
           shipping_cost
           other_cost
           item_type
           original_unit_price
         }
         payment {
           payment_type
           account
           private_key
         }
         payment_info {
           payment_type
           account
           private_key
           notes
         }
         submitted_on
         tax
         shipping
         other_fee
         append_changes {
           date
           detail
           by
         }
         payment_status
         currency
         t_shirt
         is_waiver
         vendor_notes
       }
       memberships_doc {
         id
         first_name
         last_name
         dob
         gender
         teams {
           id
           status
         }
         gyms {
           id
           status
         }
         leagues {
           id
           status
         }
         status
       }
       divisions_doc {
         id
         status
         last_update
         update_by
         created_on
         created_by
         league
         name
         grappling_type
         gender
         rank
         age_group
         weight_class
         default_timer {
           timer
           over_timer
           interstitial
         }
         code
         is_block
         is_custom_code
       }
       friends_doc {
         id
         requestor
         requestee
         request_date
         confirm_date
       }
       families_doc {
         id
         is_super
         status
         created_by
         created_on
         last_update
         update_by
         first_name
         last_name
         middle_name
         dob
         next_age_update_date {
           month
           day
           last_update
         }
         gender
         weight
         weight_unit
         email
         user_name
         address1
         address2
         region
         suburb
         password
         city
         state
         zip
         country
         phone
         time_zone
         reset_password
         years_of_wrestling
         years_of_judo
         gi_level
         is_coach
         impersonate
         admins {
           id
           display
         }
         roles
         contact_infos {
           name
           title
           phone
           email
         }
         family_permissions {
           id
           display
           relation
           date
         }
         coaches {
           id
           display
           title
           since
         }
         subscriptions {
           subscription_type
           id
           since
         }
         social_medias {
           name
           url
         }
         session {
           key
           user_agent
         }
         notification_subscriptions {
           endpoint
           expirationTime
           keys {
             p256dh
             auth
           }
           userAgent
           created_on
         }
         teams {
           id
           status
           note
           registered_date
           last_update
           update_by
           period {
             start_date
             end_date
           }
         }
         primary_gym
         gyms {
           id
           status
           registered_date
           last_update
           update_by
           membership_level
           note
           permissions
           gi_level {
             id
             date
             approve_by
           }
           period {
             start_date
             end_date
           }
         }
         leagues {
           id
           since
           status
           registered_date
           last_update
           update_by
           note
           membership_level
           period {
             start_date
             end_date
           }
         }
         gih {
           status
           start_date
           end_date
           recurrence
           amount
           paid_date
           cancel_date
           transaction
           is_sandbox
         }
       }
     }
   }`
 });
 
 static updateTeamRequest = (params) => RequestUtils.mutationRequest('updateTeam', params);

 
 static addTeamRequest = (params) => RequestUtils.mutationRequest('addTeam', params);

 
 static deleteTeamRequest = (id) => RequestUtils.serverPost({
  query: `mutation { rs:deleteTeam(id: "${id}" ){ error id } }`
});


 static updateTeamTransactionRequest = (params) => RequestUtils.mutationRequest('updateTeamTransaction', params);

 
 static addTeamTransactionRequest = (params) => RequestUtils.mutationRequest('addTeamTransaction', params);
 
 
 static deleteTeamTransactionRequest = (id) => RequestUtils.serverPost({
   query: `mutation { rs:deleteTeamTransaction(id: "${id}" ){ error id } }`
 });
 
 static getTeamTransactionsRequest = (ids, team_id) => RequestUtils.serverPost({
    query: `query {
     rs:getTeamTransactions(ids: "${RequestUtils.encodeParameter(ids)}", team: "${team_id}") {
       transactions {
         id
         last_update
         update_by
         created_on
         created_by
         status
         team
         amount
         name
         transaction_type
         transaction_type_id {
           id
           name
         }
         is_transferable
         note
         reversal_of_payment {
           date
           amount
           reason
         }
         credit_issue {
           reason
           start_date
           expiration_date
           amount
         }
         credit_redemptions {
           date
           reason
           amount
           credit_redemption_type
           credit_redemption_type_id
         }
       }
       memberships_doc {
         id
         first_name
         last_name
         dob
         gender
         teams {
           id
           status
         }
         gyms {
           id
           status
         }
         leagues {
           id
           status
         }
         status
       }
       gyms_doc {
         id
         name
         status
       }
     }
   }`,
   params: {ids, team_id}
 });
 
 static getTeamMessagesRequest = ({id,
                                         page= 0, 
                                         page_size= 100, 
                                         search_string= '', 
                                         search_field= ''}) => RequestUtils.serverPost({
    query: `query {
     rs:getMessages(id: "${id}", 
                     type: "team",
                     page: ${page}, 
                     page_size: ${page_size}, 
                     search_string: "${search_string}", 
                     search_field: "${search_field}") {
       messages {
         id
         last_update
         update_by
         created_on
         created_by
         read_on
         status
         to
         from
         date
         message_type
         content
         subject
         ref
         audiences {
           audience_type
           search_string
           ids
         }
       }
       memberships_doc {
         id
         first_name
         last_name
       }
       teams_doc {
         id
         name
       }
       leagues_doc {
         id
         name
       }
       gyms_doc {
         id
         name
       }
       tournaments_doc {
         id
         name
       }
       images_doc {
         id
         data
         post_by
         created_on
         created_by
         last_update
         update_by
         ref
         image_type
         length
       }
     }
   }`,
   params: {id, page, page_size, search_string, search_field}
 });
 
 static getTeamInfo = (id) => RequestUtils.serverPost({
  query: `query {
    rs:getTeamInfo(id: "${id}") {
      ${TeamSchema().graphql}
    }
  }`,
  params: {id}
});

static getTeamsByCountry = (country) => RequestUtils.serverPost({
  query: `query {
      rs:getTeamsByCountry(country: "${country}") {
        id name
      }
  }`
});

 static getTeamRequest = (id) => RequestUtils.serverPost({
   query: `{
    rs:getTeam(id: "${id}") {
      teams { ${TeamSchema().graphql} }
      images_doc { ${ImageSchema().graphql} }
      claim_requests_doc { ${ClaimRequestSchema().graphql}}
      memberships_doc {
        id
        first_name
        last_name
        dob
        gender
        teams {
          id
          status
        }
        gyms {
          id
          status
        }
        leagues {
          id
          status
        }
        status
      }
      gyms_doc {
        id
        name
        status
        teams {
          id
          status
        }
      }
      messages_doc { ${MessageSchema().graphql} }
      bans_doc {
        id
        status
        created_by
        created_on
        last_update
        update_by
        bans {
          ids
          ban_type
          created_on
        }
        ban_type
        ban_type_id
        recorded_date
        entity_type
        start_date
        end_state
        reason
        reason_code
      }
      leagues_doc {
        id
        name
        status
      }
      team_transactions_doc {
        id
        last_update
        update_by
        created_on
        created_by
        status
        team
        amount
        name
        transaction_type
        transaction_type_id {
          id
          name
        }
        is_transferable
        note
        reversal_of_payment {
          date
          amount
          reason
        }
        credit_issue {
          reason
          start_date
          expiration_date
          amount
        }
        credit_redemptions {
          date
          reason
          amount
          credit_redemption_type
          credit_redemption_type_id
        }
      }
     }
   }`,
   params: {id}
 });
 
 static getTeamsRequest = ({page=0, page_size=20, search_string='', search_field=''}) => RequestUtils.serverPost({
     query: `query {
         rs:getTeams(page: ${page}, page_size: ${page_size}, search_string: "${search_string}", search_field: "${search_field}") {
             teams {
               id
               name
               country
             }
             images_doc {
               id
               ref
               data
             }
         }
     }`,
     params: {page, page_size, search_string, search_field}
 });
 
  static searchTeam = (params) => RequestUtils.serverPost({
    query: `query {
         rs:searchTeam(params: "${RequestUtils.encodeParameter(params)}") {
            id display
         }
     }`,
    params
  });
}

// League requests
export class RequestLeague {
  static adjustLeagueTransactionCredits = (registrationId, leagueId, status) => RequestUtils.serverPost({
    query: `mutation {
      rs: adjustLeagueTransactionCredits(registrationId: "${registrationId}", leagueId: "${leagueId}", status: "${status}") {
        id error
      }
    }`,
    params: {leagueId}
  });
  
  static getTransactionCreditBalance = leagueId => RequestUtils.serverPost({
    query: `query {
      rs: getTransactionCreditBalance(id: "${leagueId}") {
        id error
      }
    }`,
    params: {leagueId}
  });
  static updateLeagueTransactionCredits = (params) => RequestUtils.mutationRequest('updateLeagueTransactionCredits', params);
  static getTransactionCredits = leagueId => RequestUtils.serverPost({
    query: `query {
      rs: getTransactionCredits(leagueId: "${leagueId}") {
        transaction_credits{${TransactionCreditSchema().graphql}}
        memberships_doc{${MembershipSchema().graphql_min}}
      }
    }`,
    params: {leagueId}
  });

  static addTransactionCredit = (params) => RequestUtils.serverPost({
    query: `mutation {
      rs: addTransactionCredit(params: "${RequestUtils.encodeParameter(params)}") {id error}
    }`,
    params: {params}
  });

  static registerLeague = (id, member_id='') => RequestUtils.serverPost({
    query: `mutation {
      rs: registerLeague(id: "${id}", member_id:"${member_id}") {id error}
    }`,
    params: {id, member_id}
  });

  static getDisplayLeagues = ids => RequestUtils.serverPost({
    query: `query {${
      ids.map((id, i) => `_r${i}:getDisplayLeague(id: "${id}") {
        id name city state country
      }`).join(',')
    }}`,
    params: {ids}
  });

  static deleteLeague = (id) => RequestUtils.mutationRequest('deleteLeague', id);
  static searchLeague = (params) => RequestUtils.serverPost({
    query: `query {
      rs:searchLeague(params: "${RequestUtils.encodeParameter(params)}") {
        id display
      }
    }`,
    params: {params} 
  });

  static cloneLeague = id => RequestUtils.mutationRequest('cloneLeague', id);
  static updateLeague = (params, func) => RequestUtils.mutationRequest(func, params);

  static getStandings = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getStandings(id: "${id}"){
          standings
          group_scores_doc{ ${GroupScoreSchema().graphql} }
          memberships_doc{ ${MembershipSchema().graphql_min} }
      }
    }`,
    params: {id}
  });

  static getTournaments = (id, search_string='') => RequestUtils.serverPost({
    query: `query {
          rs:getTournaments(league_id: "${id}", search_string: "${search_string}"){
              tournaments{ id name league
                           dates{start_date end_date close_date} time_zone
                           contact_infos{name title phone email} 
                           locations{venue city state country zip region suburb}
                           divisions{id}
                           social_medias{name url}
                           poolings{pool}
                        }
              images_doc { id ref data}          
          },
    }`,
    params: {id, search_string}
  });

  static deleteLeagueGroupScoreRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteGroupScore(id: "${id}" ){ error id } }`,
    params: {id}
  });

  static updateLeagueMembership = (params) => RequestUtils.mutationRequest('updateLeagueMembership', params);
   
  static addLeagueGroupScoreRequest = (params) => RequestUtils.mutationRequest('addGroupScore', params);

  
  static updateLeagueGroupScoreRequest = (params) => RequestUtils.mutationRequest('updateGroupScore', params);
 
  
  static deleteLeagueDivisionRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteDivision(id: "${id}" ){ error id } }`,
    params: {id}
  });
  
  static addLeagueDivisionRequest = (params) => RequestUtils.mutationRequest('addDivision', params);
 
  
  static updateLeagueDivisionRequest = (params) => RequestUtils.mutationRequest('updateDivision', params);
  
  
  static deleteLeagueWeightClassRequest = (id) => RequestUtils.serverPost({
     query: `mutation { rs:deleteWeightClass(id: "${id}" ){ error id } }`,
     params: {id}
  });
  
  static addLeagueWeightClassRequest = (params) => RequestUtils.mutationRequest('addWeightClass', params);
  
  
  static updateLeagueWeightClassRequest = (params) => RequestUtils.mutationRequest('updateWeightClass', params);


  static updateLeagueAgeGroupRequest = (params) => RequestUtils.mutationRequest('updateAgeGroup', params);


  static deleteLeagueAgeGroupRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteAgeGroup(id: "${id}" ){ error id } }`,
    params: {id}
  });
  
  static addLeagueAgeGroupRequest = (params) => RequestUtils.mutationRequest('addAgeGroup', params);

  
  static updateLeagueRankRequest = (params) => RequestUtils.mutationRequest('updateRank', params);

  
  static deleteLeagueRankRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteRank(id: "${id}" ){ error id } }`,
    params: {id}
  });
  
  static addLeagueRankRequest = (params) => RequestUtils.mutationRequest('addRank', params);

  
  static updateLeagueRequest = (params) => RequestUtils.mutationRequest('updateLeague', params);
 
  
  static addLeagueTransactionRequest = (params) => RequestUtils.mutationRequest('addLeagueTransaction', params);

  
  static deleteLeagueTransactionRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteLeagueTransaction(id: "${id}" ){ error id } }`,
    params: {id}
  });
  
  static updateLeagueTransactionRequest = (params) => RequestUtils.mutationRequest('updateLeagueTransaction', params);

  
  static searchLeagueTransactionsRequest = (id, search_string='') => RequestUtils.serverPost({
     query: `query {
      rs:searchLeagueTransactions(id: "${id}", search_string: "${search_string}") {
        transactions {
          id
          last_update
          update_by
          created_on
          created_by
          status
          league
          amount
          name
          transaction_type
          transaction_type_id {
            id
            display
          }
          is_transferable
          note
          reversal_of_payment {
            date
            amount
            reason
          }
          credit_issue {
            reason
            start_date
            expiration_date
            amount
          }
          credit_redemptions {
            date
            reason
            amount
            credit_redemption_type
            credit_redemption_type_id
          }
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        teams_doc {
          id
          name
          status
        }
      }
    }`,
    params: {id, search_string}
  });
  
  static getLeagueTransactionsRequest = (ids, league_id) => RequestUtils.serverPost({
     query: `query {
      rs:getLeagueTransactions(ids: "${RequestUtils.encodeParameter(ids)}", league: "${league_id}") {
        transactions {
          id
          last_update
          update_by
          created_on
          created_by
          status
          league
          amount
          name
          transaction_type
          transaction_type_id {
            id
            display
          }
          is_transferable
          note
          reversal_of_payment {
            date
            amount
            reason
          }
          credit_issue {
            reason
            start_date
            expiration_date
            amount
          }
          credit_redemptions {
            date
            reason
            amount
            credit_redemption_type
            credit_redemption_type_id
          }
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        teams_doc {
          id
          name
          status
        }
      }
    }`,
    params: {ids, league_id}
  });
  
  static getLeagueMembershipsRequest = ({id, page=0, page_size=20, search_string='', search_field=''}) => RequestUtils.serverPost({
     query: `query {
      rs:getLeagueMemberships(search_string:"${search_string}", search_field:"${search_field}", page:${page}, page_size:${page_size}, id:"${id}") {
        memberships {
          id
          is_super
          status is_private
          created_by
          created_on
          last_update
          update_by
          first_name
          last_name
          middle_name
          dob
          next_age_update_date {
            month
            day
            last_update
          }
          gender
          weight
          weight_unit
          email
          user_name
          address1
          address2
          region
          suburb
          password
          city
          state
          zip
          country
          phone
          time_zone
          reset_password
          years_of_wrestling
          years_of_judo
          gi_level
          is_coach
          impersonate
          admins {
            id
            display
          }
          roles
          contact_infos {
            name
            title
            phone
            email
          }
          family_permissions {
            id
            display
            relation
            date
          }
          coaches {
            id
            display
            title
            since
          }
          subscriptions {
            subscription_type
            id
            since
          }
          social_medias {
            name
            url
          }
          session {
            key
            user_agent
          }
          notification_subscriptions {
            endpoint
            expirationTime
            keys {
              p256dh
              auth
            }
            userAgent
            created_on
          }
          teams {
            id
            status
            note
            registered_date
            last_update
            update_by
            period {
              start_date
              end_date
            }
          }
          primary_gym
          gyms {
            id
            status
            registered_date
            last_update
            update_by
            membership_level
            note
            permissions
            gi_level {
              id
              date
              approve_by
            }
            period {
              start_date
              end_date
            }
          }
          leagues {
            id
            since
            status
            registered_date
            last_update
            update_by
            note
            membership_level
            period {
              start_date
              end_date
            }
          }
          gih {
            status
            start_date
            end_date
            recurrence
            amount
            paid_date
            cancel_date
            transaction
            is_sandbox
          }
        }
        images_doc {
          id
          data
          post_by
          created_on
          created_by
          last_update
          update_by
          ref
          image_type
          length
        }
        gyms_doc {
          id
          name
          status
          teams {
            id
            status
          }
        }
        leagues_doc {
          id
          name
          status
        }
        teams_doc {
          id
          name
          status
          leagues {
            id
            status
          }
        }
        tournaments_doc {
          id
          name
          dates {
            start_date
            end_date
            close_date
            coach_change_date
            member_change_date
            refund_date
          }
          status
        }
        registrations_doc {
          id
          last_update
          update_by
          created_on
          created_by
          status
          membership
          registered_on
          tournament
          cart
          amount
          unit_price
          promo_code
          waiver
          change_logs {
            date
            note
          }
          division
          bracket_locations {
            competitor
            division
            member_id
            index
            isLoser
            level
            name
            opponent
            score
            score2
            scorep
            score_opp
            place
            group
            group_round
            result
            isThirdPlace
            clock
            start_ts
            end_ts
            station
            priority
          }
          pool
          seed
          combo_ids
          gym
          team
          refund {
            request_date
            reason
            request_notes
            admin_notes
            admin
            status
            resolve_date
            resolve_type
            amount
            auto_refund
            transaction
            history
          }
        }
        carts_doc {
          id
          status
          last_update
          update_by
          created_on
          created_by
          membership
          ref
          ref_type
          ref_display
          items {
            sku
            name
            description
            quantity
            unit_price
            tax_rate
            shipping_cost
            other_cost
            item_type
            original_unit_price
          }
          payment {
            payment_type
            account
            private_key
          }
          payment_info {
            payment_type
            account
            private_key
            notes
          }
          submitted_on
          tax
          shipping
          other_fee
          append_changes {
            date
            detail
            by
          }
          payment_status
          currency
          t_shirt
          is_waiver
          vendor_notes
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        divisions_doc {
          id
          status
          last_update
          update_by
          created_on
          created_by
          league
          name
          grappling_type
          gender
          rank
          age_group
          weight_class
          default_timer {
            timer
            over_timer
            interstitial
          }
          code
          is_block
          is_custom_code
        }
        friends_doc {
          id
          requestor
          requestee
          request_date
          confirm_date
        }
        families_doc {
          id
          is_super
          status
          created_by
          created_on
          last_update
          update_by
          first_name
          last_name
          middle_name
          dob
          next_age_update_date {
            month
            day
            last_update
          }
          gender
          weight
          weight_unit
          email
          user_name
          address1
          address2
          region
          suburb
          password
          city
          state
          zip
          country
          phone
          time_zone
          reset_password
          years_of_wrestling
          years_of_judo
          gi_level
          is_coach
          impersonate
          admins {
            id
            display
          }
          roles
          contact_infos {
            name
            title
            phone
            email
          }
          family_permissions {
            id
            display
            relation
            date
          }
          coaches {
            id
            display
            title
            since
          }
          subscriptions {
            subscription_type
            id
            since
          }
          social_medias {
            name
            url
          }
          session {
            key
            user_agent
          }
          notification_subscriptions {
            endpoint
            expirationTime
            keys {
              p256dh
              auth
            }
            userAgent
            created_on
          }
          teams {
            id
            status
            note
            registered_date
            last_update
            update_by
            period {
              start_date
              end_date
            }
          }
          primary_gym
          gyms {
            id
            status
            registered_date
            last_update
            update_by
            membership_level
            note
            permissions
            gi_level {
              id
              date
              approve_by
            }
            period {
              start_date
              end_date
            }
          }
          leagues {
            id
            since
            status
            registered_date
            last_update
            update_by
            note
            membership_level
            period {
              start_date
              end_date
            }
          }
          gih {
            status
            start_date
            end_date
            recurrence
            amount
            paid_date
            cancel_date
            transaction
            is_sandbox
          }
        }
      }
    }`,
    params: {id, page, page_size, search_string, search_field}
  });
  
  static getLeagueMessagesRequest = ({id,
                                            page= 0, 
                                            page_size= 100, 
                                            search_string= '', 
                                            search_field= ''}) => RequestUtils.serverPost({
     query: `query {
      rs:getMessages(id: "${id}", 
                  type: "league",
                  page: ${page}, 
                  page_size: ${page_size}, 
                  search_string: "${search_string}", 
                  search_field: "${search_field}"
                  ) {
        messages {
          id
          last_update
          update_by
          created_on
          created_by
          read_on
          status
          to
          from
          date
          message_type
          content
          subject
          ref
          audiences {
            audience_type
            search_string
            ids
          }
        }
        memberships_doc {
          id
          first_name
          last_name
        }
        teams_doc {
          id
          name
        }
        leagues_doc {
          id
          name
        }
        gyms_doc {
          id
          name
        }
        tournaments_doc {
          id
          name
        }
        images_doc {
          id
          data
          post_by
          created_on
          created_by
          last_update
          update_by
          ref
          image_type
          length
        }
      }
    }`,
    params: {id, page, page_size, search_string, search_field}
  });
  
  static getLeaguesRequest = ({ids= [], 
                                     page= 0, 
                                     page_size= 100, 
                                     search_string= '', 
                                     search_field= 'name'}) => RequestUtils.serverPost({
      query: `query {
        rs:getLeagues(ids: "${RequestUtils.encodeParameter(ids)}", 
                   page: ${page}, 
                   page_size: ${page_size}, 
                   search_string: "${search_string}", 
                   search_field: "${search_field}") {
          leagues {
            id
            name
            country
          }
          images_doc {
            id
            ref
            data
          }
        }
      }`,
      params: {ids, page, page_size, search_string, search_field}
  });
  
  static getLeagueInfo = (id) => RequestUtils.serverPost({
    query: `query {
      rs:getLeagueInfo(id: "${id}") {
        ${LeagueSchema().graphql}
      }
    }`,
    params: {id}
  });

  static getLeagueRequest = (id) => RequestUtils.serverPost({
    query : `query {
      rs:getLeague(id: "${id}") {
        leagues {
          ${LeagueSchema().graphql}
        }
        images_doc {
          ${ImageSchema().graphql}
        }
        memberships_doc {
          id
          first_name
          last_name
          dob
          gender
          teams {
            id
            status
          }
          gyms {
            id
            status
          }
          leagues {
            id
            status
          }
          status
        }
        teams_doc {
          ${TeamSchema().graphql}
        }
        gyms_doc {
          id
          name
          status
        }
        messages_doc {
          ${MessageSchema().graphql}
        }
        tournaments_doc {
          ${TournamentSchema().graphql}
        }
        age_groups_doc {
          ${AgeGroupSchema().graphql}
        }
        divisions_doc {
          ${DivisionSchema().graphql}
        }
        division_combos_doc {
          ${DivisionComboSchema().graphql}
        }
        division_tags_doc {
          ${DivisionTagSchema().graphql}
        }
        group_scores_doc {
          ${GroupScoreSchema().graphql}
        }
        ranks_doc {
          ${RankSchema().graphql}
        }
        weight_classes_doc {
          ${WeightClassSchema().graphql}
        }
        transaction_credit_doc {
          ${TransactionCreditSchema().graphql}
        }
      }
    }`,
    params: {id}
  });
}

// Image requests
export class RequestImage {
  static async uploadImage(params, updateProgress){
    if (!params.data){
        return {error: 'Empty image'};
    }
    const chunkSize = 10*1000;
    delete params.id;
    let data = Array.isArray(params.data)? params.data.pop():params.data;
    let index = 0;
    let size = 0;
    let progress = 0;
    const getChunk = () => {
        size = data.length>chunkSize? chunkSize:data.length;
        let ck = data.substr(index, size);
        index += size;
        return ck;
    }
    params.length = params.data.length;
    params.data = getChunk();
    let response = await RequestImage.addImage(params);
    let result = RequestUtils.getResponseData(response);
    let isAppend = index < data.length;
    if (result && !result.error && isAppend){
      let id = result.id;
      params.id = id;
      const append = async () => {
        updateProgress && updateProgress((progress/data.length) * 100);
        params.data = getChunk();
        progress += params.data.length;
        response = await RequestImage.updateImage(params);
        let rs = RequestUtils.getResponseData(response);
        if (!rs || result.error) {
            index -= size;
            await append();
        }else if (!rs.error && index < data.length){
            await append();
        }else{
            updateProgress && updateProgress(100, rs);
        }
      }
      await append();
    }
    updateProgress && updateProgress(!isAppend? 100:0, result);
    return result;
  }

  static addImage = (params) => RequestUtils.mutationRequest('addImage', params);

  static updateImage = (params) => RequestUtils.mutationRequest('updateImage', params);

  static deleteImage = (id) => RequestUtils.mutationRequest('deleteImage', id);

  static getImagesRequest = (ids, size) => RequestUtils.serverPost({
    query: `query {
      rs:getImagesByIds(ids:"${RequestUtils.encodeParameter(ids)}", size: ${size}) {
        id
        ref
        image_type
        length
        data
      }
    }`,
    params: {ids, size}
  });

  static getImagesByRefRequest = ({ids, image_size = 200, image_type = 'profile_image', page = 0, page_size = 100}) => RequestUtils.serverPost({
    query: `query {
      rs:getImagesByRef(ids:"${RequestUtils.encodeParameter(ids)}", size: ${image_size}, type: "${image_type}", page: ${page}, page_size: ${page_size}) {
        id
        ref
        image_type
        length
        data
      }
    }`,
    params: {ids, image_size, image_type, page, page_size}
  });
}

// Message requests

const MessageBody = `messages {
  ${MessageSchema().graphql}
}
memberships_doc {
  id
  first_name
  last_name
}
teams_doc {
  id
  name
}
leagues_doc {
  id
  name
}
gyms_doc {
  id
  name
}
tournaments_doc {
  id
  name
}
images_doc {
  id
  data
  post_by
  created_on
  created_by
  last_update
  update_by
  ref
  image_type
  length
}`
export class RequestMessage {
  static searchMessageThreads = (id, searchText) => RequestUtils.serverPost({
    query: `{
      rs:searchMessageThreads(id: "${id}", searchText:"${searchText}") {
          ${MessageBody}
      }
    }`,
    params: {id, searchText}
  });

  static getMessageConversation = (id1, id2) => RequestUtils.serverPost({
    query: `{
      rs:getMessageConversation(id1: "${id1}", id2:"${id2}") {
          ${MessageSchema().graphql}
      }
    }`,
    params: {id1, id2}
  });

  static getMessageThread = id => RequestUtils.serverPost({
    query: `{
      rs:getMessageThread(id: "${id}") {
        ${MessageBody}
      }
    }`,
    params: {id}
  });

  static sendMessageRequest = params => RequestUtils.mutationRequest('addMessage', params);

  static sendBulkMessage = params => RequestUtils.mutationRequest('addBulkMessage', params);
  

  static markReadMessage = ids => RequestUtils.serverPost({
    query: `mutation {
      rs:markReadMessage(ids: "${ids}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: { ids }
  });

  static deleteMessage = id =>  RequestUtils.serverPost({
    query: `mutation {
      rs:deleteMessage(id: "${id}"){
          ${GRAPH_TEMPLATE_UPDATE}
      }
    }`,
    params: {id}
  });
}


export class RequestStore {
  static getStoreItemsRequest = ({ref_type, ref, page = 0, page_size = 20, search_string = ''}) => RequestUtils.serverPost({
    query: `query {
      rs:getStoreItems(ref: "${ref}", 
                    ref_type: "${ref_type}", 
                    page: ${page}, 
                    page_size: ${page_size}, 
                    search_string: "${search_string}") {
        store_items {
          id
          last_update
          update_by
          created_on
          created_by
          status
          name
          description
          sku
          unit_price
          tax_rate
          shipping_cost
          other_cost
          redirect_url
          unit_available
          ref
          ref_type
          affiliation_url
          affiliation_image_url
          keywords
          ad_priority
        }
        images_doc {
          id
          data
          post_by
          created_on
          created_by
          last_update
          update_by
          ref
          image_type
          length
        }
      }
    }`,
    params: {ref, ref_type, page, page_size, search_string}
  });

  static updateStoreItemRequest = (params) => RequestUtils.mutationRequest('updateStoreItem', params);
   

  static addStoreItemRequest = (params) => RequestUtils.mutationRequest('addStoreItem', params);


  static deleteStoreItemRequest = (id) => RequestUtils.serverPost({
    query: `mutation { rs:deleteStoreItem(id: "${id}" ){ error id } }`,
    params: {id}
  });
}

export class RequestHelp {
  static getHelps = (url='') => RequestUtils.serverPost({
    query: `query {
      rs:getHelps(url: "${url}") {
        helps{${HelpSchema().graphql}}
        files_doc{${FileSchema().graphql}}
      }
    }`
  });

  static addHelp = (params) => RequestUtils.mutationRequest('addHelp', params);

  static updateHelp = (params) => RequestUtils.mutationRequest('updateHelp', params);

  static deleteHelp = (id) => RequestUtils.mutationRequest('deleteHelp', id);
  
}



