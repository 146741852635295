import { DATA_HAS_CHANGED_MESSAGE, DATA_HAS_CHANGED_MESSAGE_WARNING, StatusUpdate, hasTableFormChange, reloadPage } from "../Form/Form";
import React, { useRef, useState, useEffect } from "react";
import { RequestLeague, RequestUtils } from "../../serverUtils/requests";
import {
  TableCell,
  TableRow, TextField, Tooltip
} from "@mui/material";
import TableFormInput, { IndexCell, ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/AlertPane";
import "../Form/Form.scss";
import MultiSelectFormInput, { includesAllOption } from "../FormInput/MultiSelectFormInput";
import { SelectGender, SelectGrapplingType } from "./WeightClasses";
import { CodeInput } from "./AgeGroups";
import Utils from "../../serverUtils/Utils";
import { RankSchema } from "../../serverUtils/Models";
import TextFormInput from "../FormInput/TextFormInput";

export default function Ranks({ league }) {
  const [message, setMessage] = useState("");
  return (
    <div className={`Ranks sub_form`}>
      <AlertPane message={message} setMessage={setMessage} timeOut={5000}/>
      <RankTable league={league} setMessage={setMessage} />
    </div>
  );
}

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Rank Name",
    align: "left"
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    align: "left",
  },
  {
    id: "gi_ranks",
    disablePadding: false,
    label: "BJJ Belts",
  },
  {
    id: "grappling_types",
    disablePadding: false,
    label: "Grappling Types",
  },
  {
    id: "genders",
    disablePadding: false,
    label: "Genders",
  },
];

const CRC_Fields = Utils.listObjectKeys(RankSchema().model);
const RankTable = ({ league, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const dataHasChangeRef = useRef();

  const checkCRC = () => {
    if (hasTableFormChange(league.getRanks(), CRC_Fields)) {
      toolbarButtonsRef.current.setIndicator({save: 'yellow'});
      toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
      dataHasChangeRef.current.style.display = '';
    }else {
      toolbarButtonsRef.current.setIndicator({save: ''});
      toolbarButtonsRef1.current.setIndicator({save: ''});
      dataHasChangeRef.current.style.display = 'none';
    }
  }
  useEffect(() => {
    league.getRanks && league.getRanks().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields)));
  }, []);

  const save = async () => {
    setMessage("");
    try {
      let selects = league.getRanks().filter(r => r.crc !== Utils.getCRC(r, CRC_Fields));
      
      if (selects.find((s) => !s.name || !s.code)) {
        return setMessage("error: Rank, code and status must be filled.");
      }
      for (let r of selects) {
        r.status = r.status || 'A';
        r.grappling_types = !r.grappling_types || r.grappling_types.length===0? ['gi']:r.grappling_types;
        r.genders = !r.genders || r.genders.length===0? ['M']:r.genders;

        let isAdd = r.id.startsWith("-1");
        let response;
        if (!isAdd) {
          response = await RequestLeague.updateLeagueRankRequest(r);
        } else {
          r.league = league.id;
          response = await RequestLeague.addLeagueRankRequest(r);
        }
        let data = RequestUtils.getResponseData(response);
        if (data.error) {
          return setMessage("error: Updating rank " + r.name);
        } else if (isAdd) {
          r.id = data.id;
        }
        r.crc = Utils.getCRC(r, CRC_Fields);
      }
      tableRef.current.setSelected([]);
      setMessage("success: Successfully updated server.");
    }finally {
      checkCRC();
    }
  };

  const deleteSelected = async () => {
    setMessage("");
    try {
      let selects = league
        .getRanks()
        .filter((r) => tableRef.current.selected.includes(r.id));
      if (selects.length === 0) {
        return setMessage("info: Nothing selected.");
      }
      for (let r of selects) {
        if (r.id.startsWith("-")) {
          continue;
        }
        let response = await RequestLeague.deleteLeagueRankRequest(r.id);
        let data = RequestUtils.getResponseData(response);
        if (data.error) {
          return setMessage("error: Deleting rank " + r.name);
        }
      }
      let ranks = league
        .getRanks()
        .filter((r) => !tableRef.current.selected.includes(r.id));
      league.getRanks = () => ranks;
      tableRef.current.setSelected([]);
      setMessage("success: Successfully deleted from server.");
    }finally {
      checkCRC()
    }
  };

  const doAdd = (row, index) => {
    try{
      let id = `-${new Date().getTime()}`;
      let ranks;
      if (row) {
        let copy = {...Utils.copy(row), id, name: `${row.name} copy`};
        ranks = [...league.getRanks()];
        ranks.splice(index+1, 0, copy);
      }else {
        ranks = [...league.getRanks(), { id, status: 'A' }];
      }
      league.getRanks = () => ranks;
      reloadPage(setRefresh, () => tableRef.current && tableRef.current.gotoLastPage());
    }finally {
      checkCRC();
    }
  };

  const toolbarButtons = ref => (
    <ToolbarButtons ref={ref} doAdd={() => doAdd()} doSave={save} doDelete={deleteSelected} />
  );
  return <>
    <div className="data-has-changed" ref={dataHasChangeRef} style={{display: 'none'}}>
        <AlertPane message={DATA_HAS_CHANGED_MESSAGE_WARNING} isFloat/>
    </div>
    <TableFormInput name="Ranks"
      isEditable
      toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
      toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
      ref={tableRef}
      headCells={headCells}
      data={(league.getRanks && league.getRanks()) || []}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        const inputRef = React.createRef();
        const checkboxRef = row.checkboxRef = React.createRef();
        return (
          <TableRow
            className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
            hover
            onClick={e => handleClick(e, row.id, checkboxRef)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox" className="index">
              <IndexCell ref={checkboxRef} isItemSelected={isItemSelected} labelId={labelId} index={index + 1} onClone={() => doAdd(row, index)}/> 
            </TableCell>
            <TableCell>
                <TextFormInput isFocus={!row.name? true: false}
                  value={row.name || ''}
                  onChange={v => {
                    row.name = v;
                    checkCRC();
                  }}/>
            </TableCell>
            <TableCell>
              <StatusUpdate row={row} 
                list={league.getRanks()} 
                tableRef={tableRef} 
                setRefresh={() => setRefresh(!refresh)}
                onChange={checkCRC}/>
            </TableCell>
            <TableCell>
              <CodeInput row={row} list={league.getRanks()} checkCRC={checkCRC}/>
            </TableCell>
            <TableCell>
              <MultiSelectFormInput
                multiple
                value={(row.gi_ranks || []).map(r => GI_LEVEL_OPTIONS.find(l => l.value === r))}
                name="gi_ranks"
                label="Allowed BJJ Belts"
                optionLabel="label"
                optionValue="value"
                fetchOptions={searchValue => {
                  searchValue = searchValue.toLowerCase();
                  return Promise.resolve(includesAllOption(GI_LEVEL_OPTIONS)
                    .filter(r => {
                      return !searchValue || ((typeof r.label==='string') && r.label.toLowerCase().includes(searchValue));
                    }));
                }}
                onChange={v => {
                  row.gi_ranks = v.map(v => v.value);
                  checkCRC();
                }}
              />
            </TableCell>
            <TableCell>
              <SelectGrapplingType row={row} 
                setRefresh={() => {
                  setRefresh(!refresh);
                  checkCRC();
                }}/>
            </TableCell>
            <TableCell>
              <SelectGender row={row} setRefresh={() => {
                setRefresh(!refresh);
                checkCRC();
              }}/>
            </TableCell>
          </TableRow>
        );
      }}
    />
  </>;
};

export const getGrapplingType = (grappling_types) => {
  let types = grappling_types || [];
  if (types.length === 2) {
    return 'both';
  }
  if (types.length > 0) {
    return types[0];
  } 
  return '';
}

export const GI_LEVEL_OPTIONS = [
  { value: "WHT", label: "White" },
  { value: "GRYw", label: "Grey-white" },
  { value: "GRY", label: "Grey" },
  { value: "GRYb", label: "Grey-black" },
  { value: "YELw", label: "Yellow-white" },
  { value: "YEL", label: "Yellow" },
  { value: "YELb", label: "Yellow-black" },
  { value: "ORGw", label: "Orange-white" },
  { value: "ORG", label: "Orange" },
  { value: "ORGb", label: "Orange-black" },
  { value: "GRNw", label: "Green-white" },
  { value: "GRN", label: "Green" },
  { value: "GRNb", label: "Green-black" },
  { value: "BLU", label: "Blue" },
  { value: "PUR", label: "Purple" },
  { value: "BRN", label: "Brown" },
  { value: "BLK", label: "Black" },
  { value: "REDb", label: "Red-black" },
];

export const NO_GI_LEVEL_OPTIONS = [
  { value: "BEG", label: "Beginner" },
  { value: "INT", label: "Intermediate" },
  { value: "ADV", label: "Advance" },
];
