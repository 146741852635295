import { DATA_HAS_CHANGED_MESSAGE, DATA_HAS_CHANGED_MESSAGE_WARNING, STATUS, StatusUpdate, hasTableFormChange, includesBlankOption, reloadPage } from "../Form/Form";
import React, { useEffect, useRef, useState } from "react";
import "../Form/Form.scss";
import {
  Input, TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import { RequestLeague, RequestUtils } from "../../serverUtils/requests";
import TableFormInput, { IndexCell, ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/AlertPane";
import { getGrapplingType } from "./Ranks";
import MultiSelectFormInput, { includesAllOption } from "../FormInput/MultiSelectFormInput";
import { GRAPPLING_TYPES } from "./Divisions";
import "./WeightClasses.scss";
import { CodeInput } from "./AgeGroups";
import Utils from "../../serverUtils/Utils";
import { WeightClassSchema } from "../../serverUtils/Models";
import SelectFormInput from "../FormInput/SelectFormInput";

export default function WeightClasses({ league }) {
  const crcRef = useRef();
  useEffect(() => {
    if (league.getWeightClasses) {
      crcRef.current = league.getWeightClasses().forEach(wc => wc.crc = Utils.getCRC(wc, CRC_Fields));
    }
  }, []);

  const [message, setMessage] = useState("");
  return (
    <div className="WeightClasses">
      <AlertPane message={message} setMessage={setMessage} timeOut={2000}/>
      <WeightClassTable league={league} setMessage={setMessage} />
    </div>
  );
}

const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Weight Class",
    align: "left",
    crc: true
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    align: "left",
    crc: true
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    align: "left",
    crc: true
  },
  {
    id: "weight_min",
    disablePadding: false,
    label: "Weight Minimum",
    align: "left",
    crc: true
  },
  {
    id: "weight_max",
    disablePadding: false,
    label: "Weight Maximum",
    align: "left",
    crc: true
  },
  {
    id: "weight_unit",
    disablePadding: false,
    label: "Weight Unit",
    align: "left",
    crc: true
  },
  {
    id: "grappling_types",
    disablePadding: false,
    label: "Grappling Types",
    crc: true
  },
  {
    id: "genders",
    disablePadding: false,
    label: "Genders",
    crc: true
  },
  {
    id: "age_groups",
    disablePadding: false,
    label: "Age Groups",
    crc: true
  },
  {
    id: "ranks",
    disablePadding: false,
    label: "Ranks",
    crc: true
  },
];

const alignCenter = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const CRC_Fields = Utils.listObjectKeys(WeightClassSchema().model);
const WeightClassTable = ({ league, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => league.getWeightClasses && league.getWeightClasses().forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields))), []);
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const dataHasChangeRef = useRef();

  const checkCRC = () => {
    if (hasTableFormChange(league.getWeightClasses(), CRC_Fields)) {
        toolbarButtonsRef.current.setIndicator({save: 'yellow'});
        toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
        dataHasChangeRef.current.style.display = '';
    }else {
        toolbarButtonsRef.current.setIndicator({save: ''});
        toolbarButtonsRef1.current.setIndicator({save: ''});
        dataHasChangeRef.current.style.display = 'none';
    }
  }
  const save = async () => {
    setMessage("");
    let selects = league.getWeightClasses().filter(wc => wc.crc !== Utils.getCRC(wc, CRC_Fields));
    
    if (
      selects.find(
        (s) => !s.name || !s.code || !s.weight_min || !s.weight_max
      )
    ) {
      return setMessage(
        "error: Weight class, code, weight min, weight max, weight unit and status must be filled."
      );
    }
    for (let r of selects) {
      r.status = r.status || 'A';
      r.grappling_types = !r.grappling_types || r.grappling_types.length===0? ['gi']:r.grappling_types;
      r.genders = !r.genders || r.genders.length===0? ['M']:r.genders;

      let isAdd = r.id.startsWith("-");
      let response;
      if (!isAdd) {
        response = await RequestLeague.updateLeagueWeightClassRequest(r);
      } else {
        r.league = league.id;
        response = await RequestLeague.addLeagueWeightClassRequest(r);
      }
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        selects.forEach(r => !r.crc && (r.crc = Utils.getCRC(r, CRC_Fields)));
        return setMessage("error: Updating weight class " + r.name);
      } else if (isAdd) {
        r.id = data.id;
      }
      r.crc = Utils.getCRC(r, CRC_Fields);
    }
    tableRef.current.setSelected([]);
    setMessage("success: Successfully updated server.");
    checkCRC();
  };

  const deleteSelected = async () => {
    setMessage("");
    try {
      let selects = league
        .getWeightClasses()
        .filter((r) => tableRef.current.selected.includes(r.id));
      if (selects.length === 0) {
        return setMessage("info: Nothing selected.");
      }
      for (let r of selects) {
        if (r.id.startsWith("-")) {
          continue;
        }
        let response = await RequestLeague.deleteLeagueWeightClassRequest(r.id);
        let data = RequestUtils.getResponseData(response);
        if (data.error) {
          return setMessage("error: Deleting weight class " + r.name);
        }
      }
      let weightClasses = league
        .getWeightClasses()
        .filter((r) => !tableRef.current.selected.includes(r.id));
      league.getWeightClasses = () => weightClasses;
      tableRef.current.setSelected([]);
      setMessage("success: Successfully deleted from server.");
    }finally {
      checkCRC();
    }
  };

  const doAdd = (row, index) => {
    try{
      let id = `-${new Date().getTime()}`
      let weightClasses;
      if (row) {
        let copy = {...Utils.copy(row), id, name: `${row.name} copy`};
        weightClasses = [...league.getWeightClasses()];
        weightClasses.splice(index+1, 0, copy);
      }else {
         weightClasses = [
          ...league.getWeightClasses(),
          {id, name: '', status: 'A' },
        ];
      }
     
      league.getWeightClasses = () => weightClasses;
      reloadPage(setRefresh, () => tableRef.current.gotoLastPage());
    }finally {
      checkCRC();
    }
  };

  const toolbarButtons = ref => (
    <ToolbarButtons ref={ref} doAdd={doAdd} doSave={save} doDelete={deleteSelected} />
  );
  return <>
    <div className="data-has-changed" ref={dataHasChangeRef} style={{display: 'none'}}>
        <AlertPane message={DATA_HAS_CHANGED_MESSAGE_WARNING} isFloat/>
    </div>
    <TableFormInput name="WeightClasses"
      isEditable
      ref={tableRef}
      headCells={headCells}
      data={(league.getWeightClasses && league.getWeightClasses()) || []}
      toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
      toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const getRanks = () => {
          return (row.ranks||[]).map(r => {
            let f = league.getRanks().find(lr => lr.id === r);
            return f && ({value: f.id, label: f.name});
          });
        }
        const getAgeGroups = () => {
          return (row.age_groups||[]).map(ag => {
            let f = league.getAgeGroups().find(lr => lr.id === ag);
            return f && ({value: f.id, label: f.name});
          });
        }

        const checkboxRef = row.checkboxRef = React.createRef();
        return (
          <TableRow
            className={`TableRow ${row.id.startsWith('-')? 'is-add':''}`}
            hover
            onClick={e => handleClick(e, row.id, checkboxRef)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox">
              <IndexCell ref={checkboxRef} isItemSelected={isItemSelected} labelId={labelId} index={index + 1} onClone={() => doAdd(row, index)}/> 
            </TableCell>
            <TableCell>
              <Tooltip title={row.name}>
                <Input defaultValue={row.name} className="name"
                  onChange={e => {
                    row.name = e.target.value;
                    checkCRC();
                  }}
                  onClick={e => e.stopPropagation()} />
              </Tooltip>
            </TableCell>
            <TableCell>
              <StatusUpdate row={row} 
                list={league.getWeightClasses()} 
                tableRef={tableRef} 
                setRefresh={() => setRefresh(!refresh)}
                onChange={checkCRC}/>
            </TableCell>
            <TableCell>
              <CodeInput row={row} list={league.getWeightClasses()} checkCRC={checkCRC}/>
            </TableCell>
            <TableCell>
              <Input onClick={e => e.stopPropagation()}
                onChange={e => {
                  row.weight_min = e.target.value;
                  checkCRC();
                }}
                type="number"
                defaultValue={row.weight_min}
              />
            </TableCell>
            <TableCell>
              <Input onClick={e => e.stopPropagation()}
                onChange={e => {
                  row.weight_max = e.target.value;
                  checkCRC();
                }}
                type="number"
                defaultValue={row.weight_max}
              />
            </TableCell>
            <TableCell>
              <SelectFormInput value={row.weight_unit || ""} 
                onChange={v => {
                  row.weight_unit = v;
                  checkCRC();
                }}
                options={includesBlankOption(WEIGHT_UNIT)}
              />
            </TableCell>
            <TableCell>
              <SelectGrapplingType row={row} 
                setRefresh={() => {
                  // setRefresh(!refresh);
                  checkCRC();
                }}/>
            </TableCell>
            <TableCell>
              <SelectGender row={row} 
                setRefresh={() => {
                  // setRefresh(!refresh);
                  checkCRC();
                }}/>
            </TableCell>
            <TableCell>
              <MultiSelectFormInput
                multiple
                value={getAgeGroups()}
                name="age_groups"
                label="Age Groups"
                optionLabel="label"
                optionValue="value"
                fetchOptions={searchValue => {
                  searchValue = searchValue.toLowerCase();
                  return Promise.resolve(includesAllOption(league.getAgeGroups()
                    .filter(r => !searchValue || r.name.toLowerCase().includes(searchValue))
                    .map(r => ({value: r.id, label: r.name}))));
                }}
                onChange={v => {
                  row.age_groups = v.map(v => v.value);
                  checkCRC();
                }}
              />
            </TableCell>
            <TableCell>
              <MultiSelectFormInput
                multiple
                value={getRanks()}
                name="gi_ranks"
                label="Gi Ranks"
                optionLabel="label"
                optionValue="value"
                fetchOptions={searchValue => {
                  searchValue = searchValue.toLowerCase();
                  return Promise.resolve(includesAllOption(league.getRanks()
                    .filter(r => !searchValue || r.name.toLowerCase().includes(searchValue))
                    .map(r => ({value: r.id, label: r.name}))));
                }}
                onChange={v => {
                  row.ranks = v.map(v => v.value);
                  checkCRC();
                }}
              />
            </TableCell>
          </TableRow>
        );
      }}
    />
  </>;
};

export const SelectGrapplingType = ({row, setRefresh}) => {
  return <SelectFormInput value={getGrapplingType(row.grappling_types)} 
    onChange={v => {
      if (v === 'both'){
        row.grappling_types = GRAPPLING_TYPES.map(gt => gt.value);
      }else {
        row.grappling_types = [v];
      }
      setRefresh();
    }} 
    options={[...includesBlankOption(GRAPPLING_TYPES), {value: 'both', label: 'Both'}]}
  />;
}

export const SelectGender = ({row, setRefresh}) => {
  return <SelectFormInput value={getGenderType(row.genders)} 
    onChange={v => {
      if (v === 'both'){
        row.genders = GENDER_TYPES.map(gt => gt.value);
      }else {
        row.genders = [v];
      }
      setRefresh();
    }} 
    options={[...includesBlankOption(GENDER_TYPES), {value: 'both', label: 'Both'}]}
  />;
}

export const getGenderType = (types = []) => {
  if (types.length === 2) {
    return 'both';
  }
  if (types.length > 0) {
    return types[0];
  } 
  return '';
}

export const WEIGHT_UNIT = [{ value: "lbs" }, { value: "kgs" }];

export const GENDER = {
  male: "M",
  female: "F",
};

export const GENDER_TYPES = [
  { value: GENDER.male, label: "Male" },
  { value: GENDER.female, label: "Female" },
];

export const getGenderLabel = (g) => {
  let f = GENDER_TYPES.find((gender) => gender.value === g);
  return f ? f.label : "";
};

export const getWeightClassDisplay = (wc, league, isHTML) => {
  if (!wc) {
    return "";
  }
  if (!wc.weight_max) {
    return wc.name;
  }
  let ag, ranks;
  if (league) {
    ag = `[${wc.age_groups
      .map((g) => {
        let _ag =
          league.getAgeGroups && league.getAgeGroups().find((a) => a.id === g);
        return _ag && _ag.code;
      })
      .join("-")}]`;
    ranks = `[${wc.ranks
      .map((g) => {
        let r = league.getRanks && league.getRanks().find((a) => a.id === g);
        return r && r.code;
      })
      .join("-")}]`;
  }
  let getGenderCode = (g) => {
    if (g === GENDER.maleFemale) {
      return "M/F";
    }
    return g;
  };
  let display = [
    // wc.name,
    // `[${wc?.genders?.map((g) => getGenderCode(g)).join("")}]`,
    // `[${wc?.grappling_types?.map((g) => (g === "gi" ? "G" : "NG")).join("")}]`,
    ag,
    ranks,
    `[${wc?.weight_min || 0}-${wc?.weight_max || 0}`,
    `${wc?.weight_unit || ""}]`,
    // `[${wc?.code}]`,
  ].filter((_wc) => _wc);
  if (!isHTML) {
    return display.join(" ");
  }
  return display.map((d, i) => (
    <span className={i % 2 === 0 ? "bold" : ""}>{d}</span>
  ));
};
