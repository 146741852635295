import { Route } from 'react-router-dom';
import {
  IonApp,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon, IonMenu,
  IonMenuToggle,
  IonRouterOutlet, IonToolbar,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { trophy, logInOutline } from 'ionicons/icons';
import { default as MenuIcon } from "@mui/icons-material/Menu";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';
import './theme/variables1.css';
import Logo from './icons/GIH-full-logo-medal.svg';

// Custom CSS
import './theme/global.scss';
import './App.scss';
import { useEffect, useState } from 'react';
import Navigation from './components/Nav/Navigation';
import { pages } from './components/Nav/pages';
import { Badge, createTheme, CssBaseline, Divider, Link, ListItemIcon, ListItemText, Menu, MenuItem, ThemeProvider, Tooltip } from '@mui/material';
import Utils from './serverUtils/Utils';
import UserModel from './serverUtils/models/UserModel';
import { useStore } from './Store';
import firebase from 'firebase';
import { useLocation, useHistory } from 'react-router-dom';
import { default as DashboardIcon } from '@mui/icons-material/Dashboard';
import { default as ProfileIcon } from '@mui/icons-material/AccountBox';
import { default as LogoutIcon } from '@mui/icons-material/Logout';
import { default as MembershipIcon } from '@mui/icons-material/PersonOutline';
import { default as FamilyIcon } from '@mui/icons-material/FamilyRestroomOutlined';
import { default as MessageIcon } from '@mui/icons-material/MailOutline';
import { default as TournamentInboxIcon } from '@mui/icons-material/InboxOutlined';
import { default as PublicIcon } from '@mui/icons-material/Public';
import { default as SendIcon } from '@mui/icons-material/ForwardToInboxOutlined';
import { default as HelpIcon } from "@mui/icons-material/HelpOutline";
import Icon from "./components/Icon";
import AlertPane from "./components/FormInput/AlertPane";
import classNames from "classnames";
import { event_sources, getSSEUrl, setupSSE } from "./serverUtils/requests";
import { getMessageThreads } from "./pages/DashboardPage/DashboardPage";
import TournamentModel from "./serverUtils/models/TournamentModel";
import MessageModel from "./serverUtils/models/MessageModel";
import Help from "./pages/Help/Help";
import { getBackHistory } from "./components/Form/Form";
const firebaseConfig = {
  apiKey: 'AIzaSyCWv2wRGG_oNG8_sCQAYIlRR__EaxdOiT0',
  authDomain: 'gih-app-abdbe.firebaseapp.com',
  projectId: 'gih-app-abdbe',
  storageBucket: 'gih-app-abdbe.appspot.com',
  messagingSenderId: '722961850202',
  appId: '1:722961850202:web:1309625f42ecccc08f1d63',
};
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

setupIonicReact();

export const GIHBanner = ({icon}) => {
  return <div className={`GIHBanner`} id="GIHBanner">
    <IonIcon icon={icon} className="Logo"/>
  </div>
}

const GlobalNavBar = ({ }) => {
  const history = useHistory();
  const location = useLocation();
  const global_messages = useStore(state => state.global_messages);
  const [url, setUrl] = useState(false);
  const pushBrowsingHistory = useStore(state => state.pushBrowsingHistory);
  const browsingHistory = useStore(state => state.browsingHistory);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // Location object contains information about the new location
      // Action can be 'PUSH', 'REPLACE', or 'POP'
      // console.log(`Navigation action: ${action}`, location);
      setUrl(`${location.pathname}${location.search}`);
    });

    return () => {
      // Cleanup: Unsubscribe from history listen when the component unmounts
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    setUrl(location.pathname);
    let p = `${location.pathname}${location.search}`
    if (browsingHistory.length===0 || p !== browsingHistory[browsingHistory.length-1]){
      pushBrowsingHistory(p);
    }
  }, [location.pathname, location.search]);

  let messages = global_messages.filter(m => ['global', url].includes(m.url));
  return (
    <IonToolbar className={classNames('App', `topNavBar`, 'GlobalNavBar')}>
      <GIHBanner icon={Logo}/>
      <div className={`menuProfile`}>
        <IonButtons slot="start">
          <IonMenuToggle autoHide={false}>
              <IonButton>
                <MenuIcon style={{ color: 'white' }} />
              </IonButton>
          </IonMenuToggle>
          {/* <IonButton routerLink='/search' className="search">
            <Icon name="search" />
          </IonButton> */}
        </IonButtons>
        <ProfileButtons />
      </div>
      <div className="global-message">
        {messages && messages.length > 0? 
          messages.map((m, i) => {
            return <AlertPane key={i} 
              message={m.message} 
              setMessage={() => {
                m.message='';
              }}/>}) 
          : ''}
      </div>
    </IonToolbar>
  );
};



const ProfileButtons = ({ }) => {
  const setTournamentMessagesRefresh = useStore(state => state.setTournamentMessagesRefresh);
  const session = useStore(state => state.session);
  const setSession = useStore(state => state.setSession);
  const history = useHistory();
  const [actionsMenu, setActionsMenu] = useState(null);
  const [messagesMenu, setMessasgesMenu] = useState(null);
  const [tournamentMessagesMenu, setTournamentMessasgesMenu] = useState(null);
  const [cartCount, setCartCount] = useState();
  const [sseConnected, setSseConnected] = useState(false);
  const setIsHelp = useStore(state => state.setIsHelp);

  useEffect(() => {
    if (session) {
      const init = async () => {
        for (let t of session.getAdminInfo().tournaments) {
          let messageObj = await TournamentModel.getMessages(t.id);
          messageObj.messages = messageObj.messages.sort((a, b) => Utils.sorter(b, a, 'created_on'));
          t.getMessages = () => messageObj;
        }
        getMessages(session, setSession);
        setSession({...session});
        setupSSE(session.id, messageObj => {
          if (messageObj === false) {
            setSseConnected(session && event_sources.has(`${getSSEUrl()}/${session.id}`));
          }else if (messageObj === true) {
            setSseConnected(true);
          }else if (messageObj.message === 'messages') {
            getMessages(session, setSession);
          } else if (messageObj.message === 'messages_tournament_admin') {
            let tour = session.getAdminInfo().tournaments.find(_t => _t.id === messageObj.id);
            TournamentModel.getMessages(tour.id).then(messages => {
              tour.getMessages = () => messages;
              setTimeout(() => {
                setSession({...session});
                setTournamentMessagesRefresh();
              }, 1000);
            });
          }
        });
      }
      init();
    }
  }, [session && session.id]);

  const isActionsMenuOpen = Boolean(actionsMenu);
  const isMessagesMenuOpen = Boolean(messagesMenu);
  const isTournamentMesssagesMenuOpen = Boolean(tournamentMessagesMenu);
  const onCloseMenu = e => {
    setTimeout(() => {
      tournamentMessagesMenu && setTournamentMessasgesMenu(null);
      messagesMenu && setMessasgesMenu(null);
      actionsMenu && setActionsMenu(null);
    }, 500);
  }
  const handleTournamentMessagesMenuOpen = (e) => {
    // e.stopPropagation();
    setTournamentMessasgesMenu(e.currentTarget);
  };
  const handleTournamentMessagesMenuClose = (e) => {
    // e && e.stopPropagation();
    setTournamentMessasgesMenu(null);
  };
  const handleMessagesMenuOpen = (e) => {
    // e.stopPropagation();
    setMessasgesMenu(e.currentTarget);
  };
  const handleMessagesMenuClose = (e) => {
    // e && e.stopPropagation();
    setMessasgesMenu(null);
  };
  const handleActionsMenuOpen = (e) => {
    // e.stopPropagation();
    setActionsMenu(e.currentTarget);
  };
  const handleActionsMenuClose = (e) => {
    // e.stopPropagation();
    setActionsMenu(null);
  };

  let count = session && session.getCarts && session?.getCarts().map(c => c.items).flat()?.length;
  useEffect(() => {
    if (session) {
      setCartCount(count>0? count:undefined);
    }
  },[count]);
  
  const getTournamentsUnreadCount = () => {
    let counts = session.getAdminInfo().tournaments
      .filter(t => t.getMessages)
      .map(t => {
        return t.getMessages().messages.map(m => m.count).flat();
      })
      .flat();
    return Utils.sumArray(counts);
  }

  let isTournamentAdmin = session && session.getAdminInfo().tournaments.length > 0;
  return (
    <IonButtons slot='end' className="ProfileButtons">
      <div className="buttons-wrapper" onBlur={onCloseMenu}>
        <HelpIcon className="HelpIcon" onClick={setIsHelp}/>
        {Utils.cookie('gih_session') ? (
          session && (
            <div className='session-buttons'>
              <IonButton routerLink='/carts' className="cart">
                <Badge className="session-buttons-icon"
                  color='primary'
                  max={100}
                  badgeContent={cartCount}>
                  <Icon name="shopping-cart" />
                </Badge>
              </IonButton>
              <IonButton onClick={handleMessagesMenuOpen} className="message">
                <Badge className="session-buttons-icon"
                  color='primary'
                  max={1000}
                  badgeContent={ Utils.sumArray(session.getMessages && session.getMessages()?.map(m => m.count))}>
                  <MessageIcon className={`MessageIcon ${sseConnected? 'sse-connect':''}`}/>
                </Badge>
                <Menu
                  anchorEl={messagesMenu}
                  open={isMessagesMenuOpen}
                  onClose={handleMessagesMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {[<MenuItem key={-1}>
                      <ListItemText >
                        <div className="send-a-message" 
                          onClick={e => {
                            handleMessagesMenuClose(e);
                            history.push('/messages?id=send');
                          }}>
                          Send a Message <SendIcon />
                        </div>
                      </ListItemText>
                    </MenuItem>,
                    <Divider key={-2}/>,
                    ...getMessageThreads(session).map((m, i) => {
                      return <MenuItem key={i}>
                        <ListItemText >
                          <span onClick={e => {
                            handleMessagesMenuClose(e);
                            history.push(`/messages?from=${m.id}`);
                          }}>
                            <div className="flex-column gap">
                              <MessageIcon />
                              <Badge color='primary'
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                badgeContent={m.unreads}>
                                  {m.name}
                              </Badge>
                            </div>
                          </span>
                        </ListItemText>
                      </MenuItem>;
                    })]}
                </Menu>
              </IonButton>
              {isTournamentAdmin && 
              <IonButton onClick={handleTournamentMessagesMenuOpen} className="tournament-message">
                <Badge className="session-buttons-icon"
                  color='primary'
                  max={1000}
                  badgeContent={getTournamentsUnreadCount()}>
                  <TournamentInboxIcon />
                </Badge>
                <Menu
                  anchorEl={tournamentMessagesMenu}
                  open={isTournamentMesssagesMenuOpen}
                  onClose={handleTournamentMessagesMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {session.getAdminInfo().tournaments.map((t, i) => {
                      return <MenuItem key={i}>
                        <ListItemText >
                          <span onClick={e => {
                            handleTournamentMessagesMenuClose(e);
                            document.location.href = `/tournaments/${t.id}/edit?tab=messages`;
                          }}>
                            <div className="flex-column gap">
                              <Badge color='primary'
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                badgeContent={t.getMessages? Utils.sumArray(t.getMessages().messages.map(m => m.count)):0}>
                                  <MessageIcon />
                              </Badge>
                              {t.name}
                            </div>
                          </span>
                        </ListItemText>
                      </MenuItem>;
                  })}
                </Menu>
              </IonButton>}
              <IonButton id="member_menu" onClick={handleActionsMenuOpen} style={{ color: 'white' }} className="member-menu">
                <MembershipIcon />
                <Tooltip title={UserModel.getMembershipName(session)}>
                  <span
                    className='user-name'
                    id={session && session.id}
                  >
                    {UserModel.getMembershipNameInitial(session)}
                  </span>
                </Tooltip>
                <Menu
                  id="actions_menu"
                  anchorEl={actionsMenu}
                  open={isActionsMenuOpen}
                  onClose={handleActionsMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem >
                    <ListItemText >
                      <Link href={`/users/${session.id}`}>
                        <div className="flex-column">
                          <PublicIcon />
                          {UserModel.getMembershipName(session)}
                          {session.is_super && <span className="object-tag">@super</span>}
                        </div>
                      </Link>
                    </ListItemText>
                  </MenuItem>
                  <Divider />
                  <FamilyMembers/>
                  <MenuItem onClick={(e) => {
                    // e.stopPropagation()
                    document.location.href = '/dashboard';
                    handleActionsMenuClose(e);
                  }}>
                    <ListItemIcon>
                      <DashboardIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Dashboard</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={(e) => {
                    // e.stopPropagation();
                    document.location.href = '/profile';
                    handleActionsMenuClose(e);
                  }}>
                    <ListItemIcon>
                      <ProfileIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <MenuItem id="member_logout"
                    onClick={e => {
                      // e.stopPropagation()
                      Utils.deleteCookie('gih_session');
                      document.location.href = '/logout';
                    }}>
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </Menu>
              </IonButton>
            </div>
          )
        ) : (
            <div className="login-button" onClick={e => {
              e.stopPropagation();
              document.location.href = '/login';
            }} style={{ color: 'white' }}>
                <IonIcon 
                  className='icon-flip-horizontal'
                  slot='icon-only'
                  icon={logInOutline}
                /><span>Login</span>
            </div>
        )}
      </div>
      
    </IonButtons>
  )
};

const FamilyMembers = ({}) => {
  const history = useHistory();
  const session = useStore(state => state.session);
  const setSession = useStore(state => state.setSession);

  if (!session || !session.getFamilies || session.getFamilies().length === 0) {
    return  '';
  }
  return <>
    {session.getFamilies().map((f, i) => <div key={i} className="Family">
      <MenuItem onClick={async (e) => {
        e.stopPropagation()
        let response = await UserModel.switchUser(f.id);
        if (response && response.id) {
          document.location.reload();
        }
      }}>
        <ListItemIcon><FamilyIcon /></ListItemIcon>
        <ListItemText>
          <span>{UserModel.getMembershipName(f)}</span>
        </ListItemText>
      </MenuItem>
    </div>)}
    <Divider />
  </>
}

const BottomTabs = ({ }) => {
  return (
    <div className={`tabs`}>
      <IonButton
        className={`tab`}
        routerLink='/leagues'
        fill='clear'>
        <Icon 
          name="league_black"
          className={`tabIcon`}
        />
        {/*<IonLabel className={styles.tabLabel}>Home</IonLabel>*/}
      </IonButton>

      <IonButton
        className={`tab`}
        routerLink='/tournaments'
        fill='clear'>
        <IonIcon
          icon={trophy}
          className={`tabIcon`}
        />
      </IonButton>

      <IonButtons
        className={`tab`}
        slot='start'>
        <IonMenuToggle>
          <IonButton
            className={`tabIcon`}>
            <MenuIcon className="inverse-color" />
          </IonButton>
        </IonMenuToggle>
      </IonButtons>
    </div>
  );
};

const SplitPane = ({ session }) => {
  const location = useLocation();
  const history = useHistory();
  const browsingHistory = useStore(state => state.browsingHistory);
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      let back = getBackHistory(browsingHistory);
      history.push(back);
    });
    let origin_request = Utils.cookie('origin_request');
    if (origin_request) {
      Utils.deleteCookie('origin_request');
      history.push(decodeURIComponent(origin_request));
    }
  }, []);

  useEffect(() => {
    window.trackClick(location.pathname, session); // Track page view on route change
  }, [location]);
  
  let routes = pages.filter(p => p);
  return (
    <>
      <IonMenu
        contentId='main-content'
        className={`menu popout-menu`}>
        <IonToolbar color="tertiary" className={`topNavBar`}>
          <IonIcon icon={Logo} className="menuLogo"/>
        </IonToolbar>
        <IonContent color="tertiary" className={`navListWrapper`}>
          <Navigation />
        </IonContent>
      </IonMenu>

      <GlobalNavBar />
      <div id='main-content' className={`mainContent`}>
        <div className="content">
          <IonContent>
            <IonRouterOutlet>
              {routes.map((p, i) => {
                return <Route
                  key={i}
                  exact
                  path={p.path}
                  component={p.component}
                />
              })}
            </IonRouterOutlet>
          </IonContent>
          <Help />
        </div>
        <BottomTabs />
      </div>
    </>
  );
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const getMessages = (session, setSession) => {
  if (session) {
    let messages = (session.getMessages && session.getMessages()) || [];
    let ts = messages.length > 0 ? parseInt(messages[0].created_on) : undefined;
    UserModel.getMessages({ ts }).then(messageObj => {
      let ms = MessageModel.setMessageFrom(messageObj);
      session.getMessages = () => ms;
      setSession({ ...session });
    });
  }
};

const App = () => {
  const global_dialog = useStore(state => state.global_dialog);
  const setLocalServer = useStore(state => state.setLocalServer);
  const session = useStore(state => state.session);
  const setSession = useStore(state => state.setSession);
  const setIsMobile = useStore(state => state.setIsMobile);
  const [hasInit, setHasInit] = useState();

  const initNotification = () => {
    if (session) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
            return messaging.getToken();
          }
          console.log('Not granted.');
        })
        .then((token) => {
          console.log('FCM_token: ', token);
          if (!Utils.cookie('gih_FCM_token') || Utils.cookie('gih_FCM_token') !== token) {
            session && UserModel.searchUser(`${session.first_name} ${session.last_name}`);
          }
          Utils.cookie('gih_FCM_token', token);
          const appendMessage = (encodeMsg) => {
            let message = JSON.parse(encodeMsg);
            message = JSON.parse(decodeURIComponent(message.body));
            if (message.id) {
              if (!session.getMessages().find((m) => m.id === message.id)) {
                let messages = [...session.getMessages(), message];
                console.log('session.messages:', messages.length);
                session.getMessages = () => messages;
                setSession({ ...session });
              }
            }
          };
          messaging.onMessage((payload) => {
            console.log('Message received: ', payload);
            if (session) {
              let notification = JSON.parse(payload.data.notification);
              appendMessage(notification.body);
            }
          });
          navigator.serviceWorker.addEventListener('message', (event) => {
            console.log(
              'navigator.serviceWorker.addEventListener',
              event.data.message,
              event.data.url
            );
            session && appendMessage(event.data['firebase-messaging-msg-data'].data.notification);
          });
        });
    }
  };

  useEffect(() => {
    if (hasInit) {
      return;
    }
    setLocalServer(null);
    setHasInit(true);
    initNotification();
  }, [hasInit, session]);
  useEffect(() => {
    !session &&
      UserModel.getSession(setSession).then((s) => {
        getMessages(s, setSession);
      });
  }, [session]);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile();
    });
    setIsMobile();
  }, []);

  const AppMessage = ({}) => {
    const [message, setMessage] = useState('');
    useEffect(() => {
      setMessage('');
      if (!session || session.is_super) {
        return;
      }
      const { payouts, tournaments, leagues, teams, gyms } = session.getAdminInfo();
      if (payouts) {
        let counts = JSON.parse(decodeURIComponent(payouts));
        const mapType = (t, name) => {
          t.rtype = name;
          return t;
        }
        let refs = [
          ...tournaments.map(t => mapType(t, 'tournaments')),
          ...leagues.map(t => mapType(t, 'leagues')),
          ...teams.map(t => mapType(t, 'teams')),
          ...gyms.map(t => mapType(t, 'gyms'))
        ];
        let _payouts = Object.keys(counts).map(id => {
          let ref = refs.find(r => r.id === id);
          if (ref) {
            return ref;
          }
        }).filter(r => r);
        if (_payouts.length > 0) {
          const getPromotionList = () => {
            return _payouts.map((r, i) => <Link key={i}
              href={`/${r.rtype}/${r.id}/edit?tab=payout`}>
              {`${r.name}${i < _payouts.length - 1 ? ', ' : ''}`}
            </Link>);
          }
          setMessage(<span>
            You have {_payouts.length === 1 ? 'an ' : 'some '}outstanding
            balance{_payouts.length === 1 ? '' : 's'} with <b>GIH </b>
            for <b>{getPromotionList()}</b>.
            You can resolve this issue by using the <b>Payout</b> tab
            from each promotion that you manage. You can go to each
            Payout tab by clicking the link of each promotion.  Or by your
            Paypal account dashboard and transfer money to <b>grappling.in.house</b> Paypal account.
          </span>);
        }
      }
      if (session && session.status === 'P'){
        if (!session.email_failure) {
          setMessage(<span className="incomplete-email-validation">
            Your <b>GIH</b> registration is not complete.  Please
            check your email a message from <b>grappling.in.house@gmail.com</b>
            and follow the instruction to complete the registration
            process.  Click <b style={{ cursor: 'pointer' }}><Link onClick={resendValidationEmail} >here</Link></b> if
            you need to resend the validation email.
          </span>);
        }else {
          setMessage(<span className="incomplete-email-validation">
            Your <b>GIH</b> registration is not complete.  Our email server has
            failed to send a validation email
            address <b style={{color: 'orange'}}>{session.email}</b>.  Please update or change your email
            in your profile.  Click <b style={{ cursor: 'pointer' }}><Link onClick={resendValidationEmail} >here</Link></b> if
            you need to resend the validation email.
          </span>);
        }
      }
    }, [session]);

    const resendValidationEmail = async () => {
      setMessage('');
      let res = await UserModel.resendEmailValidation();
      if (res && !res.error) {
        return setMessage('success: Your email validation has been sent.');
      }
      setMessage('error: Error sending the validation email.  Please try again');
    }

    return <div className="AppMessage">
      <AlertPane message={message} severity={'info'} setMessage={setMessage} isFloat isSolid/>
    </div>
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <IonApp>
        <IonReactRouter>
          {session && <AppMessage />}
          <SplitPane
            session={session}
          />
        </IonReactRouter>
        {global_dialog && <div className="global-dialog">
          {global_dialog}
        </div>}
      </IonApp>
    </ThemeProvider>
  );
};

export default App;
