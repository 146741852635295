import { Alert, ThemeProvider } from "@mui/material";
import Theme from "./Theme";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import "./AlertPane.scss";
import {default as CloseIcon} from "@mui/icons-material/CloseOutlined";

const AlertPane = React.forwardRef(({ 
  children='', 
  setMessage, 
  message, 
  isFloat, 
  isSolid,
  isModal, 
  timeOut, 
  severity, 
  omitClose,
  className='',
}, ref) => {
  const [isHide, setIsHide] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refMessage, setRefMessage] = useState('');
  const timeoutRef = useRef();
  const divRef = useRef();

  useImperativeHandle(ref, () => ({
    setRefMessage: msg => {
      console.log(msg);
      setRefMessage(msg);
      setIsHide(false);
    },
  }));

  useEffect(() => {
    divRef.current && divRef.current.scrollIntoView({
        behavior: 'smooth', 
        block: 'center',     
    });
  }, []);

  useEffect(() => {
    if (isHide) {
      setIsHide(false);
    }
    setRefresh(!refresh);
  }, [message]);

  useEffect(() => {
    if (message && timeOut) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setMessage('');
      }, timeOut);
    }else if (setMessage) {
      setMessage(message || '');
    }
  }, [timeOut]);

  let isString = typeof (refMessage || message) === 'string';
  const getSeverity = () => {
    if (severity && ["error", "info", "success", "warning"].includes(severity)) {
      return severity;
    }
    if (!isString) {
      return;
    }
    let msg = refMessage || message;
    if (msg.startsWith('error:')) {
      return 'error';
    }
    if (msg.startsWith('info:')) {
      return 'info';
    }
    if (msg.startsWith('warning:')) {
      return 'warning';
    }
    return 'success';
  }

  return (message || refMessage) && !isHide ?
    <ThemeProvider theme={Theme}>
      <div className={`Message ${isFloat? 'float':''} ${isSolid? 'solid':''} ${isModal? 'modal':''} ${getSeverity()} ${className}`} 
        ref={divRef}
        onClick={e => e.stopPropagation()}>
        {!omitClose && 
          <CloseIcon className="CloseIcon" 
            onClick={(e) => {
              e.stopPropagation();
              setMessage && setMessage('');
              setIsHide(true);
            }}/>}
        <Alert variant="filled" className="Alert"
          severity={severity || getSeverity()} >
          <span className="text">
            {(isString ? (refMessage || message).split(': ')[1] : message)}
          </span>
        </Alert>
        {children}
      </div>
    </ThemeProvider> : '';
});

export default AlertPane;